import React from "react";
import auth from "../services/authservice";
import { useNavigate } from "react-router-dom";


const PageNotFound=()=> {
const navigate= useNavigate();
    return(
      <>

{/* <!-- App Header --> */}
    <div  style={{width:auth.appWidth(), marginLeft:auth.appMargin()}} className="appHeader no-border">
       
        <div className="pageTitle">
            Oops!
        </div>
        <div className="right">
        </div>
    </div>
    {/* <!-- * App Header -->

    <!-- App Capsule --> */}
    <div id="appCapsule">

    <div className="section mt-2 text-center">
            <div className="card">
                <div className="card-body pt-3 pb-3">
                    <img src="assets/img/sample/photo/lend.png" alt="image" className="imaged w-50 "/>
                       
        <div className="section">
            <div className="splash-page mt-5 mb-5">
                <h2 className="mb-2">View not found!</h2>
                <p>
                    You must have navigated wrongly
                </p>
            </div>
        </div>
        <div className="row">
                <div className="col-12">
                    <a onClick={()=>navigate(-1)} className="btn btn-outline-primary btn-block goBack">Go Back</a>
                </div>
            </div>
                </div>
                
            </div>
        </div>
     

    </div>
    {/* <!-- * App Capsule --> */}

      </>
    )
}
export default PageNotFound;