import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import auth from "./services/authservice.js";
import Login from "./components/login.js";
import Home from "./components/home.js";
import ChangePassword from "./components/changepassword.js";
import ForgotPassword from "./components/forgotpassword.js";
import Borrow from "./components/borrow.js";
import LoanApproval from "./components/loanapproval.js";
import LoanApprovalCompleted from "./components/loanapprovalcompleted.js";
import UserLoanPackages from "./components/userloanpackages.js";
import LoanRequest from "./components/loanrequest.js";
import UserChat from "./components/userchat.js";
import ChatMessage from "./components/chatmessage.js";
import LoanRequestSuccessful from "./components/loanrequestsuccessful.js";
import Loans from "./components/loans.js";
import Notification from "./components/notification.js";
import Payment from "./components/payment.js";
import Payments from "./components/payments.js";
import Profile from "./components/profile.js";
import ProfileView from "./components/profileview.js";
import Register from "./components/register.js";
import RegisterOption from "./components/registeroption.js";
import SMSVerification from "./components/smsverification.js";
import Welcome from "./components/welcome.js";
import RepaymentSent from "./components/repaymentsent.js";
import RepaymentConfirmed from "./components/repaymentconfirmed.js";
import PageNotFound from "./components/pagenotfound.js";
import PaymentSubmissionSuccessful from "./components/paymentsubmissionsuccessful.js";
import FAQ from "./components/faq.js";
import About from "./components/about.js";
import Contact from "./components/contact.js";
import sc from "./services/stringconst";
import Header from "./components/header.js";
import Footer from "./components/footer.js";
import Alert from "./services/alert";
import { useNavigate } from "react-router-dom";
import NewLoanPackage from "./components/newloanpackage.js";
import LoanPackage from "./components/childcomponents/loanpackage.js";
import NewPayment from "./components/newpayment.js";
import LoanRepaymentSchedule from "./components/loanRepaymentSchedule";
import PaymentDetails from "./components/paymentdetails.js";
import { App as CapacitorApp } from '@capacitor/app';
import NewUserAccount from "./components/newuseraccount";
import RepaymentsView from "./components/repaymentsview.js";
import UpdateAvailable from "./components/updateavailable.js";
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from '@capacitor/status-bar';
import UserAccount from "./components/useraccount.js";
import Rating from "./components/rating.js";
import PhotoVerification from "./components/photoverification.js";
import Settings from "./components/settings.js";
import IdentificationVerification from "./components/identityverification.js";
import SetLoginPIN from "./components/setloginpin.js";
import BorrowerCreditCheck from "./components/borrowercreditcheck.js";
import LenderCreditCheck from "./components/lendercreditcheck.js";

const App = () => {

  useEffect(() => {

    auth.appWidth();

    
  });

  return (
    <Router>
      <AppComponentFunction />
    </Router>
  );
};


function AppComponentFunction() {
  let navigate = new useNavigate();

  const [alertMessage, setAlertMessage] = useState({
    show: false,
    message: "",
    type: "",
  });

  const messageModal = (message, type) => {

    if (message === sc.signInToContinue) {
      setIsLoggedIn(false);
      auth.logout();
      type = sc.warning;
      navigate(sc.loginroute);
    }
    if (message === sc.updateRequired) {
      setIsLoggedIn(false);
      auth.logout();
      type = sc.warning;
      navigate(sc.updateavailableroute);
    }

    setAlertMessage({ show: true, message: message, type: type });
    setTimeout(() => {
      setAlertMessage({ show: false, message: "", type: "" });
    }, sc.toastShow5000);

  };



  useEffect(() => {


    // setupPushNotification();

    const user = auth.getCurrentUser();
    if (user) {
      setCurrentUser(user);
      setIsAdmin(user.role === "Admin");
      setIsLender(user.isLender === true);
      setIsLoggedIn(true);
    } else {
      setIsAdmin(false);
      setIsLender(false);
      setIsLoggedIn(false);
    }
    
    auth.setPlatform(Capacitor.getPlatform());

    let platformIsPwa = false;
    if(
      (("standalone" in window.navigator) && window.navigator.standalone) // ios
      ||
      (window.matchMedia('(display-mode: standalone)').matches) // android
      ){
        platformIsPwa = true;
      }
      
    if (Capacitor.getPlatform() != "web" || platformIsPwa) {
      auth.setPlatformIsNative(true);
      // setupPushNotification();
      setBackgroundColor();

      CapacitorApp.addListener('backButton', ({ canGoBack }) => {
        if (!canGoBack) {
          CapacitorApp.exitApp();
        } else {
          window.history.back();
        }
      });
    }
    else {
      auth.setPlatformIsNative(false);
    }
  }, []);


  const setBackgroundColor = async () => {
    await StatusBar.setBackgroundColor({ color: "#00009C" });
  };

  const setupPushNotification = () => {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(async result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        
        await PushNotifications.register();


          // On success, we should be able to receive notifications
          PushNotifications.addListener('registration',
            (token) => {
              auth.setPushNotificationUserToken(token.value);
              //localStorage.setItem(sc.PushNotificationUserToken, token.value);
              //alert('Push registration success, token: ' + token.value);
            }
          );
          // Some issue with our setup and push will not work
          PushNotifications.addListener('registrationError',
            (error) => {
              //alert('Error on registration: ' + JSON.stringify(error));
            }
          );

          // Show us the notification payload if the app is open on our device
          PushNotifications.addListener('pushNotificationReceived',
            (notification) => {
              //alert('Push received: ' + JSON.stringify(notification));
            }
          );

          // Method called when tapping on a notification
          PushNotifications.addListener('pushNotificationActionPerformed',
            (notification) => {
              //alert('Push action performed: ' + JSON.stringify(notification));
            }
          );

      } else {
        // Show some error
      }
    });

  }

  const setUser = (value) => {
    setIsLoggedIn(value);
  };

  const setNotifications = (message, notification) => {
    setMessageCount(message);
    setNotificationCount(notification);
  }

  const [isLender, setIsLender] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [messageCount, setMessageCount] = useState(0);
  const [notificationCount, setNotificationCount] = useState(0);


  return (
    <div style={{ marginLeft: auth.appMargin(), width: auth.appWidth() }}>
    
      <Header loginStatus={isLoggedIn} messageCount={messageCount} notificationCount={notificationCount} />
      <Footer loginStatus={isLoggedIn} />
      
      {alertMessage.show && (
        <Alert message={alertMessage.message} alertType={alertMessage.type} />
      )}

      <div>
        {/* <Router> */}

        <Routes>
          <Route exact path={sc.baseroute} element={<Welcome />} />
          <Route
            exact
            path={sc.loginroute}
            element={<Login setUser={setUser} ShowAlert={messageModal} />}
          />
          <Route
            exact
            path={sc.homeroute}
            element={
              <Home ShowAlert={messageModal} setNotifications={setNotifications} />
            }
          />
          <Route
            exact
            path={sc.changepasswordroute}
            element={
              <ChangePassword

                ShowAlert={messageModal}
              />
            }
          />
          <Route
            exact
            path={sc.forgotpasswordroute}
            element={<ForgotPassword ShowAlert={messageModal} />}
          />
          <Route
            exact
            path={sc.repaymentsviewroute}
            element={<RepaymentsView ShowAlert={messageModal} />}
          />
          <Route
            exact
            path={sc.borrowroute}
            element={<Borrow ShowAlert={messageModal} />}
          />
          <Route
            exact
            path={sc.loanapprovalroute}
            element={
              <LoanApproval

                ShowAlert={messageModal}
              />
            }
          />
          <Route
            exact
            path={sc.loanapprovalcompletedroute}
            element={
              <LoanApprovalCompleted

                ShowAlert={messageModal}
              />
            }
          />
          <Route
            exact
            path={sc.userchatroute}
            element={
              <UserChat ShowAlert={messageModal} />
            }
          />
          <Route
            exact
            path={sc.chatmessageroute}
            element={
              <ChatMessage ShowAlert={messageModal} />
            }
          />
          <Route
            exact
            path={sc.loanpackageroute}
            element={<UserLoanPackages ShowAlert={messageModal} />}
          />
          <Route
            exact
            path={sc.newuseraccountroute}
            element={<NewUserAccount ShowAlert={messageModal} />}
          />
          <Route
            exact
            path={sc.updateuseraccountroute}
            element={<NewUserAccount ShowAlert={messageModal} />}
          />
          <Route
            exact
            path={sc.useraccountroute}
            element={<UserAccount ShowAlert={messageModal} />}
          />
          <Route
            exact
            path={sc.loanpackagedetailsroute}
            element={<LoanPackage ShowAlert={messageModal} />}
          />
          <Route
            exact
            path={sc.newloanpackageroute}
            element={<NewLoanPackage ShowAlert={messageModal} />}
          />
          <Route
            exact
            path={sc.updateloanpackageroute}
            element={<NewLoanPackage ShowAlert={messageModal} />}
          />
          <Route
            exact
            path={sc.ratingroute}
            element={<Rating ShowAlert={messageModal} />}
          />
          <Route
            exact
            path={sc.photoverificationroute}
            element={<PhotoVerification ShowAlert={messageModal} />}
          />
          <Route
            exact
            path={sc.identificationverificationroute}
            element={<IdentificationVerification ShowAlert={messageModal} />}
          />
          <Route
            exact
            path={sc.setloginpinroute}
            element={<SetLoginPIN ShowAlert={messageModal} />}
          />
          <Route
            exact
            path={sc.borrowercreditcheckroute}
            element={<BorrowerCreditCheck ShowAlert={messageModal} />}
          />
          <Route
            exact
            path={sc.lendercreditcheckroute}
            element={<LenderCreditCheck ShowAlert={messageModal} />}
          />
          <Route
            exact
            path={sc.loanrequestroute}
            element={
              <LoanRequest

                ShowAlert={messageModal}
              />
            }
          />

          <Route
            exact
            path={sc.loanrequestsuccessfulroute}
            element={<LoanRequestSuccessful />}
          />
          <Route
            exact
            path={sc.loansroute}
            element={
              <Loans ShowAlert={messageModal} />
            }
          />
          <Route
            exact
            path={sc.loanrepaymentscheduleroute}
            element={
              <LoanRepaymentSchedule ShowAlert={messageModal} />
            }
          />
          <Route
            exact
            path={sc.notificationroute}
            element={
              <Notification

                ShowAlert={messageModal}
              />
            }
          />
          <Route
            exact
            path={sc.newPaymentRoute}
            element={
              <NewPayment

                ShowAlert={messageModal}
              />
            }
          />
          <Route
            exact
            path={sc.paymentroute}
            element={
              <Payment ShowAlert={messageModal} />
            }
          />
          <Route
            exact
            path={sc.paymentsroute}
            element={
              <Payments ShowAlert={messageModal} />
            }
          />
          <Route
            exact
            path={sc.profileroute}
            element={
              <Profile ShowAlert={messageModal} />
            }
          />
          <Route
            exact
            path={sc.settingsroute}
            element={
              <Settings ShowAlert={messageModal} />
            }
          />
          <Route
            exact
            path={sc.profileviewroute}
            element={
              <ProfileView ShowAlert={messageModal} />
            }
          />
          <Route
            exact
            path={sc.registerroute}
            element={
              <Register setUser={setUser} ShowAlert={messageModal} />
            }
          />
           <Route
            exact
            path={sc.registeroptionroute}
            element={<RegisterOption ShowAlert={messageModal} />}
          />
          <Route
            exact
            path={sc.smsverificationroute}
            element={<SMSVerification ShowAlert={messageModal} />}
          />
          <Route
            exact
            path={sc.welcomeroute}
            element={<Welcome />}
          />
          <Route
            exact
            path={sc.repaymentsentroute}
            element={
              <RepaymentSent

                ShowAlert={messageModal}
              />
            }
          />
          <Route
            exact
            path={sc.paymentsubmissionsuccessfulroute}
            element={
              <PaymentSubmissionSuccessful

                ShowAlert={messageModal}
              />
            }
          />
          <Route
            exact
            path={sc.repaymentconfirmedroute}
            element={
              <RepaymentConfirmed

                ShowAlert={messageModal}
              />
            }
          />
          <Route
            exact
            path={sc.faqroute}
            element={<FAQ ShowAlert={messageModal} />}
          />
          <Route exact path="*" element={<PageNotFound />} />
          <Route
            exact
            path={sc.aboutroute}
            element={<About ShowAlert={messageModal} />}
          />
          <Route
            exact
            path={sc.contactroute}
            element={<Contact ShowAlert={messageModal} />}
          />
          <Route
            exact
            path={sc.paymentdetailsroute}
            element={<PaymentDetails ShowAlert={messageModal} />}
          />
          <Route
            exact
            path={sc.updateavailableroute}
            element={<UpdateAvailable ShowAlert={messageModal} />}
          />
        </Routes>

        {/* </Router> */}
      </div>
    
    </div>
  );
}


export default App;
