import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { useEffect } from "react";
import { useState } from "react";
import { Client } from "../services/apiclient";
import sc from "../services/stringconst";
import util from "../Utilities/UtilityHelper";
import LoanDetails from "./loandetails";
import auth from "../services/authservice";
import CustomButton from "./childcomponents/custombutton"; 

const LoanRequest = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const client = new Client(null, null);

  let [loanPackage, setLender] = useState({ collateralType: [] });

  const [loanTypes, setLoanTypes] = useState([]);
  const [httpProcessing, setHttpProcessing]= useState(false);

  const back = () => {
    if (!showPreview) {
      navigate(-1);
    } else {
      setShowPreview(!showPreview);
    }
  };

  const gotoVerifyBvn = () => {
    auth.setLoanPackageId(location.state);
    navigate(sc.identificationverificationroute);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setLoanData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const date = new Date();

let day = date.getDate();
let month = date.getMonth() + 1;
let year = date.getFullYear();
let currentDate = `${day}-${month}-${year}`;

  const [options, setOptions] = useState([]);

  const [showPreview, setShowPreview] = useState(false);

  const [loanData, setLoanData] = useState({
    title: undefined,
    description: undefined,
    amount: undefined,
    principal: undefined,
    collateralType: [],
    loanPackageId: location.state,
    interest: undefined,
    loanPackageTitle: undefined,
    status: undefined,
    scheduleData: { sum: {}, items: [] },
    tandc: false,
  });

  const [collaterals, setCollaterals] = useState([]);
  const collateralSelected = (e) => {
    setCollaterals(e);
  };

  const showLoanRequestPreview = () => {

    if(!loanData.title){
      props.ShowAlert(sc.LoanTypeRequired, sc.warning);
      return;
    }
    
    if(!loanData.amount){
      props.ShowAlert(sc.AmountRequired, sc.warning);
      return;
    }
    if(loanData.amount<loanPackage.minAmount || loanData.amount>loanPackage.maxAmount){
      props.ShowAlert(sc.AmountNotWithinAllowedRange, sc.warning);
      return;
    }

    if(!loanData.description){
      props.ShowAlert(sc.DescriptionRequired, sc.warning);
      return;
    }
    if(loanPackage.collateral && collaterals.length===0){
      props.ShowAlert(sc.CollateralRequired, sc.warning);
      return;
    }

    let data = [];
    for (let i = 0; i < collaterals.length; i++) {
      data.push(collaterals[i].value);
    }

    loanData.collateralType = data;
    loanData.interest = (parseInt(loanPackage.interestPercentage.toString())/100)*loanData.amount;
    loanData.loanPackageId = loanPackage.lpId;
    loanData.loanPackageTitle = loanPackage.title;
    loanData.principal=loanData.amount;

    let payload = {
      lpId: loanPackage.lpId,
      amount: parseInt(loanData.amount),
      disbursementDate: new Date(),
    };

    client.generateSchedule(payload).then((data) => {
      loanData.scheduleData = data;
      setLoanData(loanData);
      setShowPreview(true);
    });
  };

  let submitLoanRequest = () => {
    
    if (!loanData.tandc) {
      props.ShowAlert(sc.tandcrequired, sc.warning);
      return;
    }
    setHttpProcessing(true);
      client.loans(loanData).then(data=>{
        setHttpProcessing(false);
        var dt=new Date();
        navigate(sc.loanrequestsuccessfulroute, {"state": data});
       },
       error => {
        setHttpProcessing(false);
        props.ShowAlert(error.response,  sc.danger);
       });
  };

  useEffect(() => {
    if (!auth.IsUserLoggedIn()) {
      auth.setLoanPackageId(location.state);
      navigate(sc.loginroute);
      return;
    }

    if (!auth.getPhotoVerified()) {

      if(auth.getUserProfileImageUrl()=== auth.getDefaultImageUrl()){
        props.ShowAlert('Upload and verify profile photo before applying', sc.warning);
        navigate(sc.profileroute);
        return;
      }

      if(auth.getPhotoVerifyStatus()===sc.processing){
        props.ShowAlert('Photo verification is in progress', sc.warning);
        navigate(sc.homeroute);
        return;
      }

      auth.setLoanPackageId(location.state);
      props.ShowAlert('Photo verification is required', sc.warning);
      navigate(sc.photoverificationroute);
      return;
    }
    auth.setLoanPackageId();

    if (!location.state) {
      navigate(sc.borrowroute);
    } else {
      client.getLoanTypes().then((data) => {
        setLoanTypes(data);
      });

      const lpId = location.state;

      client.loanpackage(lpId).then(
        (data) => {
          setLender(data);

          let collateralTypes = [];
          for (let i = 0; i < data.collateralType.length; i++) {
            collateralTypes.push({
              value: data.collateralType[i],
              label: data.collateralType[i],
            });
          }
          setOptions(collateralTypes);
        },
        (error) => {}
      );
    }
  }, []);

  return (
    <>
      <div  style={{width:auth.appWidth(), marginLeft:auth.appMargin()}} className="appHeader  bg-primary text-light">
        <div className="left">
          <a onClick={back} className="headerButton goBack">
            <ion-icon name="chevron-back-outline"></ion-icon>
          </a>
        </div>
        <div className="pageTitle">New Loan</div>
      </div>
      <div id="appCapsule" className="extra-header-active full-height">
        {!showPreview && (
          <div className="section mb-5 p-2">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <span className="text-muted">Lender #</span>{" "}
                  <span style={{ fontSize: "1em" }}>
                    {" "}
                    {loanPackage.firstname} {loanPackage.lastname}
                  </span>
                </div>
                <div className="card-header">
                  <span className="text-muted">Package #</span>{" "}
                  <span style={{ fontSize: "0.7em" }}>
                    {" "}
                    {loanPackage.title}{" "}
                    <span className="badge bg-warning">
                      {loanPackage.interestPercentage}%
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <br />
            {auth.getBankVerifyStatus() != sc.verified && <>
            <div className="col-md-12">
              <div className="card" onClick={gotoVerifyBvn}>
                <div className="card-header bg-secondary">
                  <span style={{fontSize: '13px'}}>Lenders mostly consider borrowers that have verified their BVN. <a href='#'>VERIFY NOW</a></span>
                </div>
               
              </div>
            </div>
            <br />
            </>}
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="firstname">
                        Loan Type
                      </label>
                      <select
                        value={loanData.title}
                        className="form-control custom-select"
                        id="title"
                        onChange={handleChange}
                      >
                        <option key="-1">Select Loan Type</option>
                        {loanTypes.map((loanType) => (
                          <option key={loanType} value={loanType}>
                            {loanType}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="amount3">
                        Amount
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        value={loanData.amount}
                        onChange={handleChange}
                        id="amount"
                        placeholder="Enter an amount"
                      />
                      <i className="clear-input">
                        <ion-icon name="close-circle"></ion-icon>
                      </i>
                    </div>
                    <div className="input-info">
                      Min {util.commaCurrencyFormatter(loanPackage.minAmount)}{" "}
                      - Max {" "}
                      {util.commaCurrencyFormatter(loanPackage.maxAmount)}
                    </div>
                  </div>

                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="lastname">
                        Details &amp; Purpose
                      </label>
                      <textarea
                        type="text"
                        rows={2}
                        className="form-control"
                        onChange={handleChange}
                        value={loanData.description}
                        id="description"
                        placeholder="Enter loan details and purpose"
                      ></textarea>
                      <i className="clear-input">
                        <ion-icon name="close-circle"></ion-icon>
                      </i>
                    </div>
                  </div>

                  <div className="form-group basic">
                    <div className="input-wrapper">
                     {options?.length>0 && <div className="in">
                        {/* <div className="input-wrapper"  style={{width:"100%"}}> */}
                        <label className="label" htmlFor="collateral">
                          Collateral
                        </label>
                        <Select
                          value={collaterals}
                          onChange={collateralSelected}
                          className="custom-select"
                          id="collateralType"
                          options={options}
                          isMulti
                        />

                        {/* </div> */}
                      </div> }
                    </div>
                    <br />
                    <div className="form-group transparent">
                      <button
                        type="submit"
                        onClick={showLoanRequestPreview}
                        className="btn btn-primary btn-block"
                      >
                        Review
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
        )}

        {showPreview && (
          <div className="section mt-2">
            <LoanDetails loanData={loanData} loanPackage={loanPackage} />
            <br />
            {/* <LoanSchedule /> */}

            <div className="card">
              <div className="card-body">
                <div className="custom-control custom-checkbox mt-2 mb-1">
                  <div className="form-check">
                    <input
                      checked={loanData.tandc}
                      onChange={handleChange}
                      id="tandc"
                      type="checkbox"
                      className="form-check-input"
                    />
                    <label className="form-check-label" htmlFor="tandc">
                      I agree{" "}
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#termsModal"
                      >
                        terms and conditions
                      </a>
                    </label>
                  </div>
                </div>
                <br />
                <div className="form-group transparent">
                  <CustomButton httpProcessing={httpProcessing} text="Submit" onClick={submitLoanRequest} />
                </div>
              </div>
            </div>
            <br />
            {/* <!-- Terms Modal --> */}
            <div
              className="modal fade modalbox"
              id="termsModal"
              tabIndex="-1"
              role="dialog"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content"  style={{width:auth.appWidth(), marginLeft:auth.appMargin()}}>
                  <div className="modal-header" style={{width:auth.appWidth(), marginLeft:auth.appMargin()}}>
                    <h5 className="modal-title">Loan Application  Terms and Conditions</h5>
                    <a href="#" data-bs-dismiss="modal">
                      Close
                    </a>
                  </div>
                  <div className="modal-body">
                  <h5>This Loan Application Terms & Agreement ("Agreement") is between [<b>{loanPackage.firstname} {loanPackage.lastname}</b>], ("Lender") and [<b>{auth.getUserProfileFirstname()} {auth.getUserProfileLastname()}</b>], ("Borrower"), and is effective as of [date] (<b>{currentDate}</b>).</h5>
                    <h5>Loan</h5>
                    <p>
                    Subject to the terms of this Agreement, Lender will loan to Borrower [<b>{loanData.amount}</b>] ("Loan") on loan disbursement date. 

                    </p>
                    <h5>Interest</h5>
                    <p>
                    The Loan will be repaid every [{loanPackage.repaymentFrequency}]{" "}
                    {loanPackage.repaymentType} due on the last day of each repayment period, beginning on first date of each repayment period and ending on [specify date]. 
                    
                    </p>
                    <h5>Default</h5>
                    <p>
                    In the event of a default in repayment of the Loan by Borrower, Borrower agrees to pay all costs of collection, including reasonable attorney’s fees. 

                    </p>
                    <h5>Security Interest</h5>
                    <p>
                    To secure the Loan, Borrower grants to Lender a security interest in any and all assets of Borrower, including without limitation any accounts receivable and inventory.                     </p>
                    <h5>Waiver</h5>
                    <p>
                    Any waiver by either party of any term or provision of this Agreement must be in writing and signed by the waiving party. 
                    </p>
                    <h5>Notices</h5>
                    <p>
                    Any notices required or permitted to be given to either party by this Agreement must be communicated to both parties indicated below:
                    </p>
                    <h5>We the lender [<b>{loanPackage.firstname} {loanPackage.lastname}</b>] </h5>
                    <h5>You the borrower [<b>{auth.getUserProfileFirstname()} {auth.getUserProfileLastname()}</b>]</h5>
                    <br/>
                    <h5>Entire Agreement</h5>
                    <p>
                    This Agreement represents the entire understanding between the parties with respect to the subject matter hereof and supersedes any prior agreements or understandings, written or oral.
                    </p>
                    <h5>We the lender [<b>{loanPackage.firstname} {loanPackage.lastname}</b>] </h5>
                    <h5>You the borrower [<b>{auth.getUserProfileFirstname()} {auth.getUserProfileLastname()}</b>]</h5>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- * Terms Modal --> */}
          </div>
        )}
      </div>
    </>
  );
};
export default LoanRequest;
