import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Client } from "../services/apiclient";
import sc from "../services/stringconst";
import * as moment from 'moment';
import LoadingTextPlaceHolder from "./childcomponents/loadingtextplaceholder";
import auth from "../services/authservice";

const Rating=(props)=> {

    const navigate= useNavigate();
    const location= useLocation();
    const client= new Client();

    let [dataLoaded, setDataLoaded]=useState(false);

    const [ratingComments, setRatingComments]= useState([]);
    const [allRatingsShown, setAllRatingsShown]=useState(false);
    
    let user= location.state;
   

    let rating= user.rating;
    if(!rating){
      rating={one:0,two:0,three:0,four:0,five:0};
    }
    let totalRating=rating.one + rating.two + rating.three + rating.four + rating.five;
    
    let oneratingPercent=Math.round((rating.one/totalRating) * 100);
    let tworatingPercent=Math.round((rating.two/totalRating) * 100);
    let threeratingPercent=Math.round((rating.three/totalRating) * 100);
    let fourratingPercent=Math.round((rating.four/totalRating) * 100);
    let fiveratingPercent=Math.round((rating.five/totalRating) * 100);

    let forUserName=`${user.firstname} ${user.lastname}`;
      let forUserId=user._id;

const back=()=>{
    navigate(-1);
  }

useEffect(()=>{
  init();

},[]);

const init=()=>{
  setDataLoaded(false);
  client.getUserRatingComments(forUserId, ratingComments.length).then(
    (data)=>{
      setDataLoaded(true);
        if(data.length<10){
            setAllRatingsShown(true);
        }
        let tempRatingComments= [...ratingComments];
        for(let i=0; i<data.length; i++){
          tempRatingComments.push(data[i]);
      }
        setRatingComments(tempRatingComments);
    },(error)=>{
        props.ShowAlert(error.response, sc.danger);
    }
)
}

    return(
      <>

    {/* App Capsule */}
    <div id="appCapsule"  className="extra-header-active full-height" style={{background:"white"}}>


    {/* <!-- App Header --> */}
    <div  style={{width:auth.appWidth(), marginLeft:auth.appMargin()}} className="appHeader   bg-primary text-light">
        <div className="left">
        <a onClick={back} className="headerButton goBack">
            <ion-icon name="chevron-back-outline"></ion-icon>
          </a>
        </div>
        <div className="pageTitle">
            {forUserName}
        </div>
       
    </div>

  <div  style={{padding:"1em"}}>
      
      <div className="card bg-primary">
          <div className="card-body">
          <div className="section full">
        <div>Ratings &amp; Reviews
        <div style={{float: "right"}}><span style={{fontSize:"1.5em",color:"white", fontSize: "3em"}}>{user.ratingAverage??0}</span>
        <span style={{color:"white"}} >/5</span></div>
        </div>
        </div>
     
        
          </div>

      </div>
      <br/>
        <div>
            <div className="row">
                <div className="col-3"><img style={{height:"1em",marginTop:"-1.7em"}} src="https://loanhub.ng/appimages/ratingscale5.jpg" /></div>
              <div  className="col-6">  <div className="progress" style={{height:"0.3em"}}>
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{
                                    width: `${fiveratingPercent}%`,
                                  }}
                                ></div>
                              </div>
                </div>
            </div>
            <div className="row">
                <div className="col-3"><img style={{height:"1em",marginTop:"-2.7em"}} src="https://loanhub.ng/appimages/ratingscale4.jpg" /></div>
              <div  className="col-6">  <div className="progress" style={{marginTop:"-0.6em", height:"0.3em"}}>
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{
                                    width: `${fourratingPercent}%`,
                                  }}
                                ></div>
                              </div>
                </div>
            </div>
            <div className="row">
                <div className="col-3"><img style={{height:"1em",marginTop:"-3.7em"}} src="https://loanhub.ng/appimages/ratingscale3.jpg" /></div>
              <div  className="col-6">  <div className="progress" style={{marginTop:"-1.3em", height:"0.3em"}}>
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{
                                    width: `${threeratingPercent}%`
                                  }}
                                ></div>
                              </div>
                </div>
            </div>
            <div className="row">
                <div className="col-3"><img style={{height:"1em",marginTop:"-4.7em"}} src="https://loanhub.ng/appimages/ratingscale2.jpg" /></div>
              <div  className="col-6">  <div className="progress" style={{marginTop:"-1.8em", height:"0.3em"}}>
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{
                                    width: `${tworatingPercent}%`
                                  }}
                                ></div>
                              </div>
                </div>
            </div>
            <div className="row">
                <div className="col-3"><img style={{height:"1em",marginTop:"-5.7em"}} src="https://loanhub.ng/appimages/ratingscale1.jpg" /></div>
              <div  className="col-6">  <div className="progress" style={{marginTop:"-2.6em", height:"0.3em"}}>
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{
                                    width: `${oneratingPercent}%`
                                  }}
                                ></div>
                              </div>
                </div>
            </div>

            {totalRating} total ratings
        </div>
        </div>

  <div className="section mt-3 mb-3">

  <div className="card">
    {ratingComments.length} comment{ratingComments.length>1?'s':''}
  {ratingComments.map(item=>
      <div className="card-body text-muted">
        
        <div style={{float:"right"}}>{moment(item.audit.createdOn).format("DD.MMM.YY")}</div>
          <span className=""><span style={{color:"black"}}>{item.byUserFirstName} {item.byUserLastName}</span></span>
          <span><img src={"https://loanhub.ng/appimages/ratingsmall"+item.rating+".jpg"} /></span>
          <br/> 
          {item.comment}
          <hr/>
      </div>)}
  </div>

  {!dataLoaded &&
          <><br/>
           <LoadingTextPlaceHolder />

          </>}
</div>

<br/>
           {!allRatingsShown &&     <div className="section mb-2">
            <a onClick={init} className="btn btn-block btn-secondary">
              Load More
            </a>
          </div>    } 


    </div>
    {/* <!-- * App Capsule --> */}

      </>
    )
}
export default Rating;