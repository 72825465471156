import React, { useState } from "react";
import sc from "../../services/stringconst";

const InputLabel =(props)=> {


  let label=props.label;

  return (
    <div className="form-group basic">
      <div className="input-wrapper">
        <label className="label" htmlFor="input">
          {label}
        </label>
        <div className="row">
          <div style={{ width: "100%" }}>
            <input
              style={{ pointerEvents: "none" }}
              type="text"
              className="form-control"
              id="input"
              value={props.value}
              
            />
          </div>

       
        </div>
      </div>
    </div>
  );
};
export default InputLabel;
