import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import sc from "../services/stringconst";
import { Client } from "../services/apiclient";
import CustomButton from "./childcomponents/custombutton"; 
import auth from "../services/authservice";


const ForgotPassword =(props)=> {

    let client= new Client(null,null);
let navigate= useNavigate();

const goToLogin=(()=>{
    navigate('/login');
});

const [email, setEmail]= useState();
const [code, setCode]= useState();
const [newPassword, setNewPassword]= useState();
const [retypePassword, setRetypePassword]= useState();
const [codeSent, setCodeSent]= useState();
const [httpProcessing, setHttpProcessing]= useState(false);



const setEmailValue=(e)=>{
    setEmail(e.target.value);
}

const setCodeValue=(e)=>{
    setCode(e.target.value);
}

const setNewPasswordValue=(e)=>{
    setNewPassword(e.target.value);
}

const setRetypeNewPasswordValue=(e)=>{
    setRetypePassword(e.target.value);
}

const resetPassword=()=>{

    if(!email){
        props.ShowAlert(sc.emailrequired, sc.warning);
        return;
      }
      setHttpProcessing(true);
      client.resetpassword({email:email}).then((data)=>{
        setHttpProcessing(false);
        setCodeSent(true);
        props.ShowAlert(data, sc.success);
      },
      (error)=>{
        setHttpProcessing(false);
        props.ShowAlert(error.response, sc.danger);
      });
}


const completeResetPassword=()=>{

    if(!email){
        props.ShowAlert(sc.emailrequired, sc.warning);
        return;
      }
      if(!code){
        props.ShowAlert(sc.coderequired, sc.warning);
        return;
      }
      if(!newPassword){
        props.ShowAlert(sc.passwordrequired, sc.warning);
        return;
      }
      if(newPassword!==retypePassword){
        props.ShowAlert(sc.newpasswordmismatch, sc.warning);
        return;
      }

      setHttpProcessing(true);
      client.confirmresetpassword({email:email, resetToken:code, newPassword:newPassword}).then((data)=>{
        setHttpProcessing(false);
        props.ShowAlert(sc.passwordResetSuccessful, sc.success);
        navigate(sc.loginroute);
      },
      (error)=>{
        setHttpProcessing(false);
        props.ShowAlert(error.response, sc.danger);
      });
}


    return (

        <div className="col-md-12">
        {/* <!-- App Header --> */}
        <div style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }} className="appHeader  bg-primary text-light">

        <div className="left">
            <a onClick={goToLogin} className="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon> Login
            </a>
        </div>
        <div className="pageTitle">Forgot Password</div>
        <div className="right">
        </div>
    </div>
    <div id="appCapsule" className="extra-header-active full-height">
        <div className="section mb-5 p-2">
    <div className="text-center">
            <img src="assets/img/logo_transparent.png" className="img-fluid" style={{maxWidth: auth.appLoginLogoWidth()}}/>
            </div>
           <div className="card" style={{ marginTop: "5%" }}>
            
                <div  className="card">
                    <div  className="card-body pb-1">

                        <div  className="form-group basic">
                            <div  className="input-wrapper">
                                <label  className="label" htmlFor="email1">Username | Email | Number</label>
                                <input type="email" onChange={setEmailValue}  className="form-control" id="email1" placeholder=""/>
                                <i  className="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                            {codeSent &&  <>
                            <br/>
                            <div  className="input-wrapper">
                                <label  className="label" htmlFor="code">Reset Token</label>
                                <input type="password" onChange={setCodeValue}  className="form-control" id="code" placeholder="Enter reset code"/>
                                <i  className="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                            <br/>
                            <div  className="input-wrapper">
                                <label  className="label" htmlFor="newPassword">New Password (6-12 Char.)</label>
                                <input type="password" onChange={setNewPasswordValue}  className="form-control" id="newPassword" placeholder="Enter new password"/>
                                <i  className="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                            <br/>
                            <div  className="input-wrapper">
                                <label  className="label" htmlFor="retypeNewPassword">Retype - New Password</label>
                                <input type="password" onChange={setRetypeNewPasswordValue}  className="form-control" id="retypeNewPassword" placeholder="Retype new password"/>
                                <i  className="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                            </>}
                        </div>
                        <br/>
                        <div  className="form-group transparent">
                  {!codeSent && 
                  <CustomButton httpProcessing={httpProcessing} text="Get Reset Token" onClick={resetPassword} />}
                  {codeSent && 
                  <CustomButton httpProcessing={httpProcessing} text="Reset Password" onClick={completeResetPassword} />
                   }
                  
                </div>
                <br/>
                    </div>
                </div>

             
            
        </div>
        </div></div>
      </div>
    );
  
}
export default ForgotPassword;