import React, { useState, useEffect } from "react";
import { Client } from "../services/apiclient";
import auth from "../services/authservice"
import { useNavigate } from "react-router-dom";
import sc from "../services/stringconst"
import util from "../Utilities/UtilityHelper";
import AppVer from "../services/apiclient";


const Settings = (props) => {
  
  let navigate= useNavigate();
    let client= new Client();


  const [url, setUrl] = useState(auth.getUserProfileImageUrl());
  const [isLender, setIsLender] = useState(auth.userIsLender());

  useEffect(()=>{    


},[]);

  
const setLenderProfileValue=()=>{
  let newIsLenderValue=!isLender;
  setIsLender(newIsLenderValue);
  
  client.updateislender({"isLender":newIsLenderValue}).then(data=>{
    auth.updateislender(newIsLenderValue);
    props.ShowAlert(newIsLenderValue?'Your loan packages will now be visible to borrowers':'Your loan packages will no longer be visible to borrowers'
    , newIsLenderValue? sc.success: sc.warning);
},
   error => {
    props.ShowAlert(error.response,  sc.danger);
   });
}

  const back=()=>{
    navigate(-1);
  }

  const setLoginPin=()=>{
    navigate(sc.setloginpinroute);
  }

  const changePassword=()=>{
    navigate(sc.changepasswordroute);
  }
  const loanPackage=()=>{
    navigate(sc.loanpackageroute, {state:undefined});
  }

  
    const useraccount=()=>{
    navigate(sc.useraccountroute);
  }

  return (
    <>
      <div  style={{width:auth.appWidth(), marginLeft:auth.appMargin()}} className="appHeader  bg-primary text-light">
        <div className="left">
          <a onClick={back} className="headerButton goBack">
            <ion-icon name="chevron-back-outline"></ion-icon>
          </a>
        </div>
        <div className="pageTitle">{auth.getUserProfileFirstname()}  {auth.getPhotoVerified() && <img width="20" src="https://loanhub.ng/appimages/photoverified.png" />}
        {auth.getBankVerified() && <img width="20" src="https://loanhub.ng/appimages/bankverified.png" />}</div>
      </div>
      <div id="appCapsule" className="extra-header-active full-height">
      
        <div className="card"  style={{"margin":"1em"}}>
          <ul className="listview image-listview transparent flush">
          <li>
              <div className="item">
                <div className="icon-box bg-primaryxx">
                  <ion-icon name="business-outline"></ion-icon>
                </div>
               <div className="in">
                  <div>Lender Profile</div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox" onChange={setLenderProfileValue}
                      id="lenderProfile" checked={isLender}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="lenderProfile"
                    ></label>
                  </div>
                </div>
              </div>
            </li>
            {isLender && <li>
              <div className="item">
                <div className="icon-box bg-primaryxx">
                <ion-icon name="grid-outline"></ion-icon>
                </div>
                <div className="in">
                  <div>My Loan Packages</div>
                  <div className="form-check" onClick={loanPackage}>
                    <span
                      className="right text-primary"
                      style={{ fontSize: "2em" }}
                    >
                      <ion-icon name="open-outline"></ion-icon>
                    </span>
                  </div>
                </div>
              </div>
            </li>}
            {!auth.getBankVerified() && <>         
            {(auth.getBankVerifyStatus()=== sc.failed || auth.getBankVerifyStatus()=== "")   && <li>
              <div className="item">
                <div className="icon-box">
                <img width="30" src="https://loanhub.ng/appimages/bankverified.png" />
                </div>
                <div className="in">
                  <div>{auth.getBankVerifyStatus()!== sc.failed?"Verify BVN":<span className="badge badge-danger">Bank Verification Failed</span>}</div>
                  <div className="form-check" onClick={()=>navigate(sc.identificationverificationroute)}>
                    <span
                      className="right text-primary"
                      style={{ fontSize: "2em" }}
                    >
                      <ion-icon name="open-outline"></ion-icon>
                    </span>
                  </div>
                </div>
              </div>
            </li>}</>}
            <li>
              <div className="item">
                <div className="icon-box bg-primaryxx">
                <ion-icon name="wallet-outline"></ion-icon>
                </div>
                <div className="in">
                  <div>Bank Accounts</div>
                  <div className="form-check" onClick={useraccount}>
                    <span
                      className="right text-primary"
                      style={{ fontSize: "2em" }}
                    >
                      <ion-icon name="open-outline"></ion-icon>
                    </span>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="item">
                <div className="icon-box bg-primaryxx">
                <ion-icon name="lock-closed-outline"></ion-icon>
                </div>
                <div className="in">
                  <div>Set Login PIN</div>
                  <div className="form-check" onClick={setLoginPin}>
                    <span
                      className="right text-primary"
                      style={{ fontSize: "2em" }}
                    >
                      <ion-icon name="create-outline"></ion-icon>
                    </span>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="item">
                <div className="icon-box bg-primaryxx">
                  <ion-icon name="key-outline"></ion-icon>
                </div>
                <div className="in">
                  <div>Change Password</div>
                  <div className="form-check" onClick={changePassword}>
                    <span
                      className="right text-primary"
                      style={{ fontSize: "2em" }}
                    >
                      <ion-icon name="create-outline"></ion-icon>
                    </span>
                  </div>
                </div>
              </div>
            </li>
            
          </ul>
        </div>
        {/* Chat profile owner from here */}

      </div>


    </>
  );
};
export default Settings;
