import React from "react";
import LoanDetails from "./loandetails";
import { useEffect } from "react";
import { Client } from "../services/apiclient";
import { useState } from "react";
import sc from "../services/stringconst";
import util from "../Utilities/UtilityHelper";
import { useLocation } from "react-router-dom";
import auth from "../services/authservice";
import { useNavigate } from "react-router-dom";
import * as moment from "moment";
import ConfirmModal from "./childcomponents/confirmmodal";
import LoanApprovalModal from "./childcomponents/loanapprovalmodal";
import { useScrollBy } from "react-use-window-scroll";
import ModelBuilder from "../services/modelbuilder";
import RatingModal from "./childcomponents/ratingmodal";
import ReportModal from "./childcomponents/reportmodal";
import LoadingTextPlaceHolder from "./childcomponents/loadingtextplaceholder";
import CustomButton from "./childcomponents/custombutton";
import stringconst from "../services/stringconst";

const Loans = (props) => {
  const navigate = useNavigate();
  const client = new Client(null, null);
  const location = new useLocation();
  const scrollBy = useScrollBy();

  
  let [dataLoaded, setDataLoaded]=useState(false);
  const [borrowedLoans, setBorrowedLoans] = useState([]);
  const [LendedLoans, setLendedLoans] = useState([]);
  const [displayTab, setDisplayTab] = useState(1);
  const [httpProcessing, setHttpProcessing]= useState(false);


  const [isLender, setIsLender] = useState(false);


  const [viewLoanData, setViewLoanData] = useState({collateralType:[]});
  const [viewLoanPackage, setViewLoanPackage] = useState({collaterals:[]});


  const [showApprovalConfirmModal, setApprovalShowConfirmModal] = useState(false);
  const [showConfirmDisburementModal, setShowConfirmDisburementModal] = useState(false);
  const [showCancelLoanModal, setShowCancelLoanModal] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState();
  const [confirmMessage, setConfirmMessage] = useState();
  const [confirmButtons, setConfirmButtons] = useState([]);


  let loanId = undefined;
  if (location.state) {
    loanId = location.state.loanId;
  }
  const collapsedAccordionClass = "accordion-collapse collapse";
  const expandedAccordionClass = "accordion-collapse collapse show";
  const collapsedAccordionButtonClass = "accordion-button collapsed";
  const expandedAccordionButtonClass = "accordion-button";

  let isPending= false;
  let isRejected= false;
  let isActive= false;

  if (location.state) {
    isPending=location.state.isPending;
    isRejected=location.state.isRejected;
    isActive= location.state.isActive;
  }

  let displayTabValue = 1;
  useEffect(() => {
    if (location.state) {
      setIsLender(location.state.isLender);
      if (location.state.isLender) {
        displayTabValue = 2;
      } else {
        displayTabValue = 1;
      }
    } else {
      if (auth.userIsLender()) {
        setIsLender(true);
        displayTabValue = 2;
      }
    }

    init(displayTabValue);
  }, []);

  const showDetails=(loanData, loanPackage)=>{


        let payload = {
          loanId:loanData.loanId,
          //lpId: loanData.loanPackageId,
          amount: parseInt(loanData.principal),
          disbursementDate: new Date(),
        };

    let client= new Client(null,null);
    setHttpProcessing(true);
        client.generateSchedule(payload).then((data) => {
          setHttpProcessing(false);
          loanData.scheduleData=data;
          setViewLoanData(loanData);
          setViewLoanPackage(loanPackage);

        });

  }

  const confirmDisbursement=()=>{


    let client= new Client(null,null);

        client.confirmdisburement({id:loanIdFocused}).then((data) => {

          let tempBorrowedLoans= [...borrowedLoans];
          for(let i=0; i< tempBorrowedLoans.length; i++){
            if(tempBorrowedLoans[i].loanData.loanId===loanIdFocused){
              tempBorrowedLoans[i].loanData.disbursed=true;
            }
              
          }
          setBorrowedLoans(tempBorrowedLoans);

          setShowConfirmDisburementModal(false);
          props.ShowAlert(sc.DisbursementConfirmed, sc.success);
        }, error=>{
          props.ShowAlert(error.response, sc.danger);
        });

  }
  
  const confirmLoanCancellation=()=>{


    let client= new Client(null,null);

        client.cancelloan({id:loanIdFocused}).then((data) => {

          let tempLendedLoans= [...LendedLoans];
          let index= tempLendedLoans.findIndex(o=>o.loanData.loanId===loanIdFocused);

          tempLendedLoans.splice(index,1);
          setLendedLoans(tempLendedLoans);

          setShowCancelLoanModal(false);
          props.ShowAlert(sc.CancellationConfirmed, sc.success);
        }, error=>{
          props.ShowAlert(error.response, sc.danger);
        });

  }
  const resetViewLoanDataLoanPackage=()=>{
    setViewLoanData({collateralType:[]});
    setViewLoanPackage({collaterals:[]});
  }

  const init = (displayTabValue) => {
    
    setDisplayTab(displayTabValue);

    setDataLoaded(false);
    client.borrowedLoans().then(
      (data) => {
        setDataLoaded(true);

        if (location.state) {
          if (location.state.status) {
            data = data.filter((o) => location.state.status.indexOf(o.status)>-1);
          }
        }
        setBorrowedLoans(setLoanDataValue(data, true));

        if (location.state) {
          if (location.state.loanPagePosition) {
            scrollBy({
              top: parseInt(location.state.loanPagePosition.toString()),
              left: 0,
              behavior: "smooth",
            });
          }
        }
      },
      (error) => {
        props.ShowAlert(error.response, sc.danger);
      }
    );

    client.lendedLoans().then(
      (data) => {
        setDataLoaded(true);
        
        data = data.sort((a, b) => (b.audit.createdOn - a.audit.createdOn));

        if (location.state) {
          if (location.state.status) {
            data = data.filter((o) => location.state.status.indexOf(o.status)>-1);
          }
        }
        setLendedLoans(setLoanDataValue(data, false));

        if (location.state) {
          
          if (isLender && location.state.loanPagePosition) {
            scrollBy({
              top: parseInt(location.state.loanPagePosition.toString()),
              left: 0,
              behavior: "smooth",
            });
          }
        }
      },
      (error) => {
        props.ShowAlert(error.response, sc.danger);
      }
    );
  };

  const setLoanDataValue = (data, isBorrowed) => {

    let userLoan = [];

    for(let i=0; i< data.length; i++){
      let item= data[i];

      if(isPending && isBorrowed && item.status!== sc.Pending){
        continue;
      }
      if(isRejected && isBorrowed && item.status!== sc.Disapproved){
        continue;
      }
      if(isActive && isBorrowed && item.status!== sc.Open){
        continue;
      }
      userLoan.push(ModelBuilder.buildLoanDetailsData(item));
    }
    return userLoan;
  };

  const setDisplay = () => {
    let newIsLenderValue = !isLender;

    setDisplayTab(newIsLenderValue ? 2 : 1);
    setIsLender(newIsLenderValue);
  };
const [approvalAction,setApprovalAction]= useState();
const [loanIdFocused,setLoanIdFocused]= useState();
const [amountFocused,setAmountFocused]= useState();
const [showApprovalModal,setShowApprovalModal]= useState(false);
const [showRatingModal,setShowRatingModal]= useState(false);
const [showReportModal,setShowReportModal]= useState(false);
const [ratingUserId,setRatingUserId]= useState();
const [ratingRefId,setRatingRefId]= useState();
const [reportUserId,setReportUserId]= useState();
const [reportRefId,setReportRefId]= useState();
const [borrowerVerificationStatus,setBorrowerVerificationStatus]= useState({isPhotoVerified : null, isBVNVerified : null });


const [approvalPayload,setApprovalPayload]= useState();

  const showLoanApprovalModal = (loanId, action) => {
    setApprovalAction(action);
    setLoanIdFocused(loanId);
    setShowApprovalModal(true);
  }
  const showConfirmDisbursementModalValue = (loanId, amount) => {
    setLoanIdFocused(loanId);
    setAmountFocused(amount);
    setShowConfirmDisburementModal(true);

  }

  const showCancelLoanModalValue = (loanId, amount) => {
    setLoanIdFocused(loanId);
    setAmountFocused(amount);
    setShowCancelLoanModal(true);

  }

  const getFriendlyState = (status)=>{
    if(status===sc.Open){
      return sc.Active;
    }
    return status;
  }

  const setShowRatingModalValue=(loanData)=>{

    let userId=isLender?loanData.borrower:loanData.lender;
    let loanrefId = loanData.loanId;
    setRatingUserId(userId);
    setRatingRefId(loanrefId);

    setShowRatingModal(true);
  }
  const setShowReportModalValue=(loanData)=>{

    let userId=isLender?loanData.borrower:loanData.lender;
    let loanrefId = loanData.loanId;
    setReportUserId(userId);
    setReportRefId(loanrefId);

    setShowReportModal(true);
  }
  const getPendingLoanUser=(loanIdToSearch)=>{
    setBorrowerVerificationStatus({isPhotoVerified : null, isBVNVerified : null });
    if(displayTab == 1){
      return;
    }
    
    let loan = LendedLoans.find( o=> o.loanData.loanId == loanIdToSearch);
    if(loan?.loanData?.status == stringconst.Pending){
      client.user(loan.loanData.borrower).then(borrower =>{
        borrowerVerificationStatus.isPhotoVerified = borrower.photoVerification.verified == stringconst.verified;
        borrowerVerificationStatus.isBVNVerified = borrower.bankVerification.verified == stringconst.verified;
        setBorrowerVerificationStatus(borrowerVerificationStatus);
      })
    }
  }
  const submitRating=(rating,comment)=>{

    if(!rating){
      props.ShowAlert(sc.ratingrequired, sc.danger);
      return;
    }

    let ratingData={forUserId:ratingUserId, comment:comment, rating: rating, refId: ratingRefId};

    client.rateuser(ratingData).then(
      (data)=>{
        setRatingUserId();
        setShowRatingModal(false);
        props.ShowAlert("Rating Submitted!", sc.success);
      },
      (error)=>{
        props.ShowAlert(error.response, sc.danger);
      }
    )
  }

  const submitReport=(comment)=>{

    if(!comment){
      props.ShowAlert(sc.commentrequired, sc.danger);
      return;
    }

    let reportData={forUserId:reportUserId, comment:comment, refId: reportRefId};

    client.reportuser(reportData).then(
      (data)=>{
        setReportUserId();
        setShowReportModal(false);
        props.ShowAlert("Report Submitted!", sc.success);
      },
      (error)=>{
        props.ShowAlert(error.response, sc.danger);
      }
    )
  }
  const cancelShowRatingModal=()=>{
    setShowRatingModal(false);
  }
  const cancelShowReportModal=()=>{
    setShowReportModal(false);
  }

  const approveLoan = (loanId, disbursementDate, lenderNote, ignoreLPChange) => {

    if (!disbursementDate) {
      props.ShowAlert(sc.disburementdaterequired, sc.warning);
      return;
    }
    if (new Date(`${disbursementDate} 23:59:59`)<new Date()) {
      props.ShowAlert(sc.disburementdatecannotbelessthantodayrequired, sc.warning);
      return;
    }

    setApprovalShowConfirmModal(false);
    setShowApprovalModal(false);
    // if (!lenderNote) {
    //   props.ShowAlert(sc.lendernoterequired, sc.warning);
    //   return;
    // }
let payload={
        loanId: loanId,
        disbursementDate: disbursementDate,
        lenderNote: lenderNote,
        ignoreLPChange:ignoreLPChange // ignore Loan Package Change
      };
      setApprovalPayload(payload);

      setHttpProcessing(true);
    client
      .approveloan(payload)
      .then(
        (data) => {
          setHttpProcessing(false);
          props.ShowAlert("Approved", sc.success);
          navigate(sc.loanapprovalcompletedroute, { state: data });
          setApprovalPayload();
        },
        (error) => {
          setHttpProcessing(false);
          if (error.status === 403) {
            setConfirmTitle("Action Required");
            setConfirmMessage(error.response);
            setConfirmButtons(["Approve", "Cancel"]);
            setApprovalShowConfirmModal(true);
          } else {
            props.ShowAlert(error.response, sc.danger);
          }
        }
      );
  };

  const disapproveloan = (loanId, lenderNote) => {

    if (!lenderNote) {
      props.ShowAlert(sc.lendernoterequired, sc.warning);
      return;
    }

    setApprovalShowConfirmModal(false);
    setShowApprovalModal(false);

    setHttpProcessing(true);
    client.disapproveloan({ loanId: loanId, lenderNote: lenderNote }).then(
      (data) => {
        setHttpProcessing(false);
        props.ShowAlert(sc.Disapproved, sc.success);
        init(2);
      },
      (error) => {
        setHttpProcessing(false);
        props.ShowAlert(error.response, sc.danger);
      }
    );
  };
  const approveLoanWithInitialLoanPackage = () => {
    setApprovalShowConfirmModal(false);
    let data=approvalPayload;
    approveLoan(data.loanId, data.disbursementDate, data.lenderNote, true);
    //alert("approveLoanWithInitialLoanPackage");
  };
  const cancelAction = () => {
    setApprovalShowConfirmModal(false);
  };
  const cancelLoanApprovalAction = () => {
    setShowApprovalModal(false);
  };
  const abandonloan = (loanId) => {
    client.abandonloan({ loanId: loanId }).then(
      (data) => {
        props.ShowAlert("Abandoned", sc.success);
        init(1);
      },
      (error) => {
        props.ShowAlert(error.response, sc.danger);
      }
    );
  };

  const loadPaymentSchedule = (loan, loanPackage) => {
    navigate(sc.loanrepaymentscheduleroute, {
      state: {
        loanPackage: loanPackage,
        loan: loan,
        status:location.state?location.state.status:null,
        loanDisplayTab: displayTab,
        loanPagePosition: window.scrollY,
      },
    });
  };

  return (
    <>
      <div  style={{width:auth.appWidth(), marginLeft:auth.appMargin()}} className="appHeader  bg-primary text-light">
        <div className="pageTitle">Loans</div>
      </div>

      <div id="appCapsule" className="extra-header-active full-height">
        <div className="section mt-2">
        <div className="card">
            <div className="card-body pt-0" style={{ background: "#f9f9f9" }}>
              <ul className="nav nav-tabs lined" role="tablist">
              <li className="nav-item">
                  <a
                    className={
                      displayTab === 1 ? "nav-link active" : "nav-link"
                    }
                    onClick={setDisplay}
                  >
                    Borrower
                  </a>
                </li>
               <li className="nav-item">
                  <a
                    className={
                      displayTab === 2 ? "nav-link active" : "nav-link"
                    }
                    onClick={setDisplay}
                  >
                    Lender
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <br />


          <div className="transactions">
            <div className="accordion" id="loanpkg">
              {((displayTab === 1) ? borrowedLoans : LendedLoans).map((value) => (
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    style={{ background: "#f9f9f9" }}
                  >
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <button
                            className={
                              value.loanData.loanId === loanId
                                ? expandedAccordionButtonClass
                                : collapsedAccordionButtonClass
                            }
                            onClick={() => getPendingLoanUser(value.loanData.loanId)}
                            type="button"
                            data-bs-toggle="collapse"
                            aria-expanded={
                              value.loanData.loanId === loanId
                                ? "true"
                                : "false"
                            }
                            data-bs-target={"#acc" + value.loanData.loanId}
                          >
                            <td style={{ width: "60%" }}>
                              <b>
                                <table>
                                  <tr>
                                    <td> {value.loanData.status===sc.Open &&
                                    <ion-icon style={{ fontSize:"1.3em"}} name="folder-open-outline"></ion-icon>}
                                    {value.loanData.status===sc.Closed &&
                                    <ion-icon style={{ fontSize:"1.3em"}} name="checkmark-done-outline"></ion-icon>}
                                    {value.loanData.status===sc.Pending &&
                                    <ion-icon style={{ fontSize:"1.3em"}} name="alert-outline"></ion-icon>}
                                    {value.loanData.status===sc.Approved &&
                                    <ion-icon style={{ fontSize:"1.3em"}} name="checkmark-outline"></ion-icon>}
                                    {value.loanData.status===sc.Disapproved &&
                                    <ion-icon style={{ fontSize:"1.3em"}} name="close-outline"></ion-icon>}
                                    {value.loanData.status===sc.Abandoned &&
                                    <ion-icon style={{ fontSize:"1.3em"}} name="trash-bin-outline"></ion-icon>}</td>
                                    <td>{value.loanData.title}
                                    <span className="text-muted"><br/>
                                  {isLender
                                    ? value.loanData.borrowerFirstname
                                    : value.loanData.lenderFirstname}
                                </span></td>
                                  </tr>
                                </table>
                             
                             
                            
                              </b>
                            </td>
                            <td style={{ width: "40%" }}>
                              <div style={{ width: "100%" }}>
                                
                                  <span
                                    className={util.getcssClassByStatus(value.loanData.status)}
                                    style={{ width: "100%" }}
                                  >
                                   
                                    {util.commaCurrencyFormatter(value.loanData.principal)}
                                  </span><br/>
                                  <div style={{marginTop:"0.3em", float:"right" }}>
                                  <span className="text-muted"><span className={util.getcssClassByStatus(value.loanData.status, true)}>{getFriendlyState(value.loanData.status)}</span></span>
                                   &nbsp;
                                  {value.loanData.paymentProgress < 100 &&
                                value.loanData.status === sc.Open && (
                                  <>
                                   
                                    <span className={util.getcssClassByStatus(value.loanData.status, true)}>{Math.trunc(value.loanData.paymentProgress*100)/100}%</span>
                                    &nbsp;
                                      {
                                        value.loanData.hasUnapprovedPayment 
                                        && <span  className="badge badge-outline-warning">+</span>
                                      }
                                    &nbsp;
                                    {Date.parse(value.loanData.nextDueDate) >=
                                    Date.now()
                                      ? ""
                                      :
                                      <span  className="badge badge-outline-danger">!</span>
                                      }
                                  </>
                                )}</div>
                                 {value.loanData.paymentProgress === 100 &&
                                value.loanData.status === sc.Open && (
                                  <span className="badge badge-outline-success">
                                    {Math.trunc(value.loanData.paymentProgress*100)/100}%
                                  </span>
                                )}
                               
                              
                              </div>
                            </td>
                         
                          </button>
                        </tr>
                      </tbody>
                    </table>
                  </h2>
                  <div
                    id={"acc" + value.loanData.loanId}
                    className={
                      value.loanData.loanId === loanId
                        ? expandedAccordionClass
                        : collapsedAccordionClass
                    }
                    data-bs-parent="#loanpkg"
                  >
                    <div className="accordion-body">
                      <div className="card">
                      
                        <div className="card-body item" style={{fontSize:"1em"}}>
                          <div className="detail" style={{ width: "100%"}}>
                            <table style={{ width: "100%" }}>
                           {!isLender && !value.loanData.disbursed && value.loanData.status===sc.Open &&  <tr>
                                <td colSpan="2">
                                <a onClick={()=>showConfirmDisbursementModalValue(value.loanData.loanId, value.loanData.principal)} className="btn btn-block btn-outline-primary">Confirm Disbursement</a><br/>
                                <br/>
                                </td>
                              </tr>}
                              {isLender && !value.loanData.disbursed && value.loanData.status===sc.Open &&  <tr>
                                <td colSpan="2">
                                <a onClick={()=>showCancelLoanModalValue(value.loanData.loanId, value.loanData.principal)} className="btn btn-block btn-outline-danger">Cancel Loan</a><br/>
                                <br/>
                                </td>
                              </tr>}
                          <tr style={{lineHeight: "2em"}}>
                                <td>{isLender?"Borrower":"Lender"}</td>
                                <td style={{textAlign:"right"}}>{isLender && (
                                    <>
                                    <strong
                                      className="btn-text-primary"
                                      onClick={() =>
                                        navigate(sc.profileviewroute, {
                                          state: value.loanData.borrower,
                                        })
                                      }
                                    >
                                      {value.loanData.borrowerFirstname}{" "}
                                      {value.loanData.borrowerLastname}
                                    </strong>
                                    <span className="text-primary" onClick={()=>navigate(sc.borrowercreditcheckroute, {state:{name:`${value.loanData.borrowerFirstname} ${value.loanData.borrowerLastname}`, borrowerId:value.loanData.borrower}})}><ion-icon name="speedometer-outline"></ion-icon> Credit Check &nbsp; </span>
                                    <br/> <br/>
                                    </>
                                  )}
                                  {!isLender && (
                                    <>
                                    <strong
                                      className="btn-text-primary"
                                      onClick={() =>
                                        navigate(sc.profileviewroute, {
                                          state: value.loanData.lender,
                                        })
                                      }
                                    >
                                      {value.loanData.lenderFirstname}{" "}
                                      {value.loanData.lenderLastname}
                                    </strong>
                                    </>
                                  )}
                                  </td>
                              </tr>
                              <tr style={{lineHeight: "2em"}}>
<td>Principal</td>
                                <td style={{textAlign:"right"}}>
                                  
                                  <div>
                                    <div className="price">
                                     
                                      {util.commaCurrencyFormatter(
                                        value.loanData.principal
                                      )}
                                     
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr style={{lineHeight: "2em"}}>
                              <td>Interest</td>
                              <td style={{textAlign:"right"}}>
                              <div className="price"><span style={{fontWeight:"normal"}}> {util.commaCurrencyFormatter(
                                        value.loanData.interest
                                      )}</span>
                                      </div></td>
                              </tr>
                              {(value.loanData.status === sc.Approved ||
                                value.loanData.status === sc.Open ||
                                value.loanData.status === sc.Closed) && (
                                <tr style={{lineHeight: "2em"}}>
                                  <td>
                                    Disbursement
                                  </td>
                                  <td style={{textAlign:"right"}}>
                                    <span className="badge badge-outline-secondary">
                                      {moment(
                                        value.loanData.disbursementDate
                                      ).format("DD.MMM.YY")}
                                    </span>
                                  </td>
                                </tr>
                              )}
                              {value.loanData.paymentProgress !== 100 &&
                                value.loanData.nextDueDate && (
                                  <tr style={{lineHeight: "2em"}}>
                                    <td>
                                      Next Due Date
                                    </td>
                                    <td style={{textAlign:"right"}}>
                                      {Date.parse(value.loanData.nextDueDate) >=
                                        Date.now() && (
                                        <span className="badge badge-outline-secondary">
                                          {moment(
                                            value.loanData.nextDueDate
                                          ).format("DD.MMM.YY")}
                                        </span>
                                      )}
                                      {Date.parse(value.loanData.nextDueDate) <
                                        Date.now() && (
                                        <span className="badge badge-outline-danger">
                                           {moment(
                                            value.loanData.nextDueDate
                                          ).format("DD.MMM.YY")}
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                )}
                                <tr>
                                  <td colSpan="2">
                                    <hr/>
                                  </td>
                                </tr>
                            {((value.loanData.status===sc.Open || value.loanData.status===sc.Closed)) &&  <tr>
                                <td style={{textAlign:"right"}}><div style={{marginRight:"10px"}} onClick={()=>setShowRatingModalValue(value.loanData)} className="text-primary"><ion-icon name="star-half-outline"></ion-icon> Rate {isLender? value.loanData.borrowerFirstname:value.loanData.lenderFirstname}</div></td>
                                <td><div style={{marginLeft:"10px"}} onClick={()=>setShowReportModalValue(value.loanData)} className="text-warning"><ion-icon name="alert-circle-outline"></ion-icon> Report {isLender? value.loanData.borrowerFirstname:value.loanData.lenderFirstname}</div></td>
                                </tr>}
                            </table>
                            
                            <div></div>
                            
                          </div>
                        </div>

                        <div className="card-body">
                          {(value.loanData.status === sc.Open ||
                            value.loanData.status === sc.Closed) && (
                            <>
                              <span
                                className="text-muted"
                                style={{ color: "white", width: "100%" }}
                              >
                                Repayment{" "}
                                {util.commaCurrencyFormatter(
                                  value.loanData.repaidAmount
                                )}{" "}
                                ({Math.trunc(value.loanData.paymentProgress*100)/100}%)
                              </span>
                              <div className="progress mb-2">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{
                                    width: value.loanData.paymentProgress + "%",
                                  }}
                                ></div>
                              </div>
                            </>
                          )}
                         <div >

                          {isLender && value.loanData.status === "Pending" && (
                            <>
                            <span className="text-muted">It is advised that you approve and process request from verified users only. <hr/>
                           {borrowerVerificationStatus.isPhotoVerified && <><b>{value.loanData.borrowerFirstname} is photo verified</b> <img width="20" src="https://loanhub.ng/appimages/photoverified.png" /></> }
                           {!borrowerVerificationStatus.isPhotoVerified && borrowerVerificationStatus.isPhotoVerified != null && <><b>{value.loanData.borrowerFirstname} is NOT photo verified</b> <span className="text-warning"><ion-icon name="close-circle-outline"></ion-icon></span></> }

                           <br/>{borrowerVerificationStatus.isBVNVerified && <><b>{value.loanData.borrowerFirstname} is BVN verified</b> <img width="20" src="https://loanhub.ng/appimages/bankverified.png" /></>}
                           {!borrowerVerificationStatus.isBVNVerified && borrowerVerificationStatus.isPhotoVerified != null && <><b>{value.loanData.borrowerFirstname} is NOT BVN verified</b> <span className="text-warning"><ion-icon name="close-circle-outline"></ion-icon></span></>}<br/><br/>
                           </span>
                            <span style={{float:"right"}}>
                              <a
                                
                                style={{ width: "30%" }}
                                
                                data-bs-toggle="modal"
                                data-bs-target="#detailModal"
                              >
                                <CustomButton bgColor="outline-primary" inline="btn-inline" httpProcessing={httpProcessing} text="Details" onClick={()=>showDetails(value.loanData,value.loanPackage)} />
                              </a>

                              

                              <a
                                
                                onClick={() =>
                                  showLoanApprovalModal(value.loanData.loanId, sc.Disapprove)
                                }
                                style={{ width: "30%" }}
                                className="btn btn-danger marginLeft5px"
                              >
                                Disapprove
                              </a>
                              <a
                                
                                onClick={() =>
                                  showLoanApprovalModal(value.loanData.loanId, sc.Approve)
                                }
                                style={{ width: "30%" }}
                                className="btn btn-success marginLeft5px"
                              >
                                Approve
                              </a>
                            </span>
                            </>
                          )}
                          {isLender && value.loanData.status !== sc.Pending && (
                            <>
                              {(value.loanData.status === sc.Open ||
                                value.loanData.status === sc.Closed) && (
                                <span style={{float:"right"}}>
                                
                                  <a
                                    
                                    style={{ width: "45%" }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#detailModal"
                                    
                                  >
                                     <CustomButton bgColor="outline-primary" inline="btn-inline" httpProcessing={httpProcessing} text="Details" onClick={()=>showDetails(value.loanData,value.loanPackage)} />
                                  </a>
                                  <a
                                    
                                    style={{ width: "45%" }}
                                    onClick={() =>
                                      loadPaymentSchedule(value.loanData, value.loanPackage)
                                    }
                                    className="btn btn-primary marginLeft5px"
                                  >
                                    Payments
                                  </a>
                                </span>
                              )}

                              {(value.loanData.status === sc.Disapproved ||
                                value.loanData.status === sc.Abandoned) && (
                                  <span style={{float:"right"}}>
                                  <a
                                    
                                    style={{ width: "45%" }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#detailModal"
                                  >
                                     <CustomButton bgColor="outline-primary" httpProcessing={httpProcessing} text="Details" onClick={()=>showDetails(value.loanData,value.loanPackage)} />
                                  </a>
                                </span>
                              )}
                              {value.loanData.status === sc.Approved && (
                                <span style={{float:"right"}}>
                                  <a
                                    
                                    style={{ width: "45%" }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#detailModal"
                                  >
                                     <CustomButton bgColor="outline-primary" inline="btn-inline" httpProcessing={httpProcessing} text="Details" onClick={()=>showDetails(value.loanData,value.loanPackage)} />
                                  </a>
                                  <a
                                    
                                    style={{ width: "45%" }}
                                    className="btn btn-primary marginLeft5px"
                                    onClick={() =>
                                      loadPaymentSchedule(value.loanData, value.loanPackage)
                                    }
                                  >
                                    Payments
                                  </a>
                                </span>
                              )}
                            </>
                          )}
                          {!isLender && value.loanData.status === sc.Pending && (
                            <span style={{float:"right"}}>
                              <a
                                
                                style={{ width: "45%" }}
                                data-bs-toggle="modal"
                                data-bs-target="#detailModal"
                              >
                                 <CustomButton bgColor="outline-primary" inline="btn-inline" httpProcessing={httpProcessing} text="Details" onClick={()=>showDetails(value.loanData,value.loanPackage)} />
                              </a>
                              <a
                                
                                style={{ width: "45%" }}
                                className="marginLeft5px"
                               
                              >
                               <CustomButton bgColor="warning" inline="btn-inline" httpProcessing={httpProcessing} text="Abandon" onClick={()=>abandonloan(value.loanData.loanId)} />
                              </a>
                            </span>
                          )}
                          {!isLender && value.loanData.status !== sc.Pending && (
                            <>
                              {(value.loanData.status === sc.Open ||
                                value.loanData.status === sc.Closed) && (
                                  <span style={{float:"right"}}>
                                  <a
                                    
                                    style={{ width: "45%" }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#detailModal"
                                  >
                                     <CustomButton bgColor="outline-primary" inline="btn-inline" httpProcessing={httpProcessing} text="Details" onClick={()=>showDetails(value.loanData,value.loanPackage)} />
                                  </a>
                                  <a
                                    
                                    style={{ width: "45%" }}
                                    className="btn btn-primary marginLeft5px"
                                    onClick={() =>
                                      loadPaymentSchedule(value.loanData, value.loanPackage)
                                    }
                                  >
                                    Payments
                                  </a>
                                </span>
                              )}
                              {(value.loanData.status === sc.Disapproved ||
                                value.loanData.status === sc.Abandoned) && (
                                  <span style={{float:"right"}}>
                                  <a
                                    
                                    style={{ width: "45%" }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#detailModal"
                                  >
                                     <CustomButton bgColor="outline-primary" httpProcessing={httpProcessing} text="Details" onClick={()=>showDetails(value.loanData,value.loanPackage)} />
                                  </a>
                                </span>
                              )}

                              {value.loanData.status === sc.Approved && (
                                <span style={{float:"right"}}>
                                  <a
                                    style={{ width: "45%" }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#detailModal"
                                  >
                                     <CustomButton bgColor="outline-primary" inline="btn-inline" httpProcessing={httpProcessing} text="Details" onClick={()=>showDetails(value.loanData,value.loanPackage)} />
                                  </a>
                                  <a
                                    
                                    style={{ width: "45%" }}
                                    className="btn btn-primary marginLeft5px"
                                    onClick={() =>
                                      loadPaymentSchedule(value.loanData, value.loanPackage)
                                    }
                                  >
                                    Payments
                                  </a>
                                </span>
                              )}
                            </>
                          )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              ))}
            </div>
          </div>

          {!dataLoaded && <>
  <LoadingTextPlaceHolder />
                      </>}


    {viewLoanData &&  <div
                    className="modal fade modalbox"
                    id="detailModal"
                    tabIndex="-1"
                    role="dialog"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content"style={{width:auth.appWidth(), marginLeft:auth.appMargin()}}>
                        <div className="modal-header"style={{width:auth.appWidth(), marginLeft:auth.appMargin()}}>
                          <h5 className="modal-title">Details</h5>
                          <a  data-bs-dismiss="modal" 
                             onClick={resetViewLoanDataLoanPackage}
                            >
                            Close
                          </a>
                        </div>
                        <div  style={{overflow: "scroll"}}>
                          <LoanDetails
                            loanData={viewLoanData}
                            loanPackage={viewLoanPackage}
                            isLender={isLender}
                          />
                        </div>
                      </div>
                    </div>
                  </div>}

        </div>
      </div>

      {showApprovalConfirmModal && (
        <ConfirmModal
          title={confirmTitle}
          message={confirmMessage}
          caveat='Approving means current loan package settings will be ignored'
          buttons={confirmButtons}
          action1={approveLoanWithInitialLoanPackage}
          action2={cancelAction}
          httpProcessing={httpProcessing}
        />
      )}

      {showApprovalModal && (
        <LoanApprovalModal
          loanId={loanIdFocused}
          action={approvalAction}
          action1={approveLoan}
          action2={disapproveloan}
          action3={cancelLoanApprovalAction}
          httpProcessing={httpProcessing}
        />

      )}
         {showRatingModal && (
          <RatingModal submitRating={submitRating} cancelShowRatingModal={cancelShowRatingModal} />
        )}

{showReportModal && (
          <ReportModal submitReport={submitReport} cancelShowReportModal={cancelShowReportModal} />
        )}


{showConfirmDisburementModal && <ConfirmModal title='Confirm Disbursement' message={`This is to confirm that you have received the loan amount [${util.commaCurrencyFormatter(amountFocused)}] requested`} buttons={['Confirm', 'Cancel']} action1={confirmDisbursement} action2={() => setShowConfirmDisburementModal(false)} />}

{showCancelLoanModal && <ConfirmModal title='Confirm Cancellation' message={`This is to confirm that you no longer wish to process the loan of amount [${util.commaCurrencyFormatter(amountFocused)}]`} buttons={['Confirm', 'Cancel']} action1={confirmLoanCancellation} action2={() => setShowCancelLoanModal(false)} />}

      <br/>
    </>
  );
};
export default Loans;
