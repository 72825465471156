import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import auth from "./services/authservice.js";

// Replace this firebaseConfig object with the congurations for the project you created on your firebase console. 
var firebaseConfig = {
    apiKey: "AIzaSyDvxux-iRlrV6wzSorOq7JY4co6mTEtjKM",
    authDomain: "loanhub-76520.firebaseapp.com",
    projectId: "loanhub-76520",
    storageBucket: "loanhub-76520.appspot.com",
    messagingSenderId: "19473474906",
    appId: "1:19473474906:web:6239b5246db532c3817f63",
    measurementId: "G-KDV0G7GHK1"
  };

  export const requestFirebaseForToken = () => {

    return getToken(messaging, { vapidKey: 'BCK43hxknOG37Rl6Ocy9_JPGoWfvYqQlpTz1F0F_p4t6jlJGjBvgTRfJZhYAIwMoukzFCqUWFo11b2T4UXTOnW0' })
      .then((currentToken) => {
        if (currentToken) {
          //alert(currentToken);
          auth.setPushNotificationUserToken(currentToken);
          //console.log('current token for client: ', currentToken);
          // Perform any other neccessary action with the token
        } else {
          // Show permission request UI
          //alert('No registration token available. Request permission to generate one.');
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        //alert('An error occurred while retrieving token. ', err);
        console.log('An error occurred while retrieving token. ', err);
      });

  };

initializeApp(firebaseConfig);
let messaging = null;
try{
messaging = getMessaging();
}catch(e){
  // alert(e); // only works with https or localhost
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload)
      resolve(payload);
    });
  });