import React, { useState } from "react";
import { useEffect } from "react";
import sc from "./../services/stringconst";
import {Client } from "../services/apiclient";
import util from "../Utilities/UtilityHelper";
import auth from "../services/authservice";
import { useNavigate } from "react-router-dom";
import MoneyModal from "./childcomponents/moneyModal";
import AdvertModal from "./childcomponents/advertModal";


const Home = (props) => {

  const navigate= useNavigate();
  useEffect(()=>{

    if(auth.getIsBorrower()!==null){
      setIsBorrower(auth.getIsBorrower());
    }
    else{
      let user= auth.userIsLender();
      setIsBorrower(!user.isLender);
    }

    //getDashboardData();
    refreshDashboardData(1);
},[]);

const [isBorrower, setIsBorrower]=useState(true);

const [b_borrowed, setB_Borrowed]=useState(0.00);
const [b_repaid, setB_Repaid]=useState(0.00);
const [b_unpaid, setB_Unpaid]=useState(0.00);
const [b_due, setB_Due]=useState(0.00);

const [l_borrowed, setL_Borrowed]=useState(0.00);
const [l_repaid, setL_Repaid]=useState(0.00);
const [l_unpaid, setL_Unpaid]=useState(0.00);
const [l_due, setL_Due]=useState(0.00);

let badgeWarning='badge badge-outline-warning';
let badgePrimary='badge badge-outline-primary';

let iconBoxBgWarning='icon-box badge-outline-warning';
let iconBoxBgPrimary='icon-box badge-outline-primary';

const clearAdvert =()=>{
  setAdvert({showAd:false})
  auth.clearAdvert();
}

const getDashboardData=()=>{
  let client= new Client(null,null);

  client.getdashboard()
  .then(
    data => {
      setDashboardData(data);
       },
       error => {
        props.ShowAlert(error.response,  sc.danger);
       }
);
}

const [popValue, setPopValue]=useState(0.00);
const [popLabel, setPopLabel]=useState('');
const [popCssClass, setPopCssClass]=useState('');
const [popNavigation, setPopNavigation]=useState('');
const [popState, setPopState]=useState();
const [advert, setAdvert]=useState(auth.getAdvert());

const popDashboardAmount=(value, label, cssClass, navigation, state)=>{
  
  setPopValue(value);
  setPopLabel(label);
  setPopCssClass(cssClass);
  setPopNavigation(navigation);
  setPopState(state);
}

const refreshDashboardData=(auto=0)=>{
  let client= new Client(null,null);

  client.refreshdashboard()
  .then(
    data => {
      setDashboardData(data);
      auth.setLocalCurrentUser(data.user);
      if(auto != 1){
        props.ShowAlert('Dashboard Refreshed',  sc.success);
      }
       },
       error => {
        props.ShowAlert(error.response,  sc.danger);
       }
);
}
  
let len=2;

const setDashboardData=(data)=>{

  props.setNotifications(data.message, data.notification);
      setB_Borrowed(data.b_borrowed);
      setB_Repaid(data.b_repaid);
      setB_Unpaid(data.b_unpaid);
      setB_Due(data.b_due);
      
      setL_Borrowed(data.l_borrowed);
      setL_Repaid(data.l_repaid);
      setL_Unpaid(data.l_unpaid);
      setL_Due(data.l_due);
    
}


const SetIsBorrowerStatus=()=>{
  auth.setIsBorrower(!isBorrower);
  setIsBorrower(!isBorrower);
}

  return (
    <>
      

      {/* <!-- App Capsule --> */}
      <div id="appCapsule" className="extra-header-active full-height">
        <div className="section wallet-card-section pt-1">
          <div className="wallet-card">

         <ul className="listview image-listview transparent flush">
                    <li>
                        <div className="item">
                            <div className={isBorrower?iconBoxBgWarning:iconBoxBgPrimary}>
                            {isBorrower && <ion-icon  name="person-outline"></ion-icon>}
                            {!isBorrower && <ion-icon name="business-outline"></ion-icon>}
                            </div>
                            <div className="in">
                                <div>
                                    {isBorrower?'Borrower':`Lender`}
                                </div>
                                <span onClick={SetIsBorrowerStatus} className={isBorrower?badgeWarning:badgePrimary}>switch &nbsp; <ion-icon name="toggle-outline"></ion-icon></span>
                                <span onClick={refreshDashboardData} className={isBorrower?badgeWarning:badgePrimary}><ion-icon name="refresh"></ion-icon></span>
                            </div>
                        </div>
                    </li>
                    </ul>
            {isBorrower && <div className="section">
              <div className="row mt-2">
                <div className="col-6" data-bs-toggle="modal" data-bs-target="#DialogMoney"  onClick={()=>popDashboardAmount(b_borrowed,"Borrowed","text-primary",sc.loansroute, {"state":{'status':[sc.Open, sc.Closed],'isLender':false}})}>
                  <div className="stat-box-smallpadding bg-primary">
                    <div className="title" style={{ color: "#ddd" }}>
                      Borrowed
                    </div>
                    <h4  style={{ color: "white" }}>
                    {util.currencyFormatter(b_borrowed, len)}
                    </h4>
                  </div>
                </div>
                <div className="col-6" data-bs-toggle="modal" data-bs-target="#DialogMoney"  onClick={()=>popDashboardAmount(b_borrowed,"Repaid","text-success",sc.paymentsroute, {"state":{'status':[sc.PaymentConfirmed],'isLender':false}})}>
                  <div className="stat-box-smallpadding bg-success">
                    <div className="title" style={{ color: "#ddd" }}>
                      Repaid
                    </div>
                    <h4  style={{ color: "white" }}>
                    {util.currencyFormatter(b_repaid, len)}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6" data-bs-toggle="modal" data-bs-target="#DialogMoney"  onClick={()=>popDashboardAmount(b_unpaid,"Unpaid","text-warning",sc.repaymentsviewroute, {"state":{'status':sc.Unreceived,'isLender':false}})}>
                  <div className="stat-box-smallpadding bg-warning">
                    <div className="title" style={{ color: "#ddd" }}>
                      Unpaid
                    </div>
                    <h4  style={{ color: "white" }}>
                    {util.currencyFormatter(b_unpaid, len)}
                    </h4>
                  </div>
                </div>
                <div className="col-6" data-bs-toggle="modal" data-bs-target="#DialogMoney"   onClick={()=>popDashboardAmount(b_due,"Due","text-danger",sc.repaymentsviewroute, {"state":{'status':sc.Overdue,'isLender':false}})}>
                  <div className="stat-box-smallpadding bg-danger">
                    <div className="title" style={{ color: "#ddd" }}>
                      Due
                    </div>
                    <h4  style={{ color: "white" }}>
                    {util.currencyFormatter(b_due, len)}
                    </h4>
                  </div>
                </div>
              </div>
            </div>}
{/* 
            const [l_borrowed, setL_Borrowed]=useState(0.00);
const [l_repaid, setL_Repaid]=useState(0.00);
const [l_unpaid, setL_Unpaid]=useState(0.00);
const [l_due, setL_Due]=useState(0.00); */}
            {!isBorrower && <div className="section">
              <div className="row mt-2">
                <div className="col-6" data-bs-toggle="modal" data-bs-target="#DialogMoney"  onClick={()=>popDashboardAmount(l_borrowed,"Lended","text-primary",sc.loansroute, {"state":{'status':[sc.Open, sc.Closed],'isLender':true}})}>
                  <div className="stat-box-smallpadding bg-primary">
                    <div className="title" style={{ color: "#ddd" }}>
                      Lended
                    </div>
                    <h4  style={{ color: "white" }}>
                    {util.currencyFormatter(l_borrowed, len)}
                    </h4>
                  </div>
                </div>
                <div className="col-6" data-bs-toggle="modal" data-bs-target="#DialogMoney"  onClick={()=>popDashboardAmount(l_repaid,"Received","text-success",sc.paymentsroute, {"state":{'status':[sc.PaymentConfirmed],'isLender':true}})}>
                  <div className="stat-box-smallpadding bg-success">
                    <div className="title" style={{ color: "#ddd" }}>
                      Received
                    </div>
                    <h4  style={{ color: "white" }}>
                    {util.currencyFormatter(l_repaid, len)}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6" data-bs-toggle="modal" data-bs-target="#DialogMoney"  onClick={()=>popDashboardAmount(l_unpaid,"Unreceived","text-warning",sc.repaymentsviewroute, {"state":{'status':sc.Unreceived,'isLender':true}})}>
                  <div className="stat-box-smallpadding bg-warning">
                    <div className="title" style={{ color: "#ddd" }}>
                      Unreceived
                    </div>
                    <h4  style={{ color: "white" }}>
                    {util.currencyFormatter(l_unpaid, len)}
                    </h4>
                  </div>
                </div>
                <div className="col-6" data-bs-toggle="modal" data-bs-target="#DialogMoney"   onClick={()=>popDashboardAmount(l_due,"Due","text-danger",sc.repaymentsviewroute, {"state":{'status':sc.Overdue,'isLender':true}})}>
                  <div className="stat-box-smallpadding bg-danger">
                    <div className="title" style={{ color: "#ddd" }}>
                      Due
                    </div>
                    <h4  style={{ color: "white" }}>
                    {util.currencyFormatter(l_due, len)}
                    </h4>
                  </div>
                </div>
              </div>
            </div>}
            
          </div>
        </div>

        {/* Borrower Section */}
        {isBorrower && <div className="section">
          <div className="row mt-2">
            <div className="col-6" onClick={()=>navigate(sc.borrowroute)}>
              <div className="bill-box">
                 <span style={{fontSize:"3em"}} className="text-secondary"><ion-icon name="add-outline"></ion-icon></span>
                <h6>New Loan</h6>
              </div>
            </div>
            <div className="col-6" onClick={()=>navigate(sc.loansroute, {"state":{'isLender':false, 'isActive':true}})}>
              <div className="bill-box">
                 <span style={{fontSize:"3em"}} className="text-primary"><ion-icon name="list-outline"></ion-icon></span>
                <h6>Active Loans</h6>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-6" onClick={()=>navigate(sc.loansroute, {"state":{'isLender':false, 'isPending':true}})}>
              <div className="bill-box">
                 <span style={{fontSize:"3em"}} className="text-warning"><ion-icon name="pause-outline"></ion-icon></span>
                <h6>Pending Loans</h6>
              </div>
            </div>
            <div className="col-6" onClick={()=>navigate(sc.loansroute, {"state":{'isLender':false, 'isRejected':true}})}>
              <div className="bill-box">
                 <span style={{fontSize:"3em"}} className="text-danger"><ion-icon name="close-outline"></ion-icon></span>
                <h6>Rejected Loans</h6>
              </div>
            </div>
          </div>
        </div>}
        {/* Lender Section */}
               {!isBorrower && <div className="section">
          <div className="row mt-2">
            <div className="col-6" onClick={()=>navigate(sc.loansroute, {"state":{'status':[sc.Pending],'isLender':true}})}>
              <div className="bill-box">
                 <span style={{fontSize:"3em"}} className="text-secondary"><ion-icon name="document-outline"></ion-icon></span>
                <h6>Loan Request</h6>
              </div>
            </div>
            <div className="col-6" onClick={()=>navigate(sc.loansroute, {"state":{'status':[sc.Open],'isLender':true}})}>
              <div className="bill-box">
                 <span style={{fontSize:"3em"}} className="text-primary"><ion-icon name="list-outline"></ion-icon></span>
                <h6>Active Loans</h6>
              </div>
            </div>
          </div>
          <div className="row mt-2">
          <div className="col-6"  onClick={()=>navigate(sc.repaymentsviewroute, {"state":{'status':sc.Pending, isLender:true}})}>
              <div className="bill-box">
                 <span style={{fontSize:"3em"}} className="text-warning"><ion-icon name="alert-outline"></ion-icon></span>
                <h6>Pending<br/>Payments</h6>
              </div>
            </div>
            <div className="col-6" onClick={()=>navigate(sc.paymentsroute, {"state":{'status':[sc.PaymentSent],'isLender':true}})}>
              <div className="bill-box">
                 <span style={{fontSize:"3em"}} className="text-warning"><ion-icon name="cash-outline"></ion-icon></span>
                <h6>Unapproved<br/>Payments&nbsp;</h6>
              </div>
            </div>
          </div>
        </div>}
      </div>
      
      <br/>
      <MoneyModal label={popLabel} value={popValue} navigation={popNavigation} state={popState} cssClass={popCssClass} />
      {advert.showAd && <AdvertModal adUrl={advert.adUrl} clearAdvert={clearAdvert} />}
    </>
  );
};
export default Home;
