import React from "react";
import { useNavigate } from "react-router-dom";
import sc from "../services/stringconst";
import auth from "../services/authservice";



const About=()=> {

    const navigate= useNavigate();
    return(
      <>

    {/* <!-- App Header --> */}
    <div  style={{width:auth.appWidth(), marginLeft:auth.appMargin()}} className="appHeader   bg-primary text-light">
        <div className="left">
            <a onClick={()=>navigate(sc.homeroute)} className="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div className="pageTitle">
            About
        </div>
       
    </div>

    {/* <!-- * App Header -->

    <!-- App Capsule --> */}
    <div id="appCapsule"  className="extra-header-active full-height">

        <div className="section text-center">
        <div className="">
                <div className="card-body pt-3 pb-3">
                    <img src="assets/img/logo_transparent.png" alt="image" className="imaged" style={{maxWidth: auth.appLoginLogoWidth()}}/>
                </div>
            </div>
        </div>

        <div className="section mt-3 mb-3">
            <div className="card">
                <div className="card-body">
                    <h2 className="card-title">About us</h2>
                    Apply for loan at flexible interest rates across various loan packages
                </div>
            </div>
        </div>

{/* 
         <div className="section full mt-3">

           
            <div className="carousel-single splide">
                <div className="splide__track">
                    <ul className="splide__list">

                        <li className="splide__slide">
                            <img src="assets/img/sample/photo/lend.jpg" alt="alt" className="imaged w-100"/>
                        </li>

                        <li className="splide__slide">
                            <img src="assets/img/sample/photo/lend.jpg" alt="alt" className="imaged w-100"/>
                        </li>

                        <li className="splide__slide">
                            <img src="assets/img/sample/photo/lend.jpg" alt="alt" className="imaged w-100"/>
                        </li>

                    </ul>
                </div>
            </div>
    

        </div>  */}

        <div className="section mt-3 mb-3">
            <div className="card">
                <div className="card-body">
                    <h2 className="card-title">Have a Question?</h2>
                    <p>Feel free to contact us with any problems. We will get back to you as soon as possible.</p>
                    <a onClick={()=>navigate(sc.contactroute)} className="btn btn-primary">
                        <ion-icon name="mail-open-outline"></ion-icon> Contact
                    </a>
                </div>
            </div>
        </div>

    </div>
    {/* <!-- * App Capsule --> */}

      </>
    )
}
export default About;