import sc from "../services/stringconst";
class util {

  currencyFormatter(num, digits) {
  
    var si = [
      { value: 1, symbol: "" },
      { value: 1E3, symbol: "k" },
      { value: 1E6, symbol: "M" },
      { value: 1E9, symbol: "B" },
      { value: 1E12, symbol: "T" },
      { value: 1E15, symbol: "P" },
      { value: 1E18, symbol: "E" }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return this.commaCurrencyFormatter((num / si[i].value).toFixed(digits).replace(rx, "$1")) + si[i].symbol;
    
  }

  commaCurrencyFormatter(value){
    return new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(value);
  }

  
  getcssClassByStatus(status, includeOutline){

    let base="badge badge-";

    if(includeOutline){
      base="badge badge-outline-"
    }
    if(status===sc.Pending){
      return base+ "warning";
    }
    if(status===sc.Open){
      return base+ "primary";
    }
    if(status===sc.Approved){
      return base+ "info";
    }
    if(status===sc.Disapproved){
      return base+ "danger";
    }
    if(status===sc.Closed){
      return base+ "success";
    }
    if(status===sc.Abandoned){
      return base+ "secondary";
    }
    if(status===sc.Overdue){
      return base+ "danger";
    }

    // view status
    if(status===sc.PaymentSent || status===sc.PartPaymentRaised){
      return base+"warning";
    }

    if(status===sc.PaymentConfirmed){
      return base+"success";
    }
    if(status===sc.PaymentRejected){
      return base+"danger";
    }
    
    return base;
  }

  normalizeAverageRating(rating){
    let newRating= rating;
    if(rating.toString().indexOf(".")>0){
      rating= `${Math.floor(rating).toString()}.5`;
      newRating=parseInt(rating);
     }
     return newRating;
  }

  getAge (date){
    let currentDate= new Date();
    return Math.floor((currentDate.getTime() - new Date(date).getTime()) / 3.15576e+10)
  } 

  getDaysOfPeriod(periodType, periodValue){
    let days= 1;
    if(periodType===sc.Months){
      days=30;
    }
    if(periodType=== sc.Weeks){
      days=7;
    }
    return days * periodValue;
  }

    validateUsername(value){
      const regex = /^[A-Za-z0-9_.]+$/
      return regex.test(value)
  }
  validateString(value){
    const regex = /^[A-Za-z]+$/
    return regex.test(value)
  }
  validateEmail(value){
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
    return regex.test(value)
  }
    validateNumber(value){
      const regex = /^[0-9]+$/
      return regex.test(value)
}
  
}

export default new util();