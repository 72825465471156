import React, { useState } from "react";
import sc from "../../services/stringconst";

const InputTextBox =(props)=> {


  let label=props.label;

  const [input, setInput] = useState(props.value);
  const [inputTemp, setInputTemp] = useState(props.value);

  const setInputTempValue = (e) => {
    setInputTemp(e.target.value);
  };
  const cancelInputEdit = () => {
    setInputEnabled(false);
    setInputTemp(input);
  };

  const [inputEnabled, setInputEnabled] = useState(false);
  const editField = () => {
    if(props.editMessage){
      props.ShowAlert(props.editMessage, sc.warning);
    }
    setInputEnabled(true);
  }

  const setInputEnabledValue = () => {
    if(props.isRequired && (inputTemp==='' || inputTemp===null)){
      props.ShowAlert(label + sc.isRequired,  sc.warning);
      return;
    }

    let newValue = !inputEnabled;
    setInputEnabled(newValue);
    setInput(inputTemp);
    if (newValue === false) {
      props.applyChange(inputTemp);
    }
  };



  return (
    <div className="form-group basic">
      <div className="input-wrapper">
        <label className="label" htmlFor="input">
          {label}
        </label>
        <div className="row">
          <div style={{ width: "80%" }}>
            <input
            maxlength="50"
              style={{ pointerEvents: inputEnabled ? "auto" : "none" }}
              type="text"
              className="form-control"
              id="input"
              value={inputEnabled ? inputTemp : input}
              onChange={setInputTempValue}
            />
          </div>

          <div style={{ width: "10%" }}>
            <span
              style={{
                display: inputEnabled ? "block" : "none",
                fontSize: "1.6em",
              }}
              className="text-primary"
            >
              <ion-icon
                name="save-outline"
                onClick={setInputEnabledValue}
              ></ion-icon>
            </span>
          </div>
          <div style={{ width: "10%" }}>
            <ion-icon
              style={{ display: !inputEnabled ? "block" : "none" }}
              name="pencil-outline"
              onClick={editField}
            ></ion-icon>
            <span
              style={{
                display: inputEnabled ? "block" : "none",
                fontSize: "1.6em",
              }}
              className="text-danger"
            >
              <ion-icon name="close-outline" onClick={cancelInputEdit}></ion-icon>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default InputTextBox;
