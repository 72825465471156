


import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import auth from "../services/authservice";
import Alert from "../services/alert";
import sc from "../services/stringconst";
import { Client } from "../services/apiclient";
import { NativeBiometric, AvailableResult, Credentials, BiometryType } from "capacitor-native-biometric";
import CustomButton from "./childcomponents/custombutton";
import Keypad from "./childcomponents/keypad";
import { Device } from '@capacitor/device';


const Login = (props) => {
  let client = new Client(null, null);
  const navigate = useNavigate();
  const register = () => {
    navigate(sc.registeroptionroute);
  };
  const forgotpassword = () => {
    navigate(sc.forgotpasswordroute);
  };

  const [httpProcessing, setHttpProcessing] = useState(false);
  const [usePin, setUsePin] = useState(auth.getUserHasLoggedInOnThisDevice());

  let deviceId ='';
  let deviceInformation ='';

  const login = async () => {

    if (!logindata.email) {
      messageModal(sc.usernamerequired, sc.warning);
      return;
    }
    if (!logindata.password) {
      messageModal(sc.passwordrequired, sc.warning);
      return;
    }
 
    apilogin({"username": logindata.email,"password": logindata.password,"PushNotificationUserToken": null, "deviceId":null, "deviceInfo":null});

  };

  const [bioIsAvailable, setBioIsAvailable] = useState();

  const apilogin = (signinData) => {
    
    setHttpProcessing(true);
    signinData.PushNotificationUserToken = auth.getPushNotificationUserToken();
    signinData.deviceId = auth.getDeviceId();
    signinData.deviceInfo = auth.getDeviceInfo();

    // alert(JSON.stringify(signinData));

    client.signin(signinData).then(
      (data) => {
        if (signinData.deviceId) {
          auth.setDeviceIsRegistered();
        }
        props.setUser(true);
        auth.setCurrentUser(data);

        if (signinData.username) {
          // Delete user's credentials
          NativeBiometric.deleteCredentials({
            server: "www.loanhub.ng",
          }).then();
          // Save user's credentials
          NativeBiometric.setCredentials({
            username: signinData.username,
            password: signinData.password,
            server: "www.loanhub.ng",
          }).then();
        }


        setHttpProcessing(false);


auth.setUserHasLoggedInOnThisDevice();

        if (auth.getLoanPackageId()) {
          let lpId = auth.getLoanPackageId();
          auth.clearLoanPackageId();
          navigate(sc.loanrequestroute, { state: lpId });
        } else {
          navigate(sc.homeroute);
        }
      },
      (error) => {
        setHttpProcessing(false);
        let msg = error.response;
        if(signinData.pin){
          msg =  msg + ' - ' + 'Do not have PIN? Login with username and set PIN in settings';
        }
        messageModal(msg, sc.danger);
        
      }
    );
  }

  useEffect(async () => {

    // await Device.getId().then(data => {
      
    //   deviceId =data.uuid;
    //   alert(deviceId);
    //   auth.setDeviceId(deviceId);
    // });

    if(!auth.getDeviceId()){
      auth.setDeviceId(sc.getUUId());
    }
    await Device.getInfo().then(deviceInfo => {
      deviceInformation = `${deviceInfo.platform}|${deviceInfo.manufacturer}|${deviceInfo.osVersion}|${deviceInfo.model}`;
      auth.setDeviceInfo(deviceInformation);
    })

    let currentUser = auth.getCurrentUser();
    if (currentUser !== null && currentUser !== undefined) {
      navigate(sc.homeroute);
    }

    startFingerPrint();


  }, []);


  

  const startFingerPrint = async () => {

    const result = await NativeBiometric.isAvailable();

    if (!result.isAvailable) return;

    setBioIsAvailable(true);
    const isFaceID = result.biometryType == BiometryType.FACE_ID;

    const verified = await NativeBiometric.verifyIdentity({
      // reason: "For easy log in",
      // title: "Log in",
      // subtitle: "Maybe add subtitle here?",
      // description: "Maybe a description too?",
    })
      .then(() => true)
      .catch(() => false);

    if (!verified) return;

    await NativeBiometric.getCredentials({
      server: "www.loanhub.ng",
    }).then(data => {
      apilogin({"username":data.username, "password" : data.password,"PushNotificationUserToken": null, "deviceId":null, "deviceInfo":null});
    })
      .catch(error => {
        props.ShowAlert('User not registered on device', sc.warning);
      });


  }

  const [alertMessage, setAlertMessage] = useState({
    show: false,
    message: "",
    type: "",
  });
  const messageModal = (message, type) => {
    setAlertMessage({ show: true, message: message, type: type });
    setTimeout(() => {
      setAlertMessage({ show: false, message: "", type: "" });
    }, sc.toastShow3000);
  };

  const [logindata, setLogindata] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setLogindata((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
  const gotoWelcome = () => {
    navigate(sc.welcomeroute);
  }

  const pinSignIn = async (pin) => {

    if (!auth.getDeviceIsRegistered()) {
      props.ShowAlert('login with username & password to register device', sc.warning);
      return;
    }
    if (pin.length === 6) {

      apilogin({"pin":pin,"PushNotificationUserToken": null, "deviceId":null, "deviceInfo":null});
    }

  }

  return (
    <div className="col-md-12">
      {/* <!-- App Header --> */}
      <div style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }} className="appHeader  bg-primary text-light">
        <div className="left">
          <a onClick={gotoWelcome} className="headerButton goBack">
            <ion-icon name="home-outline"></ion-icon>
          </a>
        </div>
        <div className="pageTitle">Signin</div>
        <div className="right"></div>
      </div>
      {/* <!-- * App Header --> */}


      {/* <!-- App Capsule --> */}
      <div id="appCapsule" className="extra-header-active full-height">
        <div className="section mb-5 p-2">

          {usePin && <> 
          <div style={{ padding:"2%", textAlign: "center" }} className="text-muted">Don't have PIN? login with username, goto settings, tap <span style={{color:"#00009C"}}>Set Login PIN</span></div>
          <div style={{ marginTop: "5%" }}><Keypad submitPin={pinSignIn} /></div></>}

          {!usePin && <>
          <div className="text-center">
            <img src="assets/img/logo_transparent.png" className="img-fluid" style={{maxWidth: auth.appLoginLogoWidth()}}/>
            </div>
           <div className="card" style={{ marginTop: "5%" }}>
            <div className="card-body pb-1">
              <div className="form-group basic">
                <div className="input-wrapper">
                  <label className="label" htmlFor="email">
                    Username | Email | Number
                  </label>
                  <input
                    required
                    type="email"
                    value={logindata.email}
                    onChange={handleChange}
                    className="form-control"
                    id="email"
                    autoComplete="off"
                    placeholder=""
                  />
                  <i className="clear-input">
                    <ion-icon name="close-circle"></ion-icon>
                  </i>
                </div>
              </div>

              <div className="form-group basic">
                <div className="input-wrapper">
                  <label className="label" htmlFor="password">
                    Password
                  </label>
                  <input
                    required
                    value={logindata.password}
                    onChange={handleChange}
                    type="password"
                    className="form-control"
                    id="password"
                    autoComplete="off"
                    placeholder=""
                  />
                  <i className="clear-input">
                    <ion-icon name="close-circle"></ion-icon>
                  </i>
                </div>
              </div>
              <br />
              <div className="form-group  transparent">

                <CustomButton httpProcessing={httpProcessing} text="Sign in" onClick={login} />
              </div>
            
            </div><br/>
          </div>

          </>}
          <br />
          <br />
          <div className="form-links mt-1">
            <div>
              {/* <a onClick={register} className="text-muted">Register</a> */}
            </div>
            {usePin && <div style={{ textAlign: "center"}} className="text-muted text-primary" onClick={() => setUsePin(!usePin)}>
              <b>Username Signin</b>
            </div>}
            {!usePin && <div style={{ textAlign: "center" }} className="text-muted text-primary" onClick={() => setUsePin(!usePin)}>
              <b>PIN Signin</b>
            </div>}
            <div>
              {/* <a onClick={forgotpassword} className="text-muted">
                Forgot Password
              </a> */}
            </div>
          </div>

          <div className="appBottomMenu_login">
          <div>
              <a onClick={register} className="text-muted" style={{textDecoration:"underline"}}>Register</a>
            </div>
            <div className="text-muted">&nbsp; | &nbsp;</div>
            <div>
              <a onClick={forgotpassword} className="text-muted" style={{textDecoration:"underline"}}>
                Forgot Password
              </a>
            </div>
            </div>
        </div>
        {<div style={{ width: "100%", top: "50%" }}>


          {bioIsAvailable && <><a style={{ margin: "0 auto", zIndex: 10001 }} class="text-primary" onClick={startFingerPrint} >
            <ion-icon size="large" name="finger-print-outline"></ion-icon>
          </a>  &nbsp;
          </>}


          
        </div>}
      </div>
      {/* <!-- * App Capsule --> */}

      {alertMessage.show && (
        <Alert message={alertMessage.message} alertType={alertMessage.type} />
      )}
    </div>
  );
};
export default Login;
