import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { Client } from "../services/apiclient";
import auth from "../services/authservice";
import sc from "../services/stringconst";
import LoadingImagePlaceHolder from "./childcomponents/loadingimageplaceholder";

 const UserChat =(props)=> {
    const navigate= useNavigate();

    const client= new Client(null,null);
    let [dataLoaded, setDataLoaded]=useState(false);
    const [userChats, setUserChats]=useState([]);
useEffect(()=>{

    setDataLoaded(false);
    client.getuserchats().then((result) => {
        setDataLoaded(true);
        setUserChats(result);
      },
      error => {
       props.ShowAlert(error.response,  sc.danger);
      });

  },[]);
      
const openchatmessage=(userChat)=>{
    let chatUserId=userChat.senderUserId===auth.getuserProfileId()?userChat.receiverUserId:userChat.senderUserId;
    let name=userChat.senderUserId===auth.getuserProfileId()?userChat.receiverName:userChat.senderName;
    navigate(sc.chatmessageroute, {state:{chatId:userChat.chatId,userId:chatUserId, name:name, photoUrl: userChat.photoUrl}});
}

    return (
        <div id="appCapsule" className="extra-header-active full-height" >
      <div className="col-md-12">

{/* <!-- App Header --> */}
    <div   style={{width:auth.appWidth(), marginLeft:auth.appMargin()}} className="appHeader  bg-primary text-light">
        <div className="left">
            <a className="headerButton goBack" onClick={()=>navigate(-1)}>
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div className="pageTitle">Messages</div>
        <div className="right">
        </div>
    </div>
    {/* <!-- App Header --> */}

    <div>


    <ul className="listview image-listview">

    { userChats.map(item=>(

      <li key={item._id}>
  <div className="item">
      <img src={item.photoUrl?item.photoUrl:auth.getDefaultImageUrl()} alt="image" className="image" />
      <div className="in" onClick={()=>openchatmessage(item)}>
        {auth.getuserProfileId()===item.senderUserId  && <div>{item.receiverName}</div>}
        {auth.getuserProfileId()===item.receiverUserId  && <div>{item.senderName}</div>}
          {(auth.getuserProfileId()===item.receiverUserId && !item.lastMessageRead) && <span className="badge badge-primary">&nbsp;</span>}
      </div>
  </div>
</li>)
)}
          
        </ul>


        {!dataLoaded &&
          <>
           <LoadingImagePlaceHolder />

          </>}
    </div>
    
    </div>
      </div>
    );

}
export default UserChat