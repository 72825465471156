import React from "react";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import sc from "../services/stringconst"
import auth from "../services/authservice";

 const PaymentSubmissionSuccessful =()=> {
     const location=useLocation();
     const navigate= useNavigate();

     let paymentId=location.state;


useEffect(()=>{
    if(!location.state){
        navigate(sc.homeroute);
    }
},[]);

const gotoPayments=()=>{
    navigate(sc.paymentsroute);
}

    return (
<>

<div  style={{width:auth.appWidth(), marginLeft:auth.appMargin()}} className="appHeader  bg-primary text-light">
        <div className="pageTitle">Payment</div>
      </div>
      <div id="appCapsule" className="extra-header-active full-height">
<div className="section mt-2 text-center" style={{padding: "20% 10%"}}>
<div className="card">
            <div className="card-body">
                <h3> Payment Sent</h3> 
                </div>
                <div className="card-body">

                <ion-icon name="checkmark-circle-outline" style={{fontSize:"5em", color: "green"}}></ion-icon><br/>
                <span className="text-muted"># </span> <span style={{fontSize:"1.1em"}}>  {paymentId}</span> 
                </div>
                </div>
<br/>

                <div className="form-group transparent">
                    <button type="submit" onClick={gotoPayments} className="btn btn-primary btn-block">View Payments</button>
                </div>    
                </div>
                </div>
</>
    );

}
export default PaymentSubmissionSuccessful