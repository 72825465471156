import React from "react";


 const SMSVerification =()=> {

    return (
      <div className="col-md-12">


<div className="section mb-5 p-2  text-center">
            <br/>
        <div className="section mt-2">
            <h1>Enter Code</h1>
            <h4>Enter 6 digit verification code sent to your email <b>appi@pipe.com</b></h4>
        </div>
                <div className="form-group basic">
                    <input type="text" className="form-control verification-input" id="smscode" placeholder="••••••"
                        maxLength="6"/>
                </div>

                <div className="form-button-group transparent">
                    <button type="submit" className="btn btn-primary btn-block">Verify</button>
                </div>
                <br/>
<a>didnt get code? <u>Resend</u></a>
        </div>

      </div>
    );

}
export default SMSVerification