import React, { useState, useEffect } from "react";
import { Client } from "../services/apiclient";
import auth from "../services/authservice"
import { useNavigate } from "react-router-dom";
import sc from "../services/stringconst";
import InputTextBox from "./childcomponents/inputtextbox";
import ImageModal from "./childcomponents/imagemodal";
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import ImageKit from "imagekit-javascript";
import util from "../Utilities/UtilityHelper";
import AppVer from "../services/apiclient";
import InputLabel from "./childcomponents/inputlabel";
import moment from "moment";


const Profile = (props) => {
  

  let navigate= useNavigate();
    let client= new Client();

    const imagekit = new ImageKit({
      urlEndpoint: sc.imagekitUrlEndPoint,
      publicKey:sc.imagekitPublicKey,
      authenticationEndpoint: sc.appbaseroute +sc.cloudImageRoute+ auth.getSessionToken()+ '/'+ AppVer.getAppVer()
  }); 

  const [url, setUrl] = useState(auth.getUserProfileImageUrl());
  const [isLender, setIsLender] = useState(auth.userIsLender());

  const [photoChanged, setPhotoChanged] = useState(false);

  
  const [username, setUsername]= useState(auth.getUserProfileFirstname());
  const [verified, setVerified]= useState(auth.getPhotoVerified());
  const [ratingAverage, setRatingAverage]= useState(0);
  const [bvn, setIdentityId]= useState(auth.getUserProfileIdentityId());
  const [bvnVerified, setBvnVerified]= useState(auth.getUserProfileIdentityIdVerified());
  const [firstname, setFirstname]= useState(auth.getUserProfileFirstname());
  const [lastname, setLastname]= useState(auth.getUserProfileLastname());
  const [email, setEmail]= useState(auth.getUserProfileEmail());
  const [displayName, setDisplayName]= useState(auth.getUserProfileDisplayName());
  const [mobileNumber, setMobileNumber]= useState(auth.getUserProfileMobileNumber());
  const [address, setAddress]= useState(auth.getUserProfileAddress());
  const [state, setState]= useState(auth.getUserProfileState());
  const [city, setCity]= useState(auth.getUserProfileCity());
  const [dateOfBirth, setDateOfBirth]= useState(auth.getUserProfileDateOfBirth());
  const [gender, setGender]= useState(auth.getUserProfileGender());

  let [states, setStates]=useState([]);
  let [cities, setCities]=useState([]);

  useEffect(()=>{    
    client.states().then(
      result => {
          setStates(result);
          getCitiesByState(state, city);
     });
     let tempRatingAverage=auth.getRatingAverage();
     tempRatingAverage=util.normalizeAverageRating(tempRatingAverage);
     setRatingAverage(tempRatingAverage);

     if(auth.displayPhotoVerified()===sc.failed){
      props.ShowAlert(auth.displayPhotoVerifiedComment(), sc.danger);
     }

},[photoChanged]);

const takePhoto=async ()=>{
  const photo = await Camera.getPhoto({
    source: CameraSource.Prompt,
    resultType: CameraResultType.Base64,
    quality: 70,
  });
  uploadImage(sc.base64ImagePrefixPng + photo.base64String);
}


const getCitiesByState=(value, selection)=>{
  client.city(value).then(
    result => {
        setCities(result);
        setCity(selection);
   });
}

const uploadWebImage=async (e)=>{

  if(e.target){
    if(e.target.files.length>0){
      if(e.target.files[0].type.indexOf('image')===-1){
        props.ShowAlert(sc.invalidImageFileSelected, sc.danger);
        return;
      }
            
      if(e.target.files[0].size>5000000){ // in bytes equvalent to 5mb
        props.ShowAlert(sc.fileTooLargeError, sc.danger);
        return;
      }
      
      let b64=(await toBase64(e.target.files[0]));
      uploadImage(b64);
    }
  }
}
const getPhotoVerified=()=>{
    
  navigate(sc.photoverificationroute);

}
// const uploadWebImage=(e)=>{
//   if(e.target){
//     if(e.target.files.length>0){
//       if(e.target.files[0].type.indexOf('image')===-1){
//         props.ShowAlert(sc.invalidImageFileSelected, sc.danger);
//         return;
//       }
            
//       if(e.target.files[0].size>5000000){ // in bytes equvalent to 5mb
//         props.ShowAlert(sc.fileTooLargeError, sc.danger);
//         return;
//       }
      
//       uploadImage(e.target.files[0]);
//     }
//   }
// }


const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

  const uploadImage = (photoBase64string) => {

    if(auth.displayPhotoVerified()=== sc.processing){
      props.ShowAlert('You cannot update photo while display photo is being verified', sc.warning);
      return;
    }
    if(auth.getPhotoVerifyStatus()=== sc.processing){
      props.ShowAlert('You cannot update photo while profile is being verified', sc.warning);
      return;
    }

    if(auth.getPhotoVerifyStatus()!== sc.verified){
      setUrl(photoBase64string);
    }
    
    if(auth.displayPhotoVerified()=== sc.failed || auth.displayPhotoVerified()=== sc.verified){
      auth.setDisplayPhotoVerified(sc.processing, "Photo is being processed");
    }

    setPhotoChanged(!photoChanged);
    
      imagekit.upload({
          file: photoBase64string,
          fileName:sc.getUUId(),
          tags: ["profilePhoto"],
          extensions: [
              {
                  name: "aws-auto-tagging",
                  minConfidence: 80,
                  maxTags: 10
              }
          ]
      }, function(err, result) {
        if(result){
          let photoUrl=result.url;
          client.updateprofileimage({"photoUrl":result.url, "photoId":result.fileId}).then(result=>{
            if(auth.getPhotoVerifyStatus()!== sc.verified){
              auth.updateprofileimage(photoUrl);
            }
          })
        }

      });
  };



  const setIdentityIdValue=(value)=>{
    client.updateidentityid({"identityId":value}).then(
    data => {
      setIdentityId(value);
      auth.setIdentityId(value);
      auth.removeBankVerified();
    },
     error => {
      props.ShowAlert(error.response,  sc.danger);
     });
 }
   const setFirstnameValue=(value)=>{
      client.updatefirstname({"firstname":value}).then(
      data => {
        setFirstname(value);
        auth.setFirstname(value);
        auth.removeBankVerified();
      },
       error => {
        props.ShowAlert(error.response,  sc.danger);
       });
   }

   const setLastnameValue=(value)=>{
    client.updatelastname({"lastname":value}).then(
    data => {
      setLastname(value);
      auth.setLastname(value);
      auth.removeBankVerified();
    },
     error => {
      props.ShowAlert(error.response,  sc.danger);
     });
 }

 const setEmailValue=(value)=>{
  client.updateemail({"email":value}).then(
  data => {
    setEmail(value);
    auth.setEmail(value);
  },
   error => {
    props.ShowAlert(error.response,  sc.danger);
   });
}

const setDisplayNameValue=(value)=>{
  client.updatedisplayname({"displayName":value}).then(
  data => {
    setDisplayName(value);
    auth.setDisplayName(value);
  },
   error => {
    props.ShowAlert(error.response,  sc.danger);
   });
}


const setMobileNumberValue=(value)=>{
  client.updatemobilenumber({"mobilenumber":value}).then(
  data => {
    setMobileNumber(value);
    auth.setMobileNumber(value);
  },
   error => {
    props.ShowAlert(error.response,  sc.danger);
   });
}


const setAddressValue=(value)=>{
  client.updateaddress({"address":value}).then(
  data => {
    setAddress(value);
    auth.setAddress(value);
  },
   error => {
    props.ShowAlert(error.response,  sc.danger);
   });
}



const setStateValue=(e)=>{
  let value = e.target.value;
  if(value===state)
    return;

  setState(value);
  setCity('');
  setCities([]);
  auth.setState(value);
  client.updatestate({"state":value}).then(
    data => {
      props.ShowAlert(sc.stateSaved,  sc.success);
      client.city(value).then(
        result => {
            setCities(result);
       });
    },
     error => {
      props.ShowAlert(error.response,  sc.danger);
     });
}

const setCityValue=(e)=>{
  let value=e.target.value;
  setCity(value);
  client.updatecity({"city":value}).then(
    data => {
      props.ShowAlert(sc.citySaved,  sc.success);
      auth.setCity(value);
    },
     error => {
      props.ShowAlert(error.response,  sc.danger);
     });
}

  const back=()=>{
    navigate(-1);
  }

  const openratings=()=>{
    navigate(sc.ratingroute, {state: auth.getuserProfile()});
  }

  return (
    <>
      <div  style={{width:auth.appWidth(), marginLeft:auth.appMargin()}} className="appHeader  bg-primary text-light">
        <div className="left">
          <a onClick={back} className="headerButton goBack">
            <ion-icon name="chevron-back-outline"></ion-icon>
          </a>
        </div>
        <div className="pageTitle">{username}  {auth.getPhotoVerified() && <img width="20" src="https://loanhub.ng/appimages/photoverified.png" />}
        {auth.getBankVerified() && <img width="20" src="https://loanhub.ng/appimages/bankverified.png" />}</div>
      </div>
      <div id="appCapsule" className="extra-header-active full-height">
        <input type="file" hidden onChange={uploadWebImage} id="myInput"></input>
        <div className="section mt-3 text-center">
          <div className="avatar-section">
            <a>
              <img src={url} alt="avatar" className="imaged rounded" style={{width:"8em", height:"8em"}}  data-bs-toggle="modal" data-bs-target="#DialogImage" />

              {auth.displayPhotoVerified()===sc.processing  && 
              <>    <span className="button btn-warning">           <ion-icon
              name="alert-outline"
             onClick={()=>props.ShowAlert('Photo update is being processed', sc.warning)}
            ></ion-icon></span>  
              </>}
              {(auth.displayPhotoVerified()===sc.verified || auth.displayPhotoVerified()==='') && <>
{/*               
              {auth.getPlatformIsNative() &&  <span className="button">
                <ion-icon
                  name="camera-outline"
                 onClick={takePhoto}
                ></ion-icon>
              </span>} */}
              {/* {!auth.getPlatformIsNative() &&  */}
              <span className="button">
                <ion-icon
                  name="camera-outline"
                 onclick="$('#myInput').click();"
                ></ion-icon>
              </span>
              {/* } */}
              </>}
              {(auth.displayPhotoVerified()===sc.failed) && <>
              
              {/* {auth.getPlatformIsNative() &&  
              <span className="button btn-danger">
                <ion-icon
                  name="alert-outline"
                 onClick={takePhoto}
                ></ion-icon>
              </span>} */}
              {/* {!auth.getPlatformIsNative() &&  */}
              <span className="button btn-danger">
                <ion-icon
                  name="alert-outline"
                 onclick="$('#myInput').click();"
                ></ion-icon>
              </span>
              {/* } */}
              </>}
            </a>
          </div>
        </div>
        <div className="card"  style={{"margin":"1em"}}>
          <ul className="listview image-listview transparent flush">
           
          {!auth.getPhotoVerified() && <>
                       {(auth.getPhotoVerifyStatus()=== sc.processing)  && <li>
              <div className="item">
                <div className="icon-box">
                <img width="30" src="https://loanhub.ng/appimages/photoverified.png" />
                </div>
                <div className="in">
                  <div>Photo Verification </div><span className="right badge badge-warning">{sc.processing}</span>
                </div>
              </div>
            </li>}
            {(auth.getPhotoVerifyStatus()=== sc.failed || auth.getPhotoVerifyStatus()=== "")   && <li>
              <div className="item">
                <div className="icon-box">
                <img width="30" src="https://loanhub.ng/appimages/photoverified.png" />
                </div>
                <div className="in">
                  {auth.getPhotoVerifyStatus()!== sc.failed && <span>Verify Profile Photo</span>}
                  {auth.getPhotoVerifyStatus()=== sc.failed && <span>Photo Verification <span className="badge badge-danger">Failed</span></span>}
                  <div className="form-check" onClick={getPhotoVerified}>
                    <span
                      className="right text-primary"
                      style={{ fontSize: "2em" }}
                    >
                      <ion-icon name="open-outline"></ion-icon>
                    </span>
                  </div>
                </div>
              </div>
            </li>}</>}
           
            <li>
              <div className="item">
                <div className="icon-box bg-primary">
                <ion-icon name="star-half-outline"></ion-icon>
                </div>
                <div className="in">
                  <div>Rating <img style={{height:"1.5em"}} src={"https://loanhub.ng/appimages/rating"+ratingAverage+ ".jpg"} /></div>
                  <div className="form-check" onClick={openratings}>
                    <span
                      className="right text-primary"
                      style={{ fontSize: "2em" }}
                    >
                      <ion-icon name="open-outline"></ion-icon>
                    </span>
                  </div>
                </div>
              </div>
            </li>
            
          </ul>
        </div>
        {/* Chat profile owner from here */}
        
        <div className="card"  style={{"margin":"1em"}}>
          <div className="card-body">
           <InputLabel value={gender===sc.M? sc.Male: sc.Female} label={'Gender'} />
           <InputLabel value={moment(dateOfBirth).format("DD.MMM.YYYY")} label={'Date of Birth'} />
           {bvnVerified && <InputLabel value={bvn} label={'Bank Verification Number (BVN)'} /> }
           {!bvnVerified && <InputTextBox applyChange={setIdentityIdValue} ShowAlert={props.ShowAlert} value={bvn} isRequired={true} label={'Bank Verification Number (BVN)'} /> }
            <InputTextBox applyChange={setDisplayNameValue} ShowAlert={props.ShowAlert} value={displayName} isRequired={true} label={'Display Name'} />
            <InputTextBox applyChange={setFirstnameValue} ShowAlert={props.ShowAlert} value={firstname} isRequired={true} label={'Firstname'}  editMessage={auth.getBankVerified()?'Updating firstname will remove your bank verification':''} />
            <InputTextBox applyChange={setLastnameValue} ShowAlert={props.ShowAlert} value={lastname} isRequired={true} label={'Lastname'} editMessage={auth.getBankVerified()?'Updating lastname will remove your bank verification':''} />
            <InputTextBox applyChange={setEmailValue} ShowAlert={props.ShowAlert} value={email} isRequired={true} label={'Email'} />
            <InputTextBox applyChange={setMobileNumberValue} ShowAlert={props.ShowAlert} value={mobileNumber} isRequired={true} label={'Mobile Number'} />
            

          </div>
        </div>

        <div className="card"  style={{"margin":"1em"}}>
          <div className="card-body">
            
          <InputTextBox applyChange={setAddressValue} ShowAlert={props.ShowAlert} value={address} isRequired={true} label={'Address'} />

          <div className="form-group basic">
                            <div className="input-wrapper">
                                <label className="label" htmlFor="mobilenumber">State</label>
                                <div style={{ width: "80%" }}>
                                <select value={state} className="form-control custom-select" id="state" onChange={setStateValue}>
                                    <option key="-1">Select State</option>
                                { states.map(state=>(
                                    <option key={state.title} value={state.title} >{state.title}</option>
                                ))}
                                </select>
                                </div>
                               
                            </div>
                        </div>
                        <div className="form-group basic">
                            <div className="input-wrapper">
                                <label className="label" htmlFor="mobilenumber">City</label>
                                <div style={{ width: "80%" }}>
                                <select onChange={setCityValue} value={city} aria-label="labessl for the select" className="form-control custom-select" id="city">
                                    <option key="-1">Select City</option>
                                    { cities.map(city=>(
                                    <option value={city.title} key={city.title}>{city.title}</option>
                                ))}
                                </select>
                                </div>
                            </div>
                        </div>
           
          </div>
        </div>
      </div>

      <ImageModal url={url} />

    </>
  );
};
export default Profile;
