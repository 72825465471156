import React, { useState } from "react";
import { Client } from "../../services/apiclient.js";
import { useNavigate } from "react-router-dom";
import sc from "../../services/stringconst";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import auth from "../../services/authservice.js";

const Account = (props) => {
const location= useLocation();

let account={};
account=props.account;
let isVerified= false;
isVerified=account.isVerified;
  const navigate= useNavigate();
let client= new Client(null, null);


const [isVisible, setIsVisible]=useState(account.isVisible);

useEffect(()=>{

},[]);


const setVisibility = () => {
       
  if(account.isVisible){
    client.hideuseraccount(account._id).then(data=>{
    });
  }
  else{
    client.showuseraccount(account._id).then(data=>{
    });
  }

  setIsVisible(!isVisible);
};


const verifyUserAccount=()=>{
  navigate(sc.identificationverificationroute, {'state':{accountNumber:account.accountNumber}});
}
const removeUserAccount=()=>{
  props.removeAccountModal(account);
}


  return (
    <>
      
      <div id="appCapsule" className="extra-header-active full-height" >
      <div className="card">
        <div className="card-body">
          <ul className="listview flush transparent image-listview">
           
           
            <li>
              <div className="item">
              
                <div className="in">
                  <div>
                    {" "}
                    <span className="text-muted">
                      Name &nbsp;&nbsp;&nbsp;&nbsp;
                    </span>{" "}
                    {auth.getUserProfileFirstname()} {auth.getUserProfileLastname()}
                  </div>
                </div>
              </div>
            </li>
                
            <li>
              <div className="item">
             
                <div className="in">
                  <div>
                    {" "}
                    <span className="text-muted">
                      Number &nbsp;
                    </span>{" "}
                    {account.accountNumber}
                  </div>
                </div>
              </div>
            </li>

            {account.userId===auth.getuserProfileId() && (
            <li>
            <div className="item">
            
              <div className="in">
                <div style={{width:"100%"}}>
                  {" "}
                  <span className="text-muted">
                  Visible Lenders/Borrowers
                  </span>{" "}
                  <div className="form-check form-switch" style={{float:"right"}}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                     onChange={setVisibility}
                    id={account._id}
                     checked={isVisible}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={account._id}
                  ></label>
                </div>
                </div>
              </div>
            </div>
          </li>
            )}
          </ul>
        </div>
        {account.userId===auth.getuserProfileId() && (
        <div className="card-footer">

{!isVerified && <>
            <a
              style={{width:"55%"}}
              className="btn btn-primary" onClick={verifyUserAccount}
            >
              Match BVN
            </a>
            <a
              style={{width:"35%", marginLeft:"5%"}}
              className="btn btn-danger" onClick={removeUserAccount}
            >
              Remove
            </a></>
}

       
        </div> )}
      </div>
      </div>
   
    </>
  );
};
export default Account;
