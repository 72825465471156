import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import sc from "../services/stringconst";
import { Client } from "../services/apiclient";
import auth from "../services/authservice";



const RegisterOption = (props) => {

    const navigate = useNavigate();
    const client = new Client();

    useEffect(() => {
        let currentUser = auth.getCurrentUser();
        if (currentUser !== null && currentUser !== undefined) {
            navigate(sc.homeroute);
        }
    }, []);

    const goToLogin = () => {
        navigate(sc.loginroute);
      };
    
    const gotoRegister = (isLender) => {
        navigate(sc.registerroute, {state : {isLender : isLender }});
      };
    return (
        <>
            <div>
                <div id="appCapsule" className="extra-header-active full-height">
                    <div className="section mb-5 p-2">
                        <div className="col-md-12">

                            <div id="page1">
                                <div style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }} className="appHeader  bg-primary text-light">
                                    <div className="left">
                                        <a onClick={goToLogin} className="headerButton goBack">
                                            <ion-icon name="chevron-back-outline"></ion-icon> Login
                                        </a>
                                    </div>
                                    <div className="pageTitle">Register</div>
                                    <div className="right"></div>
                                </div>

                                <div>
                                <div className="col-md-12 text-center content_registeroption" style={{padding: auth.appWelcomeButtonPadding()}}>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <button onClick={() => gotoRegister(false)} type="button" className="btn btn-outline-warning me-1 mb-1 btn-block" style={{ padding: auth.appWelcomeButtonPadding(), maxWidth: auth.appWelcomeButtonWidth(), background: 'white' }}>I want to borrow money</button>
                                    <br /><br />

                                    <button type="button" onClick={() => gotoRegister(true)} className="btn btn-outline-primary me-1 mb-1 btn-block" style={{ padding: auth.appWelcomeButtonPadding(), maxWidth: auth.appWelcomeButtonWidth(), background: 'white' }}>
                                        I want to lend money to borrowers and earn interest </button>

                                        <br/>
                                        <br/><span className="text-muted text-primary">You can disable lender profile in settings if you choose to no longer lend.</span><br/><span className="text-muted text-primary">Also, as a lender you can borrow from other lenders</span>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default RegisterOption;