import React from "react";
import LoanDetails from "./loandetails";
import auth from "../services/authservice";

 const LoanApproval =()=> {

    return (
      <>
      
      <div  style={{width:auth.appWidth(), marginLeft:auth.appMargin()}} className="appHeader">
        <div className="left">
            <a className="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon><span className="text-muted"> Dashboard</span>
            </a>
        </div>
        <div className="pageTitle">Loan Request</div>
        <div className="right">
        </div>
    </div>
    <br/>
    <br/>
    
     <LoanDetails/>
     <div className="card-body">
            <a href="#" style={{width:"45%"}} className="btn btn-danger" data-bs-toggle="modal" data-bs-target="#declineModal">Decline</a>
            <a href="#" style={{width:"45%"}} className="btn btn-success marginLeft5px" data-bs-toggle="modal" data-bs-target="#confirmModal">Approve</a>
      
        </div>

        <div className="modal fade dialogbox" style={{width:"100%"}} id="confirmModal" data-bs-backdrop="static" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
            <div className="splash-page mt-5 mb-5">

              
                <div className="transfer-verification">
                <div className="form-group basic">
                            <div className="input-wrapper">
                                <h4 htmlFor="note">Select disbursment date</h4>
                                <input type="date" className="form-control" id="disbursmentDate"/>
                                <i className="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>
                        </div>
            </div>
                  
                    <div className="modal-footer">
                        <div className="btn-inline">
                            <a href="#" className="btn btn-text-secondary" data-bs-dismiss="modal">Cancel</a>
                            <a href="#" className="btn btn-text-primary" data-bs-dismiss="modal">Confirm</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        <div className="modal fade dialogbox" style={{width:"100%"}} id="declineModal" data-bs-backdrop="static" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
            <div className="splash-page mt-5 mb-5">

            <h4 className="text-center">Proceed to decline request?</h4>
            </div>
                  
                    <div className="modal-footer">
                        <div className="btn-inline">
                            <a href="#" className="btn btn-text-secondary" data-bs-dismiss="modal">Cancel</a>
                            <a href="#" className="btn btn-text-primary" data-bs-dismiss="modal">Reject</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     </>
    );

}
export default LoanApproval