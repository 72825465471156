import React, { useState } from "react";
import auth from "../../services/authservice";



const ReportModal =(props)=>{

  const [comment, setComment]= useState();

  const setCommentValue=(e)=>{
    setComment(e.target.value);
  }


return(
  <div>

<div className="card" style={{width:auth.appWidth(), position:"fixed",top:"25%", textAlign:"center"}}>
<div className="modal-content" >
    <div className="modal-header" style={{width:auth.appWidth(), marginLeft:auth.appMargin()}}>
                          <h4 style={{width:"100%"}} className="modal-title text-primary"><b>Report</b></h4>
                      </div>
                      <div className="modal-body">
                    
                      <div className="form-group basic">
                        <div className="input-wrapper">
                        <br/>
                          <textarea cols="5" rows="3"
                            type="text"
                            value={comment}
                            onChange={setCommentValue}
                            className="form-control"
                            id="comment"
                            placeholder="type comment here..."
                          />
                        </div>
                      </div>
                      <div className="btn-list">
                        <br/>
                         <a onClick={()=>props.submitReport(comment)} className="btn btn-primary">Submit</a>
                         <a onClick={props.cancelShowReportModal} className="btn btn-text-primary" >Cancel</a>
                          </div>
                      </div>
                    
      </div>
      
      </div>
</div>
)
};

export default ReportModal;

