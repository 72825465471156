import React from 'react';
import sc from '../../services/stringconst';
import LoadingViewPlaceHolder from './loadingviewplaceholder';


function LoadingImagePlaceHolder() {


    return (
        <div>
           {sc.loaderViewCountFour.map(o=><> 
          <table style={{width:"100%"}}>
            <tr>
              <td><div style={{ width: "50px",
    height: "50px",
    overflow: "hidden",
    borderRadius: "50%",
    marginRight: "1rem"}} >   <LoadingViewPlaceHolder extraStyles={{height: '100%', width: '120px', borderRadius: '10px',
                         margin: '4px 0'}} /></div></td>

                         <td>
          <LoadingViewPlaceHolder extraStyles={{height: '15px', width: '100px', borderRadius: '10px',
                         margin: '4px 0'}} />
                         </td>
                         <td>
                           
                         <LoadingViewPlaceHolder extraStyles={{height: '15px', marginBottom: '16px', borderRadius: '10px'}} />
                         <LoadingViewPlaceHolder extraStyles={{height: '15px', width: '100px', borderRadius: '10px',
                         margin: '4px 0'}} />
                         </td>
            </tr>
          </table><br/>
                
               
                      </>)}
        </div>
    );
}

export default LoadingImagePlaceHolder;