import React from "react";
import { useState } from "react";



const Keypad=(props)=> {

    
  const [pin, setValue]=useState('');
  
    const addToPin=(val)=>{
      if(pin.length===6){
        return;
      }
      let newValue= `${pin}${val}`;
      setValue(newValue);
    }
  
    const removeFromPin=()=>{
      if(pin.length===0){
        return;
      }
      let newValue= pin.substring(0, pin.length-1);
      setValue(newValue);
    }
  
    return(
        <>
  
<div style={{textAlign:"center", width:"100%"}}>

<div>
  <input id="pin1" style={{width:9, height:9, border:0, background:pin.length>=1?"#00009C":"#ababff"}} readOnly /> &nbsp; 
  <input id="pin2" style={{width:9, height:9, border:0, background:pin.length>=2?"#00009C":"#ababff"}} readOnly /> &nbsp; 
  <input id="pin3" style={{width:9, height:9, border:0, background:pin.length>=3?"#00009C":"#ababff"}} readOnly /> &nbsp; 
  <input id="pin4" style={{width:9, height:9, border:0, background:pin.length>=4?"#00009C":"#ababff"}} readOnly/> &nbsp; 
  <input id="pin5" style={{width:9, height:9, border:0, background:pin.length>=5?"#00009C":"#ababff"}} readOnly /> &nbsp; 
  <input id="pin6" style={{width:9, height:9, border:0, background:pin.length>=6?"#00009C":"#ababff"}} readOnly />&nbsp;   &nbsp; 
</div>
<br/>
<div>
  <span className="badge badge-outline-primary" onClick={()=>addToPin('1')}  style={{width:60, height:60, fontSize:20}} >1</span> &nbsp;  &nbsp;  &nbsp; 
  <span className="badge badge-outline-primary" onClick={()=>addToPin('2')}  style={{width:60, height:60, fontSize:20}} >2</span> &nbsp;  &nbsp;  &nbsp; 
  <span className="badge badge-outline-primary" onClick={()=>addToPin('3')}  style={{width:60, height:60, fontSize:20}} >3</span> &nbsp;  &nbsp;
</div>
<br/>
<div>
  <span className="badge badge-outline-primary" onClick={()=>addToPin('4')}  style={{width:60, height:60, fontSize:20}} >4</span> &nbsp;  &nbsp;  &nbsp; 
  <span className="badge badge-outline-primary" onClick={()=>addToPin('5')}  style={{width:60, height:60, fontSize:20}} >5</span> &nbsp;  &nbsp;  &nbsp; 
  <span className="badge badge-outline-primary" onClick={()=>addToPin('6')}  style={{width:60, height:60, fontSize:20}} >6</span> &nbsp;  &nbsp;
</div>
<br/>
<div>
  <span className="badge badge-outline-primary" onClick={()=>addToPin('7')}  style={{width:60, height:60, fontSize:20}} >7</span> &nbsp;  &nbsp;  &nbsp; 
  <span className="badge badge-outline-primary" onClick={()=>addToPin('8')}  style={{width:60, height:60, fontSize:20}} >8</span> &nbsp;  &nbsp;  &nbsp; 
  <span className="badge badge-outline-primary" onClick={()=>addToPin('9')}  style={{width:60, height:60, fontSize:20}} >9</span> &nbsp;  &nbsp;
</div>
<br/>
<div>
<span  className={pin.length>0?"badge badge-warning":"badge badge-warning-light"}  onClick={removeFromPin} style={{width:50, height:50, fontSize:20}} ><ion-icon name="backspace-outline"></ion-icon></span> &nbsp;  &nbsp;  &nbsp; 
  <span className="badge badge-outline-primary"  onClick={()=>addToPin('0')}  style={{width:60, height:60, fontSize:20}} >0</span> &nbsp;  &nbsp; &nbsp; 
  <span className={pin.length===6?"badge badge-primary":"badge badge-primary-light"} onClick={()=>props.submitPin(pin)}  style={{width:50, height:50, fontSize:20}} ><ion-icon name="log-in-outline"></ion-icon></span> &nbsp;  &nbsp;
</div>


</div>
</>
)
}
export default Keypad;