import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Client } from "../services/apiclient";
import moment from "moment";
import LoadingImagePlaceHolder from "./childcomponents/loadingimageplaceholder";
import sc from "../services/stringconst";
import auth from "../services/authservice";

 const Notification =()=> {
    let client= new Client(null, null);

    let navigate= new useNavigate();
    let [dataLoaded, setDataLoaded]=useState(false);

    const [notificationList, setNotificationList]=useState([]);
const [allNotificationShown, setAllNotificationShown]=useState(false);

    useEffect(()=>{

        init();
    },[]);

  const init=()=>{
      setDataLoaded(false);
    client.notification(notificationList.length).then(data=>{
        setDataLoaded(true);
        if(data.length<20){
            setAllNotificationShown(true);
        }
        let tempNotificationList= [...notificationList];
        for(let i=0; i<data.length; i++){
            tempNotificationList.push(data[i]);
        }
        setNotificationList(tempNotificationList);
      });

  }
    return (
<div  className="bg-white">

    
    <div id="appCapsule">

        {/* <!-- App Header --> */}
    <div   style={{width:auth.appWidth(), marginLeft:auth.appMargin()}} className="appHeader  bg-primary text-light">
    <div className="left">
            <a onClick={() => navigate(-1)} className="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div className="pageTitle">
            Notifications
        </div>
     
    </div>
    {/* <!-- App Header --> */}

        <div className="section full">
        {!dataLoaded &&
          <>
           <LoadingImagePlaceHolder />

          </>}

        <ul className="listview image-listview flush">
                
            {notificationList.map(item=><li>
                    <a className="item" >
                        <div className="icon-box badge-outline-primary">
                      {item.refType=== sc.LoanEntity &&  <ion-icon name="cash-outline"></ion-icon>}
                      {item.refType=== sc.RatingEntity &&  <ion-icon name="star-half-outline"></ion-icon>}
                      {item.refType=== sc.UserEntity &&  <ion-icon name="person-outline"></ion-icon>}
                        </div>
                        <div className="in">
                            <div>
                                <div className="mb-12"><strong>{item.title}</strong> <span className="text-warning" >{!item.seen && <ion-icon name="star-outline"></ion-icon>}</span></div>
                                <div className="text-small mb-12">{item.message}</div>
                                <div className="text-xsmall">{moment(item.audit.createdOn).format("DD.MMM.YY hh:mm:ss")}</div>
                            </div>
                        </div>
                    </a>
                </li>)}
            </ul>


                </div>
<br/>
           {!allNotificationShown &&     <div className="section mb-2">
            <a onClick={init} className="btn btn-block btn-secondary">
              Load More
            </a>
          </div>    } 
    </div>
</div>
    );

}
export default Notification