import React, { useState, useEffect } from "react";
import sc from "./../services/stringconst"
import { useNavigate } from "react-router-dom";
import { Share } from '@capacitor/share';
import { Capacitor } from '@capacitor/core';
import auth from "./../services/authservice.js";
import { requestFirebaseForToken, onMessageListener } from './../firebase.js';

const Header = (data) => {

    var navigate= new useNavigate();


  const [notification, setNotification] = useState({title: '', body: ''});

    useEffect(()=>{

      if (notification?.title){
        notify();
       }

        const user = auth.getCurrentUser();
        if (user) {
            setCurrentUser(user);
            setIsAdmin(user.role==="Admin");
            setIsLender(user.isLender===true);
            setIsLoggedIn(true);
        }
        else{
            setIsAdmin(false);
            setIsLender(false);
            setIsLoggedIn(false);
        }
        let currentUser= auth.getCurrentUser();
        if(currentUser===null || currentUser===undefined){
            navigate(sc.welcomeroute);
            return;
        }
        

        
        let devicePlatform=Capacitor.getPlatform();
        if(devicePlatform){
            setPlatform(devicePlatform);
        }
    },[notification]);


    const [openNotification, setOpenNotification]=useState(false);
    const closeNotification=()=>{
      setOpenNotification(false);
    }
    
    
    requestFirebaseForToken();

    onMessageListener()
    .then((payload) => {
      setNotification({title: payload?.notification?.title, body: payload?.notification?.body});     
    })
    .catch((err) => console.log('failed: ', err));

    const notify = () => {
      SetNotificationTitle(notification?.title);
      SetNotificationDescription(notification?.body);
      setOpenNotification(true);
    };

    const [NotificationTitle, SetNotificationTitle] = useState();
    const [NotificationDescription, SetNotificationDescription] = useState();

    const [isLender, setIsLender]=useState(false);
    const [isAdmin, setIsAdmin]=useState(false);
    const [isLoggedIn, setIsLoggedIn]=useState(false);
    const [currentUser, setCurrentUser]=useState(undefined);
    const [platform, setPlatform]=useState("web");

    const gotoNotification=()=>{
        navigate(sc.notificationroute);
    }
    const gotoMessage=()=>{
        navigate(sc.userchatroute);
    }
      const logout=()=>{
        
        auth.logout();
        setIsLoggedIn(false);
        
      }


      const shareApp= async ()=>{
        await Share.share({
            title: sc.shareMessage,
            text: sc.shareMessage,
            url: getStoreUrl(),
            dialogTitle: sc.shareMessage,
          });
      }
      
const getStoreUrl=()=>{
    // if(platform===sc.web){
    //     return sc.WebUrl;
    // }
    // if(platform===sc.android){
    //     return sc.PlayStoreUrl;
    // }
    // if(platform===sc.ios){
    //     return sc.AppStoreUrl;
    // }
    return sc.WebUrl;
}


    return (
        
        <div>

        {data.loginStatus && (<div style={{width:auth.appWidth(), marginLeft:auth.appMargin()}} className="appHeader bg-primary text-light">
            <div className="pageTitle left"  onClick={()=> navigate(sc.profileroute)}>
                <img src={auth.getUserProfileImageUrl()?auth.getUserProfileImageUrl():auth.getDefaultImageUrl()} alt="logo" className="logo imaged rounded"/>
                <div className="pageTitle">{auth.getUserProfileFirstname()} {auth.getPhotoVerified() && <img width="20" src="https://loanhub.ng/appimages/photoverified.png" />} {auth.getBankVerified() && <img width="20" src="https://loanhub.ng/appimages/bankverified.png" />}</div>
            </div>
            <div className="right">
            <a onClick={gotoMessage} className="headerButton">
                    <ion-icon name="mail-outline"></ion-icon>
                    {data.messageCount>0 && <span className="badge badge-danger">{data.messageCount}</span>}
                </a>
                <a onClick={gotoNotification} className="headerButton">
                    <ion-icon className="icon" name="notifications-outline"></ion-icon>
                    {data.notificationCount>0 && <span className="badge badge-danger">{data.notificationCount}</span>}
                </a>
                <a data-bs-toggle="modal" data-bs-target="#actionSheetIconed" className="headerButton item">
                {/* <a id="profileIcon" onClick={showActionSheet}> */}
                <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                </a>
            </div>
        </div>)}

        {/*<!-- * App Header --> */}
    
        {/* <!-- Iconed Action Sheet --> */}
      <div className="modal fade action-sheet" id="actionSheetIconed" tabIndex="-1" role="dialog"  >
            <div className="modal-dialog" role="document">
                <div className="modal-content"  style={{width:auth.appWidth(), marginLeft:auth.appMargin()}}>
                    <div className="modal-body">

                    <div className="row mt-2" style={{padding:"10px"}}>
            <div className="col-6" data-bs-dismiss="modal"  onClick={()=>navigate(sc.profileroute)}>
              <div className="bill-box">
                 <span  style={{fontSize:"1.5em", color:'#00009C'}}><ion-icon name="person-outline"></ion-icon></span>
                 <div style={{color:'#00009C', fontSize:'0.8em'}}>
                                    Profile
                                </div>
              </div>
            </div>
            <div className="col-6" data-bs-dismiss="modal"  onClick={()=>navigate(sc.settingsroute)}>
              <div className="bill-box">
                 <span  style={{fontSize:"1.5em", color:'#00009C'}}><ion-icon name="settings-outline"></ion-icon></span>
                 <div style={{color:'#00009C', fontSize:'0.8em'}}>
                                    Settings
                                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2" style={{padding:"10px"}}>
            <div className="col-6" data-bs-dismiss="modal"  onClick={()=>navigate(sc.faqroute)}>
              <div className="bill-box">
                 <span  style={{fontSize:"1.5em", color:'#00009C'}}><ion-icon name="help-outline"></ion-icon></span>
                 <div style={{color:'#00009C', fontSize:'0.8em'}}>
                                    FAQ
                                </div>
              </div>
            </div>
            <div className="col-6" data-bs-dismiss="modal"  onClick={()=>navigate(sc.aboutroute)}>
              <div className="bill-box">
                 <span  style={{fontSize:"1.5em", color:'#00009C'}}><ion-icon name="information-outline"></ion-icon></span>
                 <div style={{color:'#00009C', fontSize:'0.8em'}}>
                                    About
                                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2" data-bs-dismiss="modal" style={{padding:"10px"}}>
            <div className="col-6"  onClick={shareApp}>
              <div className="bill-box">
                 <span  style={{fontSize:"1.5em", color:'#00009C'}}><ion-icon name="share-social-outline"></ion-icon></span>
                 <div style={{color:'#00009C', fontSize:'0.8em'}}>
                                    Share
                                </div>
              </div>
            </div>

            <a href={sc.welcomeroute} className="item col-6" onClick={logout} >
              <div className="bill-box">
                 <span style={{fontSize:"1.5em", color:'#00009C'}} ><ion-icon name="log-out-outline"></ion-icon></span>
                                <div style={{color:'#00009C', fontSize:'0.8em'}}>
                                    Log out
                                </div>
              </div>
            </a>
          </div>
                       
                      
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- * Iconed Action Sheet --> */}

         {/*<!-- Modal for notification --> */}


{openNotification && <div className="pwa-add-button-container-ios-parent">
  <div className="modal" style={{background: "#FFF", padding: "1em", margin:"1em", width:"92.7%", borderRadius: "5px", maxHeight:"28%",
  display: "block"}}>
   <div className="modal-header">
    <h4 className="modal-title">{NotificationTitle}</h4>
   <a className="close-pwa-add-button-container" onClick={closeNotification} >
      <ion-icon name="close"></ion-icon>
    </a>
  </div>
    <div className="modal-body">
    {NotificationDescription}
    </div>
  </div>
</div>}
      
      {/* <!-- * Modal for notification --> */}

        </div>
        )
    
  
}



export default Header;