import React from "react";
import CustomButton from "./custombutton";
import auth from "../../services/authservice";

const ConfirmModal =(props)=>{

  let title=props.title;
  let message= props.message;
  let buttons= props.buttons;
  let caveat= props.caveat;

return(
  <div>


<div className="card" style={{width:auth.appWidth(), position:"fixed",top:"25%", textAlign:"center"}}>
<div className="modal-content" >
    <div className="modal-header" style={{width:auth.appWidth(), marginLeft:auth.appMargin()}}>
                          <h5 className="modal-title text-primary"><b>{title}</b></h5>
                      </div>
                      <div className="modal-body">
                         <span className="text-muted">{message}</span> <br/>
                         <span className="text-muted"><span style={{color:"red"}}>{caveat}</span></span>
                      </div>
                      <div style={{padding:"1em"}}>
                          <div className="btn-list">
                         
                            {buttons[0] && <CustomButton httpProcessing={props.httpProcessing} text={buttons[0]} onClick={props.action1} />}
                            {buttons[1] &&  <a onClick={props.action2} className="btn btn-text-primary btn-block" style={{padding:"2em"}}>{buttons[1]}</a>}
                          </div>
                      </div>
      </div>
      
      </div>
</div>
)
};

export default ConfirmModal;

