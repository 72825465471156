import React, { useState } from "react";
import { Client } from "../services/apiclient.js";
import { useNavigate } from "react-router-dom";
import sc from "../services/stringconst";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import util from "../Utilities/UtilityHelper";
import auth from "../services/authservice.js";
import Account from "./childcomponents/account.js";
import ConfirmModal from "./childcomponents/confirmmodal";
import LoadingTextPlaceHolder from "./childcomponents/loadingtextplaceholder";

const UserAccount = (props) => {
const location= useLocation();

  const navigate= useNavigate();
let client= new Client(null, null);

let [dataLoaded, setDataLoaded]=useState();
const [userAccounts, setUserAccounts]=useState([]);
const [accountForDelete, setAccountForDelete]= useState();
const [httpProcessing, setHttpProcessing]= useState();

const [showConfirmModal, setShowConfirmModal] = useState(false);
const [confirmButtons, setConfirmButtons]= useState(["Remove","Cancel"]);

let userId=undefined;
let isLoggedInUser=false;
if(location.state){
  userId=location.state;
  isLoggedInUser=false;
}
else{
  userId=auth.getuserProfileId();
  isLoggedInUser=true;
}


const removeAccount=()=>{

  setHttpProcessing(true);
  client.deleteuseraccount(accountForDelete._id,accountForDelete).then(
    (data)=>{
      setHttpProcessing(false);
      userAccounts.splice(userAccounts.indexOf(accountForDelete),1);
      setShowConfirmModal(false);
      setAccountForDelete();
    },
    (error)=>{
      setHttpProcessing(false);
      props.ShowAlert(error.response, sc.danger);
    }
  )
  setShowConfirmModal(false);
}

const cancelAction=()=>{

  setShowConfirmModal(false);
  setAccountForDelete();
}

  
const removeAccountModal=(account)=>{
  setAccountForDelete(account);
  setShowConfirmModal(true);
}

useEffect(()=>{
  
client.getuseraccounts(userId).then((data)=>{
  setDataLoaded(true);
  setUserAccounts(data);
},
(error)=>{
  setDataLoaded(true);
  props.ShowAlert(error.response, sc.danger);
});

},[]);

const addUserAccount = ()=>{
  navigate(sc.newuseraccountroute);
}

const removeUserAccount=()=>{
  setShowConfirmModal(true);
}


  const back=()=>{
    if(isLoggedInUser){
      navigate(sc.settingsroute);
    }
    else{
      navigate(sc.profileviewroute, {"state":userId});
    }

  }


  return (
    <>
    <div  style={{width:auth.appWidth(), marginLeft:auth.appMargin()}} className="appHeader  bg-primary text-light">
      <div className="left">
        <a onClick={back} className="headerButton goBack">
          <ion-icon name="chevron-back-outline"></ion-icon>
        </a>
      </div>
      <div className="pageTitle">Bank Accounts</div>
      {isLoggedInUser && <div className="right">
                  <a onClick={addUserAccount} className="headerButton">
                      <ion-icon name="add-circle"></ion-icon>
                  </a>
              </div>}
    </div>
    <div id="appCapsule" className="extra-header-active full-height" >


<div className="section full">


      {userAccounts.map(value=>(   
      <>  {((isLoggedInUser && !value.isVisible) || value.isVisible) &&
      <div key={value.lpId} className="accordion" id="banks">
              <div className="accordion-item">
                  <h2 className="accordion-header"   style={{background:"#f9f9f9"}}>
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target={"#bank"+value._id}>
                        <b>  {value.bankName} &nbsp; {value.isVerified && <img width="20" src="https://loanhub.ng/appimages/bankverified.png" />}</b>
                      </button>
                  </h2>
                  <div id={"bank"+value._id} className="accordion-collapse collapse" data-bs-parent="#banks">
                      <div className="accordion-body">
                        <Account httpProcessing={httpProcessing} account={value} removeAccountModal={removeAccountModal} />
          
                      </div>
                  </div>
              </div>

          </div>
        }</>
      ))}
          
          {!dataLoaded &&
          <>
           <LoadingTextPlaceHolder />

          </>}
      </div>

      </div>
      {showConfirmModal && (
        <ConfirmModal
          title='Confirmation'
          message='Proceed to remove account?'
          caveat=''
          buttons={confirmButtons}
          action1={removeAccount}
          action2={cancelAction}
          httpProcessing={httpProcessing}
        />
      )}

    </>
  );
};
export default UserAccount;
