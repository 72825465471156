import React, { useEffect, useState } from "react";
import auth from "../services/authservice";
import { useNavigate } from "react-router-dom";
import { Client } from "../services/apiclient";
import sc from "../services/stringconst";
import CustomButton from "./childcomponents/custombutton";
import InputLabel from "./childcomponents/inputlabel";
import util from "../Utilities/UtilityHelper";
import { useLocation } from "react-router-dom";

import PaystackPop from '@paystack/inline-js';

const IdentificationVerification = (props) => {

  const client = new Client();
  const navigate = useNavigate();
const location= useLocation();

  const [bvn, setIdentityId] = useState(auth.getUserProfileIdentityId());
  const [firstname, setFirstname] = useState(auth.getUserProfileFirstname());
  const [lastname, setLastname] = useState(auth.getUserProfileLastname());
  const [tandc, settandc] = useState(false);
  const [account, setAccount] = useState();
const [bankVerifyData, setBankVerifyData] = useState(auth.getBankVerifyData());
  const [httpProcessing, setHttpProcessing] = useState(false);

  const setAccountSelected = (e) => {
    setAccount(e.target.value);
  }
  const settandcValue = (e) => {
    settandc(!tandc);
  }

  const [accounts, setAccounts] = useState([]);

  useEffect(() => {

    client.getuseraccounts(auth.getuserProfileId()).then((data) => {
      setAccounts(data);
    },
      (error) => {
        props.ShowAlert(error.response, sc.danger);
      });

      if(location.state?.accountNumber){
        setAccount(location.state?.accountNumber);
      }
  }, []);

  //const [name, setName]= useState(`${auth.getUserProfileFirstname()} ${auth.getUserProfileLastname()}`);

  const verify = () => {

    if (!firstname) {
      props.ShowAlert(sc.firstnamerequired, sc.warning);
      return;
    }
    if (!lastname) {
      props.ShowAlert(sc.lastnamerequired, sc.warning);
      return;
    }
    if (!bvn) {
      props.ShowAlert(sc.identityidrequired, sc.warning);
      return;
    }
    if (!account) {
      props.ShowAlert(sc.accountnumberrequired, sc.warning);
      return;
    }
    if (!tandc) {
      props.ShowAlert(sc.tandcrequired, sc.warning);
      return;
    }

    setHttpProcessing(true);

    let handler = PaystackPop.setup({
      // key: sc.paystack_public_key_test, // Replace with your public key
      key: sc.paystack_public_key_live,
      email: auth.getUserProfileEmail(),
      amount: 50 * 100,
      name: `${auth.getUserProfileFirstname()} ${auth.getUserProfileLastname()}`,
      ref: Math.floor((Math.random() * 1000000000) + 1) + '-' + auth.getuserProfileId(), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
      // label: "Optional string that replaces customer email"
      onClose: function () {
        //props.ShowAlert('Payment View Closed', sc.warning);
        setHttpProcessing(false);
      },
      callback: function (response) {
        client.completeverificationpayment({ reference: response.reference, accountNumber: account }).then((data) => {

          props.ShowAlert(data.verified, data.verified === sc.verified ? sc.success : sc.danger);

          auth.updateBankVerifyStatus(data);
          setHttpProcessing(false);
          
          if(auth.getLoanPackageId()){
            navigate(sc.loanrequestroute);
          }

          navigate(sc.settingsroute);
        }, (error) => {
          props.ShowAlert(error.response, sc.danger);
          setHttpProcessing(false);
        });

      }
    });
    handler.openIframe();


  }

  return (
    <>

      {/* <!-- App Header --> */}
      <div style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }} className="appHeader bg-primary text-light">
        <div className="left">
          <a onClick={() => navigate(-1)} className="headerButton goBack">
            <ion-icon name="chevron-back-outline"></ion-icon>
          </a>
        </div>
        <div className="pageTitle">
          BVN Verification
        </div>

      </div>
      {/* <!-- * App Header -->


    <!-- App Capsule --> */}
      <div id="appCapsule" className="extra-header-active full-height">

        <div className="section mt-2">

          <div className="item">

            <div className="in">
              <div>Your BVN will be verified using your BVN and Account number, ensure your first and last name is exactly same as that of your BVN and account information. <br/><span className="text-primary">Verification cost {util.commaCurrencyFormatter(50)} only</span></div>
            </div>
          </div>

      {(auth.getBankVerifyStatus() === sc.failed) && <>

          <div className="card" style={{ "margin": "1em" }}>

            <div className="card-body">
<h4>Failed Verification Result</h4><hr/>
<table>
  <tr>
    <td>{bankVerifyData.bvn?<span className="badge badge-success"><ion-icon name="checkmark-outline"></ion-icon></span>:<span className="badge badge-danger"><ion-icon name="close-outline"></ion-icon></span>} BVN</td>
    <td>{bankVerifyData.firstName?<span className="badge badge-success"><ion-icon name="checkmark-outline"></ion-icon></span>:<span className="badge badge-danger"><ion-icon name="close-outline"></ion-icon></span>} Firstname</td>
  </tr>
  <tr>
    <td>{bankVerifyData.lastName?<span className="badge badge-success"><ion-icon name="checkmark-outline"></ion-icon></span>:<span className="badge badge-danger"><ion-icon name="close-outline"></ion-icon></span>} Lastname</td>
    <td>{bankVerifyData.accountNumber?<span className="badge badge-success"><ion-icon name="checkmark-outline"></ion-icon></span>:<span className="badge badge-danger"><ion-icon name="close-outline"></ion-icon></span>} Acct Number</td>
  </tr>
  <tr>
    <td colSpan={2}>{!bankVerifyData.isBlacklisted?<span className="badge badge-success"><ion-icon name="checkmark-outline"></ion-icon></span>:<span className="badge badge-danger"><ion-icon name="close-outline"></ion-icon></span>} Not Blacklisted</td>
    </tr>
</table>


            </div>
          </div>
          </>
      }

          <div className="card" style={{ "margin": "1em" }}>

            <div className="card-body">

              <InputLabel value={bvn} label={'BVN'} />
              <InputLabel value={firstname} label={'Firstname'} />
              <InputLabel value={lastname} label={'Lastname'} />

              <select value={account} className="form-control custom-select" id="account" onChange={setAccountSelected}>
                <option key="-1">Select Account</option>
                {accounts.map(account => (
                  <option key={account.accountNumber} value={account.accountNumber} selected={account.accountNumber===location.state?.accountNumber} >{account.accountNumber} - {account.bankName}</option>
                ))}
              </select>
              <a className="text-muted text-primary" onClick={() => navigate(sc.newuseraccountroute, { "state": { 'source': sc.verification } })}>Add new account</a>


            </div>
          </div>


          <div className="custom-control custom-checkbox mt-2 mb-1">
            <div className="form-check">
              <input
                checked={tandc}
                onChange={settandcValue}
                id="tandc"
                type="checkbox"
                className="form-check-input"
              />
              <label className="form-check-label" htmlFor="tandc">
                I agree{" "}
                <a
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#termsModal"
                >
                  terms and conditions
                </a>
              </label>
            </div>
          </div>
          <br />
          <div className="form-group transparent">

            <CustomButton httpProcessing={httpProcessing} text="Proceed" onClick={verify} />
          </div>

        </div>



      </div>
      {/* <!-- * App Capsule --> */}

      {/* <!-- Terms Modal --> */}
      <div
        className="modal fade modalbox"
        id="termsModal"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content" style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}>
            <div className="modal-header" style={{ width: auth.appWidth(), marginLeft: auth.appMargin() }}>
              <h5 className="modal-title">Terms and Conditions</h5>
              <a href="#" data-bs-dismiss="modal">
                Close
              </a>
            </div>
            <div className="modal-body" style={{ textAlign: "justify" }}>

              <p>You acknowledge that you have read this Agreement and agree to all its terms and conditions. Payment is a non-refundable charge for bank verification service which is handled by a 3rd party organization</p>
              <h2>Contacting us</h2>
              <p>If you have any questions, concerns, or complaints regarding this Agreement, we encourage you to contact us using the details below:</p>
              <p><a target="_blank" rel="nofollow" href="https://loanhub.ng">https://loanhub.ng</a></p>
              <p>This document was last updated on June 18, 2022</p>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- * Terms Modal --> */}
    </>
  )
}
export default IdentificationVerification;