import React, { useState } from "react";
import { useEffect } from "react";
import sc from "../services/stringconst";
import { useNavigate } from "react-router-dom";
import LoanPackage from "./childcomponents/loanpackage.js";
import { Client } from "../services/apiclient";
import auth from "../services/authservice";
import { useLocation } from "react-router-dom";
import LoadingTextPlaceHolder from "./childcomponents/loadingtextplaceholder";

 const UserLoanPackages =(props)=> {
   let location= useLocation();
    let navigate= new useNavigate();
    let [dataLoaded, setDataLoaded]=useState(false);
    let client= new Client();
      
  const back=()=>{
    if(isLoggedInUser){
      navigate(sc.settingsroute);
    }
    else{
      navigate(sc.profileviewroute, {"state":userId});
    }

  }

  const [loanPackages, setLoanPackages]=useState([]);

  let userId=undefined;
  let username=undefined;
  let isLoggedInUser=false;

  if(location.state){
    username=location.state.username;
    userId=location.state.userId;
    isLoggedInUser=false;
  }
  else{
    username=auth.getUserProfileUsername();
    isLoggedInUser=true;
  }
    
  useEffect(()=>{
    
    setDataLoaded(false);
    client.userloanpackages(username)
    .then(data=>{
      setDataLoaded(true);
      setLoanPackages(data);
    },
    error=>{
      props.ShowAlert(error.response,  sc.danger);
    })
  
},[]);

  const addLoanPackage=()=>{
    navigate(sc.newloanpackageroute);
  }

    return (
      <>
      <div  style={{width:auth.appWidth(), marginLeft:auth.appMargin()}} className="appHeader  bg-primary text-light">
        <div className="left">
          <a onClick={back} className="headerButton goBack">
            <ion-icon name="chevron-back-outline"></ion-icon>
          </a>
        </div>
        <div className="pageTitle">Loan Packages</div>
        {isLoggedInUser && <div className="right">
                    <a onClick={addLoanPackage} className="headerButton">
                        <ion-icon name="add-circle"></ion-icon>
                    </a>
                </div>}
      </div>
      <div id="appCapsule"  className="extra-header-active full-height" >


<div className="section full">
            

        {loanPackages.map(value=>(   
        <div key={value.lpId} className="accordion" id="loanpkg">
                <div className="accordion-item">
                    <h2 className="accordion-header"   style={{background:"#f9f9f9"}}>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target={"#acc"+value.lpId}>
                          <b>  {value.title} &nbsp;</b> <span className="badge badge-warning">{value.interestPercentage}%</span>
                        </button>
                    </h2>
                    <div id={"acc"+value.lpId} className="accordion-collapse collapse" data-bs-parent="#loanpkg">
                        <div className="accordion-body">
                          <LoanPackage loanPackage={value} isOwner={isLoggedInUser}  showTitle={false} />
            
                        </div>
                    </div>
                </div>

            </div>
        ))}

{!dataLoaded &&
          <>
           <LoadingTextPlaceHolder />

          </>}
        </div>

        </div>
      </>
    );

}
export default UserLoanPackages