import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Client } from "../services/apiclient";
import { useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import auth from "../services/authservice";
import sc from "../services/stringconst";
import ImageModal from "./childcomponents/imagemodal";
import * as moment from 'moment';
import { useScrollBy } from "react-use-window-scroll";
import { io } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';


 const ChatMessage =(props)=> {
    const navigate= useNavigate();
    const location= useLocation();

    const client= new Client(null,null);
    const scrollBy = useScrollBy();
    const [chatMessages, setChatMessages]=useState([]);
    const [chatMessageHistory, setChatMessageHistory]=useState([]);
    const [userId, setUserId]=useState();
    const [name, setName]=useState();
    const [photoUrl, setPhotoUrl]=useState();
    const [chatId, setChatId]=useState();
    const [maxScroll, setMaxScroll]=useState();
    const [loggedInUserId, setLoggedInUserId]=useState();

    const [typingMessage, setTypingMessage]=useState(false);

    const mySocketClient = useRef();

    const [message, setMessage]=useState();
  const [messageForOptions, setMessageForOptions]=useState({});

useEffect(()=>{
    if(!auth.IsUserLoggedIn()){
        navigate(sc.loginroute);
    }
    if(!location.state){
        navigate(sc.homeroute);
    }
    setLoggedInUserId(auth.getuserProfileId());
    setUserId(location.state.userId);
    setName(location.state.name);
    setPhotoUrl(location.state.photoUrl);
    setChatId(location.state.chatId);

    

    client.getchatmessages(location.state.chatId??location.state.userId).then((result) => {
      setChatMessageHistory(result.chatMessages);

        let chatIdValue=null;
        if(result.chatId){
            setChatId(result.chatId);
            chatIdValue=result.chatId;
        }
        else{
          chatIdValue=uuidv4();
          setChatId(chatIdValue);
        }

        let msg={
          chatId: chatIdValue,
          receiverUserId: location.state.userId,
          senderUserId: auth.getuserProfileId()
        }
        
        mySocketClient.current.emit('chatconnection', msg);

        setMaxScroll(result.chatMessages.length* 400);
        scrollBy({
            top: result.chatMessages.length* 400,
            left: 0,
          });
      },
      error => {
       props.ShowAlert(error.response,  sc.danger);
      });

  
      let socket = io(sc.chatbaseroute);

    socket.on('newMessage', (message) =>
    receiveMessage(message)
  );

    socket.on('disconnect', (reason) =>
      console.log(`Client disconnected: ${reason}`)
    );

    socket.on('receiveTypingMessage', () =>
      showTypingMessage()
  );

    socket.on('connect_error', (reason) =>
      console.log(`Client connect_error: ${reason}`)
    );

    mySocketClient.current = socket;
    

    
  },[]);


  const handleChange=(e)=>{
      let msg=e.target.value;
    setMessage(msg);

    let message={
      _id: "",
      chatId: chatId,
      receiverUserId: userId,
      senderUserId: loggedInUserId,
      pinned: false,
      timeStamp: new Date()
    }
    if(chatId){
      mySocketClient.current.emit('typingMessage', message);
    }
  }
  const sendMessageOnKeyPress=(e)=>{
    if(e.key==="Enter"){
        sendMessage();
    }
  }

  const showTypingMessage=()=>{
    setTypingMessage(true);
    setTimeout(() => {
      setTypingMessage(false)
    }, "5000");
  }

  const receiveMessage=(msg)=>{
    chatMessages.push(msg);
    setChatMessages(chatMessages);
    let newScrollLength=(chatMessageHistory.length* 400)+200;
setMaxScroll(newScrollLength);
    scrollBy({
        top: newScrollLength,
        left: 0,
        behavior: "smooth",
      });
  }


  const goBack=()=>{

    if(chatMessages.length>0){
      if(chatMessages[chatMessages.length-1].receiverUserId===loggedInUserId){
        client.setuserlastmessageread({chatId: chatId});
      }
    }
    else{
      if(chatMessageHistory.length>0){
        if(chatMessageHistory[chatMessageHistory.length-1].receiverUserId===loggedInUserId){
          client.setuserlastmessageread({chatId: chatId});
        }
      }
    }
    navigate(-1);
  }

  const deleteMessage=()=>{

    if(messageForOptions.senderUserId=== loggedInUserId){
      client.deletechatmessage(messageForOptions.messageId,{});

      let temp_chatMessage = [...chatMessages];
      let chatMessage=temp_chatMessage.filter(o=>o.messageId===messageForOptions.messageId);
      if(chatMessage.length>0){
        temp_chatMessage.splice(temp_chatMessage.indexOf(chatMessage[0]),1);
        setChatMessages(temp_chatMessage);
      }
      let temp_chatMessageHistory = [...chatMessageHistory];
      chatMessage=temp_chatMessageHistory.filter(o=>o.messageId===messageForOptions.messageId);
      if(chatMessage.length>0){
        temp_chatMessageHistory.splice(temp_chatMessageHistory.indexOf(chatMessage[0]),1);
        setChatMessageHistory(temp_chatMessageHistory);
      }
  
    }

  }

  const deleteUserChat=()=>{

      client.deleteuserchat(chatId,{}).then((result) => {
        navigate(-1);
        },
        error => {
         props.ShowAlert(error.response,  sc.danger);
        });

  }

  const pinMessage=()=>{
    if(!messageForOptions.pinned)
      client.pinchatmessage({id: messageForOptions.messageId});
    
    if(messageForOptions.pinned)
      client.unpinchatmessage({id: messageForOptions.messageId});

  

    let temp_chatMessage = [...chatMessages];
    let chatMessage=temp_chatMessage.filter(o=>o.messageId===messageForOptions.messageId);
    if(chatMessage.length>0){
     
      temp_chatMessage[temp_chatMessage.indexOf(chatMessage[0])].pinned=!messageForOptions.pinned;
      setChatMessages(temp_chatMessage);
      
    }

    let temp_chatMessageHistory = [...chatMessageHistory];
    chatMessage=temp_chatMessageHistory.filter(o=>o.messageId===messageForOptions.messageId);
    if(chatMessage.length>0){
      temp_chatMessageHistory[temp_chatMessageHistory.indexOf(chatMessage[0])].pinned=!messageForOptions.pinned;
      setChatMessageHistory(temp_chatMessageHistory);
      
    }

    setMessageForOptions({});
  }
  const sendMessage=()=>{
    let new_message=message.trimStart().trimEnd();
    let msg={
        _id: "",
        chatId: chatId,
        message: new_message,
        receiverUserId: userId,
        senderUserId: loggedInUserId,
        pinned: false,
        messageId:uuidv4(),
        timeStamp: new Date()
      }
    chatMessages.push(msg);
    setChatMessages(chatMessages);
    setMessage('');

    scrollBy({
        top: maxScroll+200,
        left: 0,
        behavior: "smooth",
      });

      mySocketClient.current.emit('createMessage', msg);

  }


    return (
      <div id="appCapsule" className="extra-header-active full-height"  style={{background:"white", minHeight:"50em"}} >
      <div className="col-md-12">

{/* <!-- App Header --> */}
    <div   style={{width:auth.appWidth(), marginLeft:auth.appMargin()}} className="appHeader  bg-primary text-light">
        <div className="left">
            <a className="headerButton goBack" onClick={goBack}>
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
            <a>
              <img src={photoUrl?photoUrl:auth.getDefaultImageUrl()} alt="avatar" className="imaged rounded"  style={{width:"3em", height:"3em"}} data-bs-toggle="modal" data-bs-target="#DialogImage" />
            </a>
        </div>
        <div className="pageTitle">{name}
        <br/>
        {typingMessage && <div className="text-muted">typing...</div>}
</div>
        <div className="right">
        <a data-bs-toggle="modal" data-bs-target="#actionSheetDelete" className="headerButton item">
                <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                </a>
        </div>
    </div>
    {/* <!-- App Header --> */}
{/* <!-- Iconed Action Sheet --> */}
<div className="modal fade action-sheet" id="actionSheetDelete" tabIndex="-1" role="dialog" >
            <div className="modal-dialog" role="document" >
                <div className="modal-content"  style={{width:auth.appWidth(), marginLeft:auth.appMargin()}}>
                    <div className="modal-body">
                        <ul className="action-button-list listview flush transparent no-line image-listview">
                            <li>
                            <a onClick={deleteUserChat}  data-bs-dismiss="modal" className="item">
                                <div className="icon-box bg-primary">
                                <ion-icon name="trash-outline"></ion-icon>
                                </div>
                                <div className="in">
                                    Delete Chat
                                </div>
                            </a>
                            </li>
                           
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- * Iconed Action Sheet --> */}

    <div>

    { chatMessageHistory.map(item=>(

<div className={loggedInUserId===item.receiverUserId?"message-item":"message-item user"}>
<div >
  <div className="bubble" onClick={()=>setMessageForOptions(item)}  data-bs-toggle='modal' data-bs-target='#DialogMessageOption'>
      {item.message} &nbsp;
      {item.pinned && item.senderUserId===loggedInUserId && <span class="badge badge-warning badge-empty"></span>}
      {item.pinned && item.senderUserId!==loggedInUserId && <span class="badge badge-primary badge-empty"></span>}
  </div>
  <div className="footer">{moment(item.timeStamp).format("DD.MMM.YY hh:mm")}</div>
</div>
</div>
)
)}
    { chatMessages.map(item=>(

<div className={loggedInUserId===item.receiverUserId?"message-item":"message-item user"}>
<div>
  <div className="bubble"  onClick={()=>setMessageForOptions(item)}   data-bs-toggle='modal'  data-bs-target='#DialogMessageOption'>
      {item.message} &nbsp;
      {item.pinned && item.senderUserId===loggedInUserId && <span class="badge badge-warning badge-empty"></span>}
      {item.pinned && item.senderUserId!==loggedInUserId && <span class="badge badge-primary badge-empty"></span>}
  </div>
  <div className="footer">{moment(item.timeStamp).format("DD.MMM.YY hh:mm")}</div>
</div>
</div>
)
)}

    </div>
    {/* <!-- * App Capsule -->

    <!-- chat footer --> */}
    <div className="chatFooter" style={{zIndex:10001, width:auth.appWidth(), marginLeft:auth.appMargin()}}>

            <div className="form-group basic">
                <div className="input-wrapper">
                    <input type="text" value={message} onChange={handleChange} onKeyPress={sendMessageOnKeyPress} className="form-control" placeholder="Type a message..."/>
                    <i className="clear-input">
                        <ion-icon name="close-circle"></ion-icon>
                    </i>
                </div>
            </div>
          {message && <button type="button" className="btn btn-icon btn-primary rounded" onClick={sendMessage}>
                <ion-icon name="arrow-forward-outline"></ion-icon>
            </button>}

    </div>
    {/* <!-- * chat footer --> */}

    <ImageModal url={photoUrl?photoUrl:auth.getDefaultImageUrl()} />

          {/* <!-- Dialog Message Option --> */}
          <div className="modal fade dialogbox" id="DialogMessageOption" data-bs-backdrop="static" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                <div className="card-footer">
              <a 
            style={{ width: loggedInUserId===messageForOptions.senderUserId?"45%":"90%", marginRight:"2%" }}
            className="btn btn-primary marginLeft5px"
            onClick={pinMessage}
            data-bs-dismiss="modal"
          >
         {!messageForOptions.pinned && <ion-icon name="pin-outline"></ion-icon> }
         {messageForOptions.pinned && <ion-icon name="remove-outline"></ion-icon> }
          </a>
 
 {loggedInUserId===messageForOptions.senderUserId &&             <a
            style={{ width: "45%" }}
            className="btn btn-danger marginLeft5px"
            onClick={deleteMessage}
            data-bs-dismiss="modal"
          >
            <ion-icon name="trash-outline"></ion-icon>
          </a>
 }
 </div>
                    <div className="modal-footer">
                        <div className="btn-inline">
                            <a href="#" className="btn btn-text-secondary" data-bs-dismiss="modal"><ion-icon name="close-outline"></ion-icon></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- * Dialog Message Option --> */}

    </div>
      </div>
    );

}
export default ChatMessage