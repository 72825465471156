import React, { useState } from "react";
import sc from "../services/stringconst";
import {Client } from "../services/apiclient";
import { useNavigate } from "react-router-dom";
import CustomButton from "./childcomponents/custombutton";
import auth from "../services/authservice";


 const ChangePassword =(props)=> {
    let navigate= new useNavigate();
const [httpProcessing, setHttpProcessing]=useState(false);

    const changePassword=(e)=>{

        let client= new Client();
        e.preventDefault();

        if(!changePasswordData.oldPassword){
            props.ShowAlert(sc.oldpasswordrequired,  sc.warning);
            return;
        }
        if(!changePasswordData.newPassword){
            props.ShowAlert(sc.newpasswordrequired,  sc.warning);
            return;
        }
        if(!changePasswordData.repeatNewPassword){
            props.ShowAlert(sc.repeatedpasswordrequired,  sc.warning);
            return;
        }
        if(changePasswordData.oldPassword.toLowerCase()===changePasswordData.newPassword.toLowerCase()){
            props.ShowAlert(sc.currentpasswordcannotbesameasnew,  sc.warning);
            return;
        }

        if(changePasswordData.newPassword!== changePasswordData.repeatNewPassword){
            props.ShowAlert(sc.newpasswordmismatch,  sc.warning);
            return;
        }

      var data= {
            "oldPassword": changePasswordData.oldPassword,
            "newPassword": changePasswordData.newPassword
          };
          setHttpProcessing(true);
        client.changepassword(data)
        .then(
            data => {
                setHttpProcessing(false);
                props.ShowAlert(sc.passwordChangeSuccessful,  sc.success);
                navigate(sc.homeroute);
               },
               error => {
                setHttpProcessing(false);
                props.ShowAlert(error.response,  sc.danger);
               }
        );

    }

    const [changePasswordData , setChangePasswordData] = useState({
        oldPassword : "",
        newPassword : "",
        repeatNewPassword : ""
    });

    const handleChange = (e) => {
        const {id , value} = e.target   
        setChangePasswordData(prevState => ({
            ...prevState,
            [id] : value
        }))
    }

    const back=()=>{
        navigate(-1);
      }

    return (
      <>
      <div  style={{width:auth.appWidth(), marginLeft:auth.appMargin()}} className="appHeader  bg-primary text-light">
        <div className="left">
          <a onClick={back} className="headerButton goBack">
            <ion-icon name="chevron-back-outline"></ion-icon>
          </a>
        </div>
        <div className="pageTitle">Change Password</div>
      </div>
      <div id="appCapsule" className="extra-header-active full-height">

        <div  className="section mb-5 p-2" style={{"marginTop":"10%"}}>
            
                <div  className="card">
                    <div  className="card-body pb-1">

                    <div  className="form-group basic">
                            <div  className="input-wrapper">
                                <label  className="label" htmlFor="password">Current Password</label>
                                <input type="password"  onChange={handleChange} id="oldPassword" className="form-control"  placeholder="Your current password"/>
                                <i  className="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>
                        <div  className="form-group basic">
                            <div  className="input-wrapper">
                                <label  className="label" htmlFor="password">New Password</label>
                                <input type="password" onChange={handleChange} id="newPassword"  className="form-control" placeholder="Your new password"/>
                                <i  className="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>

                        <div  className="form-group basic">
                            <div  className="input-wrapper">
                                <label  className="label" htmlFor="retypepassword">Retype New Password</label>
                                <input type="password"  onChange={handleChange} id="repeatNewPassword"  className="form-control"  placeholder="Retype new password"/>
                                <i  className="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>

                        <div  className="form-group basic">
                            <div  className="input-wrapper">
                            
                            <CustomButton httpProcessing={httpProcessing} text='Change Password' onClick={changePassword} />
                            </div>
                        </div>
                    </div>
                </div>

              
        </div>
        </div>

      </>
    );
  
}
export default ChangePassword