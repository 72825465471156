import React from 'react';
import sc from '../../services/stringconst';
import LoadingViewPlaceHolder from './loadingviewplaceholder';


function LoadingTextPlaceHolder() {


    return (
        <div>
            {sc.loaderViewCountThree.map(o=><> 
          <table style={{width:"100%"}}>
            <tr>
              <td>
                <LoadingViewPlaceHolder extraStyles={{height: '15px', marginBottom: '16px', borderRadius: '10px'}} />
                         <LoadingViewPlaceHolder extraStyles={{height: '15px', width: '100px', borderRadius: '10px',
                         margin: '4px 0'}} />
                         </td>
                         <td>
          <LoadingViewPlaceHolder extraStyles={{height: '15px', width: '100px', borderRadius: '10px',
                         margin: '4px 0'}} />
                         </td>
            </tr>
          </table><br/>
                
               
                      </>)}
        </div>
    );
}

export default LoadingTextPlaceHolder;