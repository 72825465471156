import React, { useState, useEffect } from "react";
import {useNavigate} from 'react-router-dom';
import {Client} from '../services/apiclient';
import auth from "../services/authservice";
import sc from "../services/stringconst"
import ImageModal from "./childcomponents/imagemodal";
import util from "../Utilities/UtilityHelper";
import LoadingImagePlaceHolder from "./childcomponents/loadingimageplaceholder";
import CustomButton from "./childcomponents/custombutton";

const Borrow = (props) => {

  let navigate= useNavigate();
  let client= new Client(null,null);
  
  let [imageUrl, setImageUrl]=useState();

  let [states, setStates]=useState([]);
  let [cities, setCities]=useState([]);
  let [lenders, setLenders]=useState([]);

  const [httpProcessing, setHttpProcessing]= useState(false);
  
  let [dataLoaded, setDataLoaded]=useState();

 let [pageHeader, setPageHeader]=useState("Search Loan Packages");
 
  let interestRange=[];
  for(let i=0; i<=36; i++){
      interestRange.push(i);
  }

  const gotoWelcome=()=>{
    navigate(sc.welcomeroute);
}

  let [lenderName, setLenderName]=useState(undefined);
  let [amount, setAmount]=useState(undefined);
  let [minInterest, setMinInterest]=useState(-1);
  let [maxInterest, setMaxInterest]=useState(101);
  let [searchState, setSearchState]=useState(auth.getUserProfileState());
  let [searchCity, setSearchCity]=useState(auth.getUserProfileCity());
  let [showMoreVisibility, setShowMoreVisibility]=useState(false);

  useEffect(()=>{

    search();
    client.states().then(
      result => {
          setStates(result);
          if(searchState){
            client.city(searchState).then(
              result => {
                  setCities(result);
             });
          }
     })
},[]);


const setLenderNameValue=(e)=>{
  setLenderName(e.target.value);
}
const setAmountValue=(e)=>{
  setAmount(e.target.value);
}
const setMinInterestValue=(e)=>{
  setMinInterest(e.target.value);
}
const setMaxInterestValue=(e)=>{
  setMaxInterest(e.target.value);
}
const getCities=(e)=>{
  setSearchState(e.target.value);
  client.city(e.target.value).then(
      result => {
          setCities(result);
     });
}

const setSelectedCity=(e)=>{
  let value=e.target.value;
  if(value.indexOf('Select')>-1){
    value=undefined;
  }
  setSearchCity(value);
}

const setShowMoreVisibilityStatus=()=>{
  setShowMoreVisibility(false);
}
  const [searchBoxVisible, setSearchBoxVisible]=useState(true);
  
  
  const setSearchBoxVisibility=()=>{
    setSearchBoxVisible(!searchBoxVisible);
  }

  const search=(retry=0)=>{

    if(minInterest && maxInterest){
      if(parseInt(minInterest)>parseInt(maxInterest)){
        props.ShowAlert('Min interest cannot be less', sc.warning);
        return;
      }
    }

    if(!searchState){
      //props.ShowAlert('State is required', sc.warning);
      //return;
    }

    

    setDataLoaded(false);

    if(retry == 1){
      searchCity = '';
    }

  setHttpProcessing(true);
  client.loanpackages(minInterest, maxInterest, searchCity, amount, lenderName, searchState). then(data=>{
    setHttpProcessing(false);
    setDataLoaded(true);
    if(data.length>0){
      setSearchBoxVisible(false);
    }else{
      if(retry==1){
          props.ShowAlert(`No loan package found in ${searchCity} ${searchState}`, sc.warning);
        }
        else{
          setSearchCity('');
          props.ShowAlert(`No loan package found, searching all cities in ${searchState}`, sc.warning);
          search(1);
        }
    }
  setLenders(data);
},
error=>{
  setHttpProcessing(false);
  //setLenderLoaded(true);
  props.ShowAlert(error.response,  sc.danger);
})


  }


const openLoanApplication=(loanPackage)=>{
  navigate(sc.loanpackagedetailsroute, {state:loanPackage});
}

const openChatMessage=(user)=>{
  navigate(sc.chatmessageroute, {state:{userId:user.lenderId, name:`${user.firstname} ${user.lastname}`, photoUrl: user.photoUrl}});
}

const openLenderProfile=(userId)=>{
  navigate(sc.profileviewroute, {"state":userId});
} 
  return (
    <>
      

      <div  style={{width:auth.appWidth(), marginLeft:auth.appMargin()}} className="appHeader  bg-primary text-light highZIndex">
      <div className="left">
                      <a onClick={gotoWelcome} className="headerButton goBack">
                      <ion-icon name="home-outline"></ion-icon>
                      </a>
                    </div>
        <div className="pageTitle">{pageHeader}</div>
        <div className="right">
          <a className="headerButton toggle-searchbox" >
            {/* <ion-icon onClick={displaySearch} id="searchbox-lender-toggle" name="search-outline"></ion-icon> */}
            {!searchBoxVisible && <ion-icon onClick={setSearchBoxVisibility} id="searchbox-lender-toggle" name="search-outline"></ion-icon>}
            
          </a>
        </div>
      </div>
      
  
    <br/>
 {searchBoxVisible &&  <div id="lenderSearch" style={{background:"white", position:"fixed", zIndex:"1001", width:auth.appWidth()}} >
 <div  style={{width:auth.appWidth(), marginLeft:auth.appMargin()}} className="appHeader  bg-primary text-light">
       
       <div className="pageTitle">{pageHeader}</div>
       <div className="right">
         <a className="headerButton toggle-searchbox" >
           {searchBoxVisible && <ion-icon onClick={setSearchBoxVisibility} id="searchbox-lender-toggle" name="close"></ion-icon>}
         </a>
       </div>
     </div>
     <div ></div>
     <div className="extraHeaderSearch">
        <form className="search-form">
          
            <input value={lenderName} onChange={setLenderNameValue} type="text" className="form-control" placeholder="Lender (optional)" />
          
        </form>
      </div>
      <div className="extraHeaderSearch">
        <form className="search-form">
          
            <input  onChange={setAmountValue} value={amount} type="text" className="form-control" placeholder="Amount (optional)" />
          
        </form>
      </div>
        <div className="extraHeaderSearch">
        <form className="search-form">
          
          <table style={{width:"100%"}}>
                               <tbody>
                               <tr>
                                 <td style={{width:"50%"}}><span>
                                   {/* <input onChange={setMinInterestValue}  value={minInterest} id="minInterest" style={{width:"100%"}} className="form-control" placeholder="min interest e.g 5" /> */}
                                   <select  onChange={setMinInterestValue} value={minInterest} id="minInterest"  style={{width:"100%"}} className="form-control custom-select">
                                     <option key="-1" value="-1" >Min Interest %</option>
                                    { interestRange.map(value=>(
                                    <option key={value} value={value}>{value}%</option>
                                ))}
                                      </select>
                                   </span></td>
                                 <td style={{width:"50%"}}><span>
                                   {/* <input onChange={setMaxInterestValue}   value={maxInterest} id="maxInterest"  style={{width:"100%"}} className="form-control" placeholder="max interest e.g 15" /> */}
                                   <select value={maxInterest} onChange={setMaxInterestValue}  id="maxInterest"   style={{width:"100%"}} className="form-control custom-select">
                                   <option key="101" value="101" >Max Interest %</option>
                                    { interestRange.map(value=>(
                                    <option value={value} key={value}>{value}%</option>
                                ))}
                                      </select>
                                   </span></td>
                               </tr>
                               </tbody>
                               </table>
          
        </form>
      </div>
      
      <div className="extraHeaderSearch">
        <form className="search-form">
          
          <select value={searchState} className="form-control custom-select" id="state" onChange={getCities}>
                                    <option key="-1">Select State</option>
                                { states.map(state=>(
                                    <option key={state.title} value={state.title} >{state.title}</option>
                                ))}
                                </select>
          
        </form>
      </div>

      <div className="extraHeaderSearch">
        <form className="search-form">
          
          <select onChange={setSelectedCity} value={searchCity} aria-label="labessl for the select" className="form-control custom-select" id="city">
                                    <option key="-1">Select City (Not required)</option>
                                    { cities.map(city=>(
                                    <option value={city.title} key={city.title}>{city.title}</option>
                                ))}
                                </select>
          
        </form>
      </div>

      <div className="extraHeaderSearch">

      <CustomButton httpProcessing={httpProcessing} text='Search' onClick={search} />
      </div>

      </div>}


      
      <div id="appCapsule" className="extra-header-active full-height" >
      <div className="section">
        {dataLoaded &&  <div className="text-muted">
            Found {lenders.length} Loan Package
          </div> }

          {lenders && lenders.map(lender=> (
          <div key={lender.lpId} className="card" style={{"marginBottom": "0.8em"}}>
            <ul className="listview image-listview media transparent flush">
              <li>
                <a  className="item">
                   
                  <div onClick={()=>setImageUrl(lender.photoUrl)} className="imageWrapper" data-bs-toggle="modal" data-bs-target="#DialogImage" >
                    <img 
                      src={(lender.photoUrl && lender.photoUrl!==sc.undefined)?lender.photoUrl:auth.getDefaultImageUrl()}
                      alt="image"
                      className="imaged w64"
                    />
                  </div>
                  <div className="in">
                    <div style={{width:"100%"}}>
                      <div style={{float:"right"}}>
                        <span className="badge badge-outline-warning" style={{marginBottom: "0.5em"}}>{lender.interestPercentage}%</span> <br/>
                        {lender.collateral && <span className="badge badge-outline-danger">&nbsp;<ion-icon name="swap-horizontal-outline"></ion-icon>&nbsp;</span> }
                    </div>
                    
                    <span onClick={()=>openLoanApplication(lender)} className="text-primary">{lender.title}</span>
                    <br/>
                    
                      <div className="text-muted">
                      <span><span  onClick={()=>openLoanApplication(lender)} style={{color:"black"}}>{util.commaCurrencyFormatter(lender.minAmount)}</span> - <span style={{color:"black"}}>{util.commaCurrencyFormatter(lender.maxAmount)}</span></span>
                      
                      
                      <br/>
                      <hr/>
                   <span onClick={()=>openLenderProfile(lender.lenderId)}  className="text-primary">{lender.displayName} {lender.photoVerified && <img width="12" src="https://loanhub.ng/appimages/photoverified.png" />} {lender.bankVerified && <img width="12" src="https://loanhub.ng/appimages/bankverified.png" />}</span> 
                   &nbsp;<ion-icon name="location-outline"></ion-icon>
                        {lender.city}, {lender.state}

                   <br/> <br/><span className="text-primary" onClick={()=>navigate(sc.lendercreditcheckroute, {state:{name:`${lender.firstname} ${lender.lastname}`, lenderId:lender.lenderId}})}><ion-icon name="speedometer-outline"></ion-icon> Credit Rating&nbsp; </span>
                      
                        <div style={{float:"right"}}>
                     { auth.getuserProfileId()!==lender.lenderId && <span className="badge bg-primary" onClick={()=>openChatMessage(lender)} >&nbsp;<ion-icon name="chatbubbles-outline"></ion-icon>&nbsp;</span> }
                    </div>
                      </div>
                    </div>
                  </div>
                 
                </a>
              </li>            

            </ul>
          </div>))} 

          {dataLoaded===false &&
          <>
           <LoadingImagePlaceHolder />
          </>}
          
        </div>
        {/* to be fully implemented later */}
       {showMoreVisibility && 
       <div className="section mb-2">
            <a onClick={setShowMoreVisibilityStatus} className="btn btn-block btn-secondary">
              Load More
            </a>
          </div>}
      </div>

      <ImageModal url={imageUrl} />
    </>
  );
};

export default Borrow;
