import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { Client } from "../services/apiclient";
import { useLocation } from "react-router-dom";
import sc from "../services/stringconst";
import CustomButton from "./childcomponents/custombutton";
import auth from "../services/authservice";
import util from "../Utilities/UtilityHelper";


 const NewLoanPackage =(props)=> {
  const location= useLocation();
  let editLoanPackage=location.state;

    let navigate= new useNavigate();
      let client= new Client(null, null);

      
  const [httpProcessing, setHttpProcessing]= useState(false);

    let interestRange=[];
    for(let i=0; i<=100; i++){
        interestRange.push(i);
    }

    let durationRange=[];
    for(let i=1; i<=24; i++){
        durationRange.push(i);
    }

    let durationTypeRange=[sc.Days, sc.Weeks, sc.Months];

  const back=()=>{
    navigate(sc.loanpackageroute);
  }

  
  const handleChange = (e) => {
    const {id , value} = e.target   
    setLoanPackage(prevState => ({
        ...prevState,
        [id] : value
    }))
}

  const options = [
    { value: 'Phone', label: 'Phone' },
    { value: 'Laptop', label: 'Laptop' },
    { value: 'Car', label: 'Car' },
    { value: 'Electronics', label: 'Electronics' },
    { value: 'Bike', label: 'Bike' },
    { value: 'House', label: 'House' },
    { value: 'Wristwatch', label: 'Wristwatch' },
    { value: 'Shoe', label: 'Shoe' },
    { value: 'Jewelry', label: 'Jewelry' },
    { value: 'Vehicle', label: 'Vehicle' },
    { value: 'Land', label: 'Land' },
    { value: 'Others', label: 'Others' }
  ]


  const [loanPackage, setLoanPackage]=useState(editLoanPackage?editLoanPackage:{
    lpId:null, 
    title:undefined, 
    collateral:false, 
    collateralType:[], 
    isActive:true,
    minAmount:undefined, 
    maxAmount:undefined,
    durationType:sc.Months, 
    durationValue:2, 
    repaymentType:sc.Weeks, 
    repaymentFrequency:1,
    interestPercentage:5
  });

  

  let defaultCollateral={ value: null, label: null };
  const [collaterals, setCollaterals]=useState(editLoanPackage?editLoanPackage.collateralType.map(v=>defaultCollateral={value: v, label: v}):[]);
  const collateralSelected=(e)=>{
        setCollaterals(e);
  }

  const addUpdateLoanPackage=()=>{
  
          
    if(!loanPackage.title){
      props.ShowAlert(sc.titlerequired, sc.warning);
      return;
    }

    if(!loanPackage.minAmount){
      props.ShowAlert(sc.minamountrequired, sc.warning);
      return;
    }
    if(!loanPackage.maxAmount){
      props.ShowAlert(sc.maxamountrequired, sc.warning);
      return;
    }

    console.log(util.getDaysOfPeriod(loanPackage.durationType, loanPackage.durationValue));
    console.log(util.getDaysOfPeriod(loanPackage.repaymentType, loanPackage.repaymentFrequency));
    if(util.getDaysOfPeriod(loanPackage.durationType, loanPackage.durationValue)<util.getDaysOfPeriod(loanPackage.repaymentType, loanPackage.repaymentFrequency)){
      props.ShowAlert(sc.invalidTenureAndRepayment, sc.warning);
      return;
    }

    let data= [];
        for(let i=0; i<collaterals.length; i++){
            data.push(collaterals[i].value);
        }

      loanPackage.collateralType=data;
      loanPackage.collateral=loanPackage.collateralType.length>0;
      
      if(!loanPackage.lpId){
        setHttpProcessing(true);
        client.addloanpackage(loanPackage).then( data => {
          setHttpProcessing(false);
          navigate(sc.loanpackageroute);
         },
         error => {
          setHttpProcessing(false);
          props.ShowAlert(error.response,  sc.danger);
         });
      }
else{

  client.updateloanpackage(loanPackage.lpId,loanPackage).then( data => {
    navigate(sc.loanpackageroute);
   },
   error => {
    props.ShowAlert(error.response,  sc.danger);
   });
  
}

  }

    return (
      <>
      
      <div  style={{width:auth.appWidth(), marginLeft:auth.appMargin()}} className="appHeader  bg-primary text-light">
        <div className="left">
          <a onClick={back} className="headerButton goBack">
            <ion-icon name="chevron-back-outline"></ion-icon>
          </a>
        </div>
        <div className="pageTitle">{editLoanPackage?'Update':'Add'} Loan Package</div>
       
      </div>
     
      <div id="appCapsule"  className="extra-header-active full-height"  style={{background:'white'}}>

<div className="card">
                     
                <div className="card-body">
                <div className="item">
                           
                           <div className="in">
                             <table style={{width:"100%"}}>
                               <tbody>
                               <tr>
                                 <td><span className="text-muted">Title</span></td>
                               </tr>
                               <tr>
                                 <td><span><input  value={loanPackage.title} onChange={handleChange} id="title" style={{width:"100%"}} className="form-control" placeholder="e.g Package 1" /></span></td>
                               </tr>
                               </tbody>
                               </table>
                           </div>
                       </div><br/>
                <div className="item">
                           
                           <div className="in">
                             <table style={{width:"100%"}}>
                               <tbody>
                               <tr>
                                 <td style={{width:"50%"}}><span className="text-muted">(Min Amt)</span></td>
                                 <td style={{width:"50%"}}><span className="text-muted">(Max Amt)</span></td>
                               </tr>
                               <tr>
                                 <td style={{width:"50%"}}><span><input type="number" value={loanPackage.minAmount} onChange={handleChange} id="minAmount" style={{width:"100%"}} className="form-control" placeholder="e.g 5000" /></span></td>
                                 <td style={{width:"50%"}}><span><input type="number"  value={loanPackage.maxAmount} onChange={handleChange} id="maxAmount"  style={{width:"100%"}} className="form-control" placeholder="e.g 10000" /></span></td>
                               </tr>
                               </tbody>
                               </table>
                           </div>
                       </div><br/><hr/>
                <ul className="listview flush transparent image-listview">
               
                 
                    <li>
                        <div  className="item">
                            <div className="icon-box badge-outline-primary">
                            <ion-icon name="add-outline"></ion-icon>
                            </div>
                            <div className="in">
                            <table style={{width:"100%"}}>
                                <tbody>
                                <tr>
                                  <td style={{width:"50%"}}><span className="text-muted">Interest</span></td>
                                  <td style={{width:"20%"}}> <select  value={loanPackage.interestPercentage} onChange={handleChange} id="interestPercentage" style={{width:"100%"}} className="form-control custom-select">
                                    { interestRange.map(value=>(
                                    <option value={value} key={value}>{value}</option>
                                ))}
                                      </select></td>
                                  <td>%</td>
                                </tr>
                               
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </li>
                    <li>
                   
                        <div className="item">
                            <div className="icon-box badge-outline-primary">
                            <ion-icon name="time-outline"></ion-icon>
                            </div>
                            <div className="in">
                            <table style={{width:"100%"}}>
                                <tbody>
                                <tr>
                                  <td style={{width:"50%"}}><span className="text-muted">Tenure</span></td>
                                  <td style={{width:"20%"}}> <select value={loanPackage.durationValue} onChange={handleChange} id="durationValue"  style={{width:"100%"}} className="form-control custom-select">
                                    { durationRange.map(value=>(
                                    <option value={value} key={value}>{value}</option>
                                ))}
                                      </select></td>
                                  <td style={{width:"30%"}}><select value={loanPackage.durationType} onChange={handleChange} id="durationType"  style={{width:"100%"}} className="form-control custom-select">
                                  { durationTypeRange.map(value=>(
                                    <option value={value} key={value}>{value}</option>
                                ))}
                                      </select></td>
                                </tr>
                               
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </li>
                    <li>
                   
                   <div className="item">
                    
                       <div className="icon-box badge-outline-primary">
                       <ion-icon name="repeat-outline"></ion-icon>
                       </div>
                       
                       <div className="in">
                       <table style={{width:"100%"}}>
                           <tbody>
                           <tr>
                             <td style={{width:"30%"}}><span className="text-muted">Repayment</span></td>
                             <td style={{width:"20%"}}>Every</td>
                             <td style={{width:"20%"}}> <select value={loanPackage.repaymentFrequency} onChange={handleChange} id="repaymentFrequency"  style={{width:"100%"}} className="form-control custom-select">
                               { durationRange.map(value=>(
                               <option value={value} key={value}>{value}</option>
                           ))}
                                 </select></td>
                             <td style={{width:"30%"}}><select value={loanPackage.repaymentType} onChange={handleChange} id="repaymentType"  style={{width:"100%"}} className="form-control custom-select">
                             { durationTypeRange.map(value=>(
                               <option value={value} key={value}>{value}</option>
                           ))}
                                 </select></td>
                           </tr>
                          
                           </tbody>
                           </table>
                       </div>
                   </div>
               </li>
                   <li>
                   <div className="item">
                      <div className="icon-box badge-outline-primary">
                            <ion-icon name="swap-horizontal-outline"></ion-icon>
                            </div>
                            <div className="in">
                            <div style={{width:"100%"}}> <span className="text-muted">Collateral (not required)</span>
                                {/* <Select   onChange={collateralSelected} id="collateralType"  className="custom-select" options={options} isMulti /> */}
                                <Select value={collaterals}  onChange={collateralSelected} className="custom-select" id="collateralType" options={options} isMulti />
                                </div>
                                </div>
</div>
                    </li>
                </ul>

                
              
                   <div className="item">
                         
                            </div>
                            
                </div>
                <div className="card-footer">
                
                
                <CustomButton  httpProcessing={httpProcessing} text={editLoanPackage?'Update':'Add'} onClick={addUpdateLoanPackage} />
                </div>
                <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>
            </div>
            </div>


      </>
    );

}
export default NewLoanPackage