import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import auth from "../services/authservice";
import sc from "./../services/stringconst"


const Footer = (loginStatus) => {
    const navigate= useNavigate();
     
    let showHome=false;
if(!window.location.href.toLowerCase().endsWith('welcome')){
    showHome=true;
}

    useEffect(()=>{
        const user = auth.getCurrentUser();
        if (user) {
            setIsLoggedIn(true);
            setActiveTab('dashboard');
        }
        else{
            setIsLoggedIn(false);
        }


    },[]);

    const gotoLogin=()=>{
        navigate(sc.loginroute);
    }
    const gotoWelcome=()=>{
        navigate(sc.welcomeroute);
        setActiveTab('welcome');
    }
    const gotoDashboard=()=>{
        navigate(sc.homeroute);
        setActiveTab('dashboard');
    }
    const gotoBorrow=()=>{
        navigate(sc.borrowroute);
        setActiveTab('borrow');
    }
    const gotoLoans=()=>{
        navigate(sc.loansroute);
        setActiveTab('loan');
    }
    const gotoPayments=()=>{
        navigate(sc.paymentsroute);
        setActiveTab('payment');
    }

    let itemActiveClass='item active';
    let itemClass='item';

    const [dashboardClass,setDashboardClass]= useState(itemActiveClass);
    const [borrowClass,setBorrowClass]= useState(itemClass);
    const [loanClass,setLoanClass]= useState(itemClass);
    const [paymentClass,setPaymentClass]= useState(itemClass);
    const [welcomeClass,setWelcomeClass]= useState(itemClass);
    
    
    const setActiveTab=(activeTab)=>{
        setDashboardClass(activeTab==='dashboard'?itemActiveClass:itemClass);
        setBorrowClass(activeTab==='borrow'?itemActiveClass:itemClass);
        setLoanClass(activeTab==='loan'?itemActiveClass:itemClass);
        setPaymentClass(activeTab==='payment'?itemActiveClass:itemClass);
        setWelcomeClass(activeTab==='welcome'?itemActiveClass:itemClass);
        
    }
    
    
    const [isLoggedIn, setIsLoggedIn]=useState(false);


  
  
    return (
        
        <div>

    
        {/*<!-- App Bottom Menu --> */}
        {loginStatus.loginStatus && (<div  style={{width:auth.appWidth(), marginLeft:auth.appMargin()}} className="appBottomMenu">
            <a onClick={gotoDashboard} className={dashboardClass}>
                <div className="col">
                    <ion-icon name="pie-chart-outline"></ion-icon>
                    <strong>Home</strong>
                </div>
            </a>
            <a  onClick={gotoBorrow} className={borrowClass}>
                <div className="col">
                <ion-icon name="cash-outline"></ion-icon>
                    <strong>Borrow</strong>
                </div>
            </a>
            <a  onClick={gotoLoans} className={loanClass}>
                <div className="col">
                <ion-icon name="list-outline"></ion-icon>
                    <strong>Loans</strong>
                </div>
            </a>
            <a  onClick={gotoPayments} className={paymentClass}>
                <div className="col">
                    <ion-icon name="card-outline"></ion-icon>
                    <strong>Payments</strong>
                </div>
            </a>
         
        </div>)}
        {/* {!loginStatus.loginStatus && showHome && (<div style={{width:auth.appWidth(), marginLeft:auth.appMargin()}} className="appBottomMenu">
            <a onClick={gotoWelcome} className={welcomeClass}>
                <div className="col">
                <ion-icon name="home-outline"></ion-icon>
                    <strong>Home</strong>
                </div>
            </a>
         
          
        </div>)} */}
        
        {!loginStatus.loginStatus && !showHome && (<div className="appBottomMenu_login">
        <a href="https://loanhub.ng/#how-it-work" style={{color: "orange", fontSize:"1.5em", position:"absolute", left:20, "zIndex":"10000"}}>
           <ion-icon  name="information-circle-outline"></ion-icon>
              
           </a>
            <a onClick={gotoLogin} style={{"zIndex":"10000"}}>
                <div className="col">
                
                    <strong>Signin</strong> <ion-icon name="log-in-outline"></ion-icon>
                </div>
            </a>
         
          
        </div>)}

        {/*<!-- * App Bottom Menu --> */}
   
    
        </div>
        )
    
  
}



export default Footer;