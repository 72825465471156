import React from "react";
import { useNavigate } from "react-router-dom";
import sc from "../services/stringconst";
import auth from "../services/authservice";
import { Client } from "../services/apiclient";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import util from "../Utilities/UtilityHelper";



const BorrowerCreditCheck=(props)=> {

    const navigate= useNavigate();
    const client = new Client();
    const location= useLocation();

    
  const [data, setData] = useState({});

  useEffect(() => {

    if(!location.state.borrowerId){
        navigate(-1);
    }
    client.borrowercreditcheck(location.state.borrowerId).then((data) => {
        setData(data);
    },
      (error) => {
        props.ShowAlert(error.response, sc.danger);
      });
  }, []);


const [creditData, setCreditData]= useState([]);

    return(
      <>

    {/* <!-- App Header --> */}
    <div  style={{width:auth.appWidth(), marginLeft:auth.appMargin()}} className="appHeader   bg-primary text-light">
        <div className="left">
            <a onClick={()=>navigate(-1)} className="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div className="pageTitle">
        Borrower Credit Check
        </div>
       
    </div>

    {/* <!-- * App Header -->

    <!-- App Capsule --> */}
    <div id="appCapsule"  className="extra-header-active full-height">

    <div className="card"  style={{"margin":"1em"}}>
    <ul className="listview image-listview transparent flush">
           
    <li>
              <div className="item">
                <div className="icon-box">
                <ion-icon name="person-outline"></ion-icon>
                </div>
                <div className="in">
                  <div>Borrower</div>
                  <div>
                {location.state.name}
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="item">
                <div className="icon-box text-info">
                <ion-icon name="checkmark-outline"></ion-icon>
                </div>
                <div className="in">
                  <div>Applied Loan<br/><span className="text-muted">Loans that borrower applied for</span></div>
                  <div>
                  <div className="icon-box bg-info">{data.applied}
                    </div>
                  </div>
                </div>
              </div>
            </li>
            
            <li>
              <div className="item">
                <div className="icon-box text-warning">
                <ion-icon name="checkmark-outline"></ion-icon>
                </div>
                <div className="in">
                  <div>Disapproved Loans<br/><span className="text-muted">Loans that got rejected by lenders</span></div>
                  <div>
                  <div className="icon-box bg-warning">{data.disapproved}
                    </div>
                  </div>
                </div>
              </div>
            </li>
            
           <li>
              <div className="item">
                <div className="icon-box text-info">
                <ion-icon name="checkmark-outline"></ion-icon>
                </div>
                <div className="in">
                  <div>Approved Loans<br/><span className="text-muted">Loans that has been approved but not yet open for disbursement and repayments</span></div>
                  <div>
                  <div className="icon-box bg-info">{data.approved}
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="item">
                <div className="icon-box text-secondary">
                <ion-icon name="arrow-forward-outline"></ion-icon>
                </div>
                <div className="in">
                  <div>Disbursed Loans<br/><span className="text-muted">Loans that the borrower has confirmed receipt of principal</span></div>
                  <div>
                  <div className="icon-box bg-secondary">{data.disbursed}
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="item">
                <div className="icon-box text-primary">
                <ion-icon name="folder-open-outline"></ion-icon>
                </div>
                <div className="in">
                  <div><span data-bs-toggle="modal"
                              data-bs-target="#creditModal" onClick={()=>setCreditData(data.openData)}>Opened Loans <ion-icon name="open-outline"></ion-icon></span><br/><span className="text-muted">Loans that are active and still within repayment tenure</span></div>
                  <div>
                  <div className="icon-box bg-primary">{data.open}
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="item">
                <div className="icon-box text-danger">
                <ion-icon name="checkmark-done-outline"></ion-icon>
                </div>
                <div className="in">
                  <div><span data-bs-toggle="modal"
                              data-bs-target="#creditModal" onClick={()=>setCreditData(data.overdueData)}>Overdue Loans <ion-icon name="open-outline"></ion-icon></span><br/><span className="text-muted">Loans that borrower has either not repaid at due date or lender not confirmed repayment</span></div>
                  <div>
                  <div className="icon-box bg-danger">{data.overdue}
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="item">
                <div className="icon-box text-success">
                <ion-icon name="checkmark-done-outline"></ion-icon>
                </div>
                <div className="in">
                  <div><span data-bs-toggle="modal"
                              data-bs-target="#creditModal" onClick={()=>setCreditData(data.closedData)}>Closed Loans <ion-icon name="open-outline"></ion-icon></span><br/><span className="text-muted">Loans that all repayments have been completed</span></div>
                  <div>
                  <div className="icon-box bg-success">{data.closed}
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="item">    
                <div className="icon-box text-warning">
                <ion-icon name="checkmark-done-outline"></ion-icon>
                </div>
                <div className="in">
                  <div><span data-bs-toggle="modal"
                              data-bs-target="#creditModal" onClick={()=>setCreditData(data.closedBeforeClosePeriodData)}>Closed Before tenure <ion-icon name="open-outline"></ion-icon></span><br/><span className="text-muted">Loans repayed and closed before last repayment period</span></div>
                  <div>
                  <div className="icon-box bg-warning">{data.closedBeforeClosePeriod}
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="item">
                <div className="icon-box text-danger">
                <ion-icon name="folder-open-outline"></ion-icon>
                </div>
                <div className="in">
                  <div><span data-bs-toggle="modal"
                              data-bs-target="#creditModal" onClick={()=>setCreditData(data.unclosedData)}>Unclosed Loans <ion-icon name="open-outline"></ion-icon></span><br/><span className="text-muted">Loans that are still open after repayment tenure</span></div>
                  <div>
                  <div className="icon-box bg-danger">{data.unclosed}
                    </div>
                  </div>
                </div>
              </div>
            </li>
            </ul>
            </div>
   

   
              {/* <!-- Credit Data --> */}
              <div 
                className="modal fade modalbox"
                id="creditModal"
                tabIndex="-1"
                role="dialog"
              >
                <div className="modal-dialog" role="document">
                <div className="modal-content"  style={{width:auth.appWidth(), marginLeft:auth.appMargin()}}>
                  <div className="modal-header" style={{width:auth.appWidth(), marginLeft:auth.appMargin()}}>
                      <h5 className="modal-title">Details</h5>
                      <a href="#" data-bs-dismiss="modal">
                        Close
                      </a>
                    </div>
                    <div className="modal-body" style={{textAlign:"justify"}}>
                   
    <div className="card"  style={{"margin":"1em"}}>
    <ul className="listview image-listview transparent flush">
           
   {creditData.map((o)=>(
 <li>
 <div className="item">
   <table style={{width:"100%"}}>
    <tr>
        <td>Progress level</td>
        <td><span className="badge badge-primary">{o.progressLevel}%</span></td>
    </tr>
    <tr>
        <td>Amount</td>
        <td> {util.commaCurrencyFormatter(
                                  o.amount
                                )}</td>
    </tr>
    <tr>
        <td>Tenure</td>
        <td>{o.duration}</td>
    </tr>
    <tr>
        <td>Repayment Frequency</td>
        <td>{o.repaymentFrequency}</td>
    </tr>
   </table>
 
 </div>
</li>
   ))}
            </ul>
            </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- * Credit Data --> */}
    </div>
    {/* <!-- * App Capsule --> */}

      </>
    )
}
export default BorrowerCreditCheck;