import React from "react";
import sc from '../services/stringconst';
import auth from "./authservice";


const Alert =({message, alertType})=>{

  if(!message && alertType===sc.danger){
    message='Check network connection';
  }

return(
<div>
<div  style={{width:auth.appAlertWidth(), marginLeft:"10%", position:"fixed", bottom:"10%", "zIndex":"1000", textAlign:"center"}} className={alertType} role="alert">
                      {message}
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>


</div>
)
};

export default Alert;

