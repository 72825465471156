import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import auth from "../services/authservice";
import sc from "../services/stringconst";
import { Client } from "../services/apiclient";
import AppVer from "../services/apiclient";
import { Capacitor } from "@capacitor/core";

const Welcome=()=> {

  const navigate = useNavigate ();

  useEffect(()=>{
    try{
    const client = new Client();
    let currentUser= auth.getCurrentUser();
    
    if(currentUser!==null && currentUser!==undefined){
        navigate(sc.homeroute);
    }

    auth.setPlatform(Capacitor.getPlatform());
    let platform = auth.getPlatform();
    if(!platform){
      platform= 'unknown';
    }

    }catch(e){
      alert(e);
    }
    

},[]);
  
  
const getLoan=()=>{
  navigate(sc.borrowroute);
};

const login=()=>{
  navigate(sc.loginroute);
};


    return (
      <div>
      <div id="appCapsule" className="extra-header-active full-height" >
          {/* <!-- 1. The <iframe> (video player) will replace this <div> tag. --> */}

          <div className="video-wrapper">

<video autoPlay loop="loop" muted defaultmuted="true" playsInline  onContextMenu={()=>{return false}}  preload="auto"  id="myVideo" style={{height:"100%"}}>
        <source src="/assets/video/loanapp.mp4" type="video/mp4"/>
        </video>
</div>
<div className="col-md-12 text-center content" style={{padding: auth.appWelcomeButtonPadding()}}>
  <br/>
  <br/>
          <img src="assets/img/sample/photo/lend.png" className="img-fluid" style={{maxWidth: auth.appWelcomeLogoWidth()}}/>
          <br/>
          <br/>
          <button  onClick={getLoan} type="button"  className="btn btn-warning me-1 mb-1 btn-block" style={{padding: auth.appWelcomeButtonPadding(), maxWidth: auth.appWelcomeButtonWidth()}}>Get quick loan &nbsp;&nbsp;<ion-icon name="cash-outline"></ion-icon></button>
<br/><br/>

<button type="button" onClick={login} className="btn btn-primary me-1 mb-1 btn-block" style={{padding: auth.appWelcomeButtonPadding(), maxWidth: auth.appWelcomeButtonWidth()}}>
Lend money with interest (%) </button>
    </div>

<div>
  
</div>

        </div>
      </div>
      
    );
    };

    export default Welcome;