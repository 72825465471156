import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import auth from "../services/authservice";
import Alert from "../services/alert";
import sc from "../services/stringconst";
import { Client } from "../services/apiclient";
import { NativeBiometric, AvailableResult, Credentials, BiometryType } from "capacitor-native-biometric";
import CustomButton from "./childcomponents/custombutton";
import Keypad from "./childcomponents/keypad";


const SetLoginPIN = (props) => {
  //alert(localStorage.getItem("PushNotificationToken"));
  let client = new Client();
  const navigate = useNavigate();
  const register = () => {
    navigate(sc.registerroute);
  };


  const setMobilePIN = (pin) => {
    if(pin.length<6){
      props.ShowAlert("PIN must be 6 characters", sc.danger);
    }
    client.setmobilepin({pin:pin}).then((data)=>{

      props.ShowAlert("Mobile login PIN updated", sc.success);
      navigate(sc.settingsroute);
    },
    (error)=>{
      props.ShowAlert(error.response, sc.danger);
    });

  };
  const back=()=>{
    navigate(-1);
  }
  useEffect(() => {

  }, []);



  return (
    <div className="col-md-12">
      {/* <!-- App Header --> */}
      <div  style={{width:auth.appWidth(), marginLeft:auth.appMargin()}} className="appHeader  bg-primary text-light">
      <div className="left">
        <a onClick={back} className="headerButton goBack">
            <ion-icon name="chevron-back-outline"></ion-icon>
          </a>
        </div>
        <div className="pageTitle">Set Login PIN</div>
        <div className="right"></div>
      </div>
      {/* <!-- * App Header --> */}


      {/* <!-- App Capsule --> */}
      <div id="appCapsule" className="extra-header-active full-height">
        <div className="section mb-5 p-2">


        <br/>
          
<Keypad submitPin={setMobilePIN} />
          
          
        </div>
      
      </div>

    </div>
  );
};
export default SetLoginPIN;
