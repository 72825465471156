import React from "react";
import { useNavigate } from "react-router-dom";
import util from "../../Utilities/UtilityHelper";

const MoneyModal=(props)=> {
const navigate= useNavigate();
    return(
     <>
   
      {/* <!-- Money Modal --> */}
      <div className="modal fade dialogbox" id="DialogMoney" data-bs-backdrop="static" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="bg-primary" style={{textAlign:"center", width:"100%"}}>
                        <br/>
                    <h4 className="bg-primary">{props.label}</h4>
                    </div>
                    <div className="modal-body">
                        <br/>
                    <b className={props.cssClass}>{util.commaCurrencyFormatter(props.value)}</b>
                    </div>
                    
                    <div className="modal-footer">
                        <div className="btn-inline">
                            <a onClick={()=>navigate(props.navigation, props.state)} className="btn btn-text-primary"  data-bs-dismiss="modal">View Details</a>
                            <a className="btn btn-text-warning" data-bs-dismiss="modal">Close</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- * Money Modal --> */}

     </>
    )
}
export default MoneyModal;