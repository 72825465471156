import React from 'react';
import sc from '../../services/stringconst';

const CustomButton=(props)=> {

    let bgColor="primary";
    let block="btn-block";

    if(props.bgColor){
        bgColor=props.bgColor;
    }
   
    if(props.inline){
        block="";
    } 
    return (
        <>
       {!props.httpProcessing && <button onClick={props.onClick} className={`btn btn-${bgColor} ${block}`}>{props.text}</button> }
       {props.httpProcessing && <button disabled className={`btn btn-${bgColor} ${block}`}>
     {sc.loaderViewCountThree.map(o=> <><div style={{color:"white"}}  className="spinner-grow spinner-grow-sm" role="status">
  <span className="sr-only"></span>
</div> &nbsp;</>)}
       </button>}
       </>
    );
}

export default CustomButton;