import React from "react";
import auth from "../../services/authservice";

const AdvertModal=(props)=> {
    return(
<>
   <div style={{position:"absolute", top:"0%", left:auth.appMargin(), width:"100%", height:"100%",zIndex:"10001"}}>
   {/* style={{width:auth.appWidth(), marginLeft:auth.appMargin()}}  */}
       
 <img style={{width:auth.appWidth(), height:"100%"}} src={props.adUrl} />


   
        </div>
        <a onClick={props.clearAdvert} className="badge badge-primary" style={{position:"fixed", top:"95%", left:"48%", "zIndex":"10002", color:"white"}}>
                <div className="col">
                
                    <strong>Close</strong>
                </div>
            </a>
        </>
    )
}
export default AdvertModal;